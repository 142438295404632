import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../../vinciToolbox';
import { NavBackIcon, ArrowRightSmall, LiveTrainingIcon } from '../../../../svg/svg_icons';
import { NavButton } from '../../../../components'; 
import { CourseInfo } from './CourseInfo';
import firebase from 'firebase';

export const LiveLearning=(props)=> {
  let pt = usePageTransitions('/live_learning', 2)
  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    overflow: 'auto',
    display: pt.render ? 'block' : 'none'
  });

  let refSubpageHeader = useRef(null);

  //  let contentWrapperHeight = refSubpageHeader.current ? refSubpageHeader.current.offsetHeight : 0;

  let [data, setData] = useState( [] );
  let db = firebase.firestore();
  let docRef = {};
  docRef = db.doc(`campbell_compass/live_learning`); // Create reference to db location where compass data is saved
  let [courseList, setCourseList] = useState([]);

  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get().then(function(doc) {
      setCourseList(doc.data().liveLearning)
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[props])

  const checkIfUserEnrolled = (attendees) => {
    let results = false;
    // console.log('attendees: ', attendees)
    if(attendees){
      for(let i = 0; i < attendees.length; ++i){
        if(firebase.auth().currentUser.displayName === attendees[i]){
          results = true;
        }
      }
    } 
    return results;
  }

  let tempArray = [];
  for(let i = 0; i < courseList.length; ++i){
    let status = checkIfUserEnrolled(courseList[i].attendees);
    tempArray.push({
      id: i, 
      data: courseList[i], 
      attendees: status,
      enrolled: courseList[i].attendees && courseList[i].attendees.length
    });
  }
  // console.log('tempArray: ', tempArray)

  let iconSize = 45;

  let theList = tempArray.map((course)=>
  <div key={course.id} style={{display: 'flex'}}>
    <div style={{margin: '15px 0 0 25px', width: iconSize, height: iconSize}}>
      <LiveTrainingIcon size={iconSize} color={'#F5B01B'}  />
    </div>
    <div key={course.id} style={{width: '100%', display: 'flex', flexDirection: 'column', fontSize: '110%', 
      borderBottom: '1px solid rgba(0,0,0,0.2)', padding: '15px 20px', cursor: 'pointer', 
    }} onClick={(e)=> { props.rt.nav(`/plan/live_learning/course_info:${course.id}`) } } >
    
        <div style={{display: 'flex'}}>

          <div style={{padding: 0, color: '#555', fontWeight: 600, width: '100%', marginRight: 0, display: 'flex'}}>
            {course.data.title}
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{fontSize: '90%', fontWeight: 500, marginRight: 10}}>
              <div style={{display: 'flex'}}>
                <div>Enrolled: </div><div style={{margin: '0 0 0 10px'}}>{course.enrolled}</div> 
              </div>
            </div>
            <div style={{
              // padding: '5px 0 0 5px',
              borderRadius: '50%', width: 25, height: 25, background: course.attendees ?  '#F9C908' : '#c6c6c6'
            }}>
              <div style={{opacity: .5, padding: '3px 0 0 5px'}}>
                <ArrowRightSmall scale={1.6}  />
              </div>
            </div>
          </div>
        </div>

        <div style={{fontSize: '90%', padding: 0, color: '#555', width: '100%', marginRight: 0, display: 'flex',}}>
          {course.data.description}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div style={{}}>{course.data.date}</div>
          <div style={{}}>{course.data.instructor}</div>
        </div>
      </div>
    </div>
  )


  return(
    <Fragment>
      <div style={{backgroundColor: '#cccccc'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary" name="back" size={50} >
                <NavBackIcon height="50" name="liveLearn" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>Live Leadership Training</div>
          </div>
          <div className="Assess-description-block" 
            style={{minHeight: 100, fontSize: '100%', width: '100%', backgroundColor: '#f4f4f4', overflow: 'hidden'}}
          >
            <div style={{ padding: '15px 0 10px 0', fontWeight: 500}}>
              Currently offered training courses are listed below. 
              Enroll in a course, or review current enrollement, by clicking on a course and following the instructions.
            </div>
          </div>

          {theList}

        </animated.div>
      </div>

      <CourseInfo rt={props.rt} wrapper={props.wrapper} screenSize={props.screenSize} user={props.user} />
    </Fragment>
  )
}


