import React, { useState, useEffect, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions } from '../../../../../../vinciToolbox';
import { NavBackIcon } from '../../../../../svg/svg_icons';
import { ResourceLibrary } from './ResourceLibrary/ResourceLibrary';
import { NavButton } from '../../../../../components'; 
import { CompetencyLineItem } from './CompetencyLineItem';
import {  compassDefinition } from '../../../../AssessPages/compassDefinition';
import firebase from 'firebase';
import '../../../../../../firebase-init';
import './AddResources.css';

var arraySort = require('array-sort');

export const AddResources= React.memo(props=> {
  let pt = usePageTransitions('/add_resources', 3)
  let [data, setData] = useState( [] );
  let db = firebase.firestore();
  let docRef = {};

  let [compassData, setCompassData] = useState(compassDefinition)


  // The following two functions are not used but might be put into use in the future, so I've retained the code.
  // const getOneCompetencyAverage = (data, categoryIndex, competencyIndex) => {
  //   let compassPointArray = data[categoryIndex].subHeading[competencyIndex].compassPoint;
  //   let sum = 0;
  //   for(let i = 0; i < compassPointArray.length; ++i){
  //     sum += compassPointArray[i].barValue ;
  //   }
  //   return sum / compassPointArray.length;
  // }

  // const getAverageOfOneCompetencyForAllAssessments = (assessArray, categoryIndex, competencyIndex) => {
  //   let averages = []
  //   let sum = 0
  //   for(let i = 0; i < assessArray.length; ++i){
  //     averages[i] = getOneCompetencyAverage( assessArray[i].assessment, categoryIndex, competencyIndex );
  //     sum += averages[i];
  //   }
  //   return( sum / assessArray.length );
  // }

  // const getAverageOfCompassPoints = (subHeading) => {
  //   let compassPointArray = subHeading.compassPoint;
  //   let sum = 0;
  //   let avg = 0;
  //   for(let cp = 0; cp < compassPointArray.length; ++cp){
  //     let barValue = compassPointArray[cp].barValue;
  //     sum = sum + barValue;
  //     avg = sum / compassPointArray.length;
  //   }
  //   console.log('avg: ', avg)
  //   return(avg)
  // }

  // const getCompetencyScores = (assessment, i) => {
  //   let competencyScores = [];
  //   for(let h = 0; h < assessment.length; ++h){
  //     for(let sh = 0; sh < assessment[h].subHeading.length; ++sh){
  //       let avg = getAverageOfCompassPoints(assessment[h].subHeading[sh]);
  //       competencyScores.push(avg);
  //     }
  //   }
  //   return competencyScores;
  // }

  
  const getRealtimeUpdates = () =>{
    docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_my_assessments`);
    docRef.onSnapshot((doc)=>{
      // console.log('doc.data()', doc.data())
      if(doc && doc.exists) {
        

        if(doc.data().assessmentArray.length){
          let assessmentArray = arraySort(doc.data().assessmentArray, 'assessmentDate', {reverse: true});

          // console.log('assessmentArray: ', assessmentArray);

          // This is where the assessments should be averaged for the current year.
          // Filter for current year.
            // Get the current year. 
          let d = new Date();
          let year = d.getFullYear();
          // console.log('the current year is : ', year);
            // Filter for current year
          let tempArray = [];
          for(let i = 0; i < assessmentArray.length; ++i){
            // Push only assessements that are in the current year.
            if(assessmentArray[i].assessmentYear === year){
              tempArray.push(assessmentArray[i]);
            }
          }
          assessmentArray = tempArray;



          let ccAverages = compassDefinition;

          // Initialize barValue sums to 0
          for(let h = 0; h < ccAverages.length; ++h){
            // console.log('heading: ', ccAverages[h].heading);
            for(let sh = 0; sh < ccAverages[h].subHeading.length; ++sh){
              // console.log('subHeading: ', ccAverages[h].subHeading[sh]);
              for(let cp = 0; cp < ccAverages[h].subHeading[sh].compassPoint.length; ++cp){
                ccAverages[h].subHeading[sh].compassPoint[cp].barValue = 0;
                // console.log('subHeading: ', ccAverages[h].subHeading[sh].compassPoint[cp] );
              }
            }
          }

          // Sum the barValues of all the assessments
          for(let ass = 0; ass < assessmentArray.length; ++ass){
            let ccDefTemp = assessmentArray[ass].assessment;
            for(let h = 0; h < ccDefTemp.length; ++h){
              // console.log('heading: ', ccDefTemp[h].heading);
              for(let sh = 0; sh < ccDefTemp[h].subHeading.length; ++sh){
                // console.log('subHeading: ', ccDefTemp[h].subHeading[sh]);
                for(let cp = 0; cp < ccDefTemp[h].subHeading[sh].compassPoint.length; ++cp){
                  let tempValue = ccDefTemp[h].subHeading[sh].compassPoint[cp].barValue;
                  ccAverages[h].subHeading[sh].compassPoint[cp].barValue += tempValue;
                }
              }
            }
          }
          // Calculate the average values from the above sums
          for(let ass = 0; ass < assessmentArray.length; ++ass){
            let ccDefTemp = assessmentArray[ass].assessment;
            for(let h = 0; h < ccDefTemp.length; ++h){
              // console.log('heading: ', ccDefTemp[h].heading);
              for(let sh = 0; sh < ccDefTemp[h].subHeading.length; ++sh){
                // console.log('subHeading: ', ccDefTemp[h].subHeading[sh]);
                for(let cp = 0; cp < ccDefTemp[h].subHeading[sh].compassPoint.length; ++cp){
                  let tempValue = ccDefTemp[h].subHeading[sh].compassPoint[cp].barValue;
                  tempValue = tempValue / assessmentArray.length;
                  ccAverages[h].subHeading[sh].compassPoint[cp].barValue = Math.round(tempValue);
                }
              }
            }
            assessmentArray[ass].assessment = ccAverages; // Move final result to assessmentArray
          }

          setData({
            ccDef: assessmentArray[0] && assessmentArray[0].assessment, // THIS IS WHERE WE FORCE THE USE OF ONLY THE FIRST ASSESSMENT FOR CURRENT YEAR. IT NEEDS TO CHANGE.
            status: 'success' 
          });
          // console.log('assessmentArray[0] && assessmentArray[0].assessment=', assessmentArray[0] && assessmentArray[0].assessment)
        }else{
          // There are no assessments yet, so setData with the compassDefinition template
          setData({ ccDef: compassDefinition, status: 'success' });
        }
        return( null );
      }else{
        // doc does not exist so creacted it now
        docRef.set({
          assessmentArray: []
        });
      }

    }, ()=> {
      setData({ccDef: null, status: 'error' });
      return( null );
    });
  }

  useEffect(()=>{
    //if(props.user) {
      getRealtimeUpdates();  
    //}
  },[props.user])

  // Put Competency Names in an array
  let competencyNames = [];
  let competencyValues = [];
  let competencyTraits = [];
  let valueAccumulator = 0;
  let outPut = [];

  useEffect(() => {
    // if(data.ccDef && data.ccDef.length){
      // console.log('data.ccDef=', data.ccDef)
      // compassData = data.ccDef;
      setCompassData(data.ccDef)
    // }
  },[data.ccDef && data.ccDef.length, pt.render])


  // console.log('compassData=', compassData);

  if(compassData){
    for(let h = 0; h < compassData.length; ++h){
      for(let sh = 0; sh < compassData[h].subHeading.length; ++sh){
        competencyNames.push(compassData[h].subHeading[sh].name);
        competencyTraits.push(compassData[h].subHeading[sh].compassPoint);
        valueAccumulator = 0;
        for(let cp = 0; cp <  compassData[h].subHeading[sh].compassPoint.length; ++cp){
          valueAccumulator = valueAccumulator + compassData[h].subHeading[sh].compassPoint[cp].barValue;
        }
        let averaged = Math.round(valueAccumulator / compassData[h].subHeading[sh].compassPoint.length);
        competencyValues.push(averaged);
      }
    }
    for(let n = 0; n < 9; ++n){
      outPut.push({
        index: n,
        name: competencyNames[n],
        value: competencyValues[n],
        traits: competencyTraits[n]
      });
    }
  }

  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.wrapper.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    display: pt.render ? 'block' : 'none'
  });

  // let heading = 


  let [selectedCompetency, setSelectedCompetency] = useState(0);
  let cNames = outPut.map((competency) =>
    <div key={competency.index}>
      <div className="Resources-heading" style={{display: competency.index===0 ? 'block' : 'none'}}>PERSONAL LEADERSHIP</div>
      <div className="Resources-heading" style={{display: competency.index===3 ? 'block' : 'none'}}>TEAM LEADERSHIP</div>
      <div className="Resources-heading" style={{display: competency.index===6 ? 'block' : 'none'}}>BUSINESS LEADERSHIP</div>
      <CompetencyLineItem key={competency.index} props={props} 
        competency={competency} compassData={compassData} 
        selectedCompetency={selectedCompetency} setSelectedCompetency={setSelectedCompetency}
      />
    </div>
  );

  useEffect(()=>{
    if(document.getElementsByClassName('Phases-content-block')[0]){
    }
  },[document.getElementsByClassName('Phases-subpage-header-block')[0]]);

  return(
    <Fragment>
        <Fragment>  
          <animated.div className="Phases-slidein-page" style={transition}>
            <div style={{height: props.screenSize.height, overflowX: 'hidden', overflowY: 'scroll'}}>
            <div className="Phases-subpage-header-block">
              <div className="Phases-subpage-header">
                <div className="Phase-subpage-header-icon" onClick={()=>window.history.go(-1)}>
                  <NavButton variant="secondary" name="back" size={50} >
                    <NavBackIcon height="50" name="addResources" />
                  </NavButton> 
                </div>
                <div className="Phase-subpage-header-text"  style={{margin: '0 auto'}}>The Resource Library</div>
              </div>
              <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
              Under each competency is a menu of Leadership Compass Points. Choose a Compass Point to 
              gain access to relevant resources. The bar graphs show your assessment score.
              </div>
            </div>
            <div className="Phases-content-block" style={{marginTop: -20}}>
              {cNames}
            </div>
            </div>
          </animated.div>
        </Fragment>
      <ResourceLibrary props={props} compassData={data} />
    </Fragment>
  )
});

