import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../vinciToolbox';
import { BackBlueIcon, RefreshIcon } from '../svg/svg_icons';
// import { CCButton } from '../Plan'
import { NavButton } from '../components'; 
import { UserProfile } from './UserProfile';
// import { getPhotoUrlFromEmail } from '../helper_functions';
// import { AutocompleteUsers } from './components';

import firebase from 'firebase';
import arraySort from 'array-sort';
// import '../Phase.css';
// import '../Resources.css';

let db = firebase.firestore(); // Creates database reference

var graph = require('@microsoft/microsoft-graph-client');

export const UserList=(props)=> {
  let pt = usePageTransitions('/user_list', 1)

  let transition = useSpring({
    transform: `translateY(${pt.animate ? 0 : props.screenSize.height}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    overflowX: 'hidden', overYflow: 'auto', 
    display: pt.render ? 'block' : 'none'
  });

  let segment = usePathSegments(null);

  // Get the list of users
  let [theUsers, setTheUsers] = useState([]);
  useEffect(() => {
    let usersList = [];
    let docRef = db.doc('campbell_compass/users');
    docRef.get().then(function(doc) {
      if (doc && doc.exists) {
        let usersArray = Object.values(doc.data().users);
        // console.log('usersArray=', usersArray)
        usersArray = arraySort(usersArray, 'displayName')
        for(let i = 0; i < usersArray.length; ++i){
          usersList.push({
            id: i, 
            name: usersArray[i].displayName,
            email: usersArray[i].email,
            manager: usersArray[i].managerName
        });
        }
        setTheUsers(usersList)
        // console.log('usersList=', usersList)
      } else {
        console.log("No such document!");
      }
    }).catch(function(error) {
      console.log('Critical Failure! The "users" document does not exist: ', error);
    }); 
  },[])

  /* REAUTHENTICATION */
  let provider = null;
  let TENANT_ID = '75258168-0fa1-459e-94a6-968aabb9186a';
  provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    tenant: TENANT_ID,      
  });

  const reAuthenticate = () => {
    firebase.auth().currentUser.reauthenticateWithPopup(provider)
    .then((result) => {
      console.log('result=', result)
      let accessToken = result.credential && result.credential.accessToken;
      getUserProfile(accessToken);
    })
    .catch((error) => {
      console.log('Error in reauthenticating user: ', error)
    });
  }

  // async function getUserProfile() {
  var msUser = null;
  let client = {};
  function getUserProfile(accessToken) {
    // console.log('SUCCESS - token', doc.data());
    client = graph.Client.init({
      authProvider: (done) => {
        // console.log('done: ', done)
        done(null, accessToken);
      }
    });

    client.api('/me').get()
    .then((result)=> {
      msUser = result;
      console.log('Success - your user profile data was retrieved: ', result);

      // Get the manager data
      let managerName = '';
      client.api('/me/manager').get()
      .then((manager)=>{
        managerName = manager.displayName;
        console.log('Success - your manager was retrieved: ', manager.displayName);
        let fbUser = firebase.auth().currentUser;
        msUser['email'] = fbUser.email;
        msUser['uid'] = firebase.auth().currentUser.uid;
        msUser['managerName'] = managerName;             
        // Merge modified user record in campbell_compass/users document
        let docRef = db.doc('campbell_compass/users');
        docRef.set({ 
          users: { 
            [msUser.uid]: msUser,
            }
          }, { merge: true })
        .then(()=>{
          console.log('Success - current user record merged with main users object.');
          let usersList = [];
          let docRef = db.doc('campbell_compass/users');
          docRef.get().then(function(doc) {
            if (doc && doc.exists) {
              let usersArray = Object.values(doc.data().users);
              console.log('usersArray=', usersArray)
              usersArray = arraySort(usersArray, 'displayName')
              for(let i = 0; i < usersArray.length; ++i){
                usersList.push({
                  id: i, 
                  name: usersArray[i].displayName,
                  manager: usersArray[i].managerName
              });
              }
              setTheUsers(usersList)
              // console.log('usersList=', usersList)
            } else {
              console.log("No such document!");
            }
          }).catch(function(error) {
            console.log('Critical Failure! The "users" document does not exist: ', error);
          }); 
        })
        .catch((error)=> {
          console.log('Error - new user record not added. ', error);
        });
        // Retrieve photo blob from Active Directory and save it as a jpg in Firebase Cloud Storage
        client.api('/me/photo/$value').get()
        .then((photo) => {
          console.log('Success - your photo was retrieved: ', photo);
          var storageRef = firebase.storage().ref();
          storageRef.child('user_photos/' +firebase.auth().currentUser.email + '.jpg').put(photo)
          .then((result) => {
            console.log('Success - The photo blob was saved as jpg in Firebase Storage', result)
          }).catch(function(error) {
            console.log("Error - could not put the photo into Firebase Storage:", error);
          });
        }).catch(function(error) {
          console.error('Error - photo not retrienved:', error);
        });    
      })
      .catch((error)=>{
        managerName = '';
        console.log('Error - your manager was not retrieved: error');
      });
    }).catch((error)=> {
      console.log("ERROR - could not retrieve user's profile: ", error)
    })
  }
  let imageSize = 45;
  let [selectedUser, setSelectedUser] = useState('');
  const listItems = theUsers.map((user) =>
    <div key={user.id}>
      <div style={{marginBottom: 10, display: 'flex', justifyContent: 'space-between',}}>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', widthfontSize: '110%', fontWeight: 500,  cursor: 'pointer'}} 
            onClick={()=> { setSelectedUser(user.name); props.rt.nav('/user_list/profile') } }
          >
            {user.name}
          </div>
          <div style={{opacity: .9, margin: '-0px 0 0 20px', cursor: 'pointer'}}
            onClick={() => reAuthenticate() }
          >
            {firebase.auth().currentUser.displayName === user.name ?
              <RefreshIcon size={25} scale={1.3} />
            : ''
            }
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontSize: '90%', color: '#000'}}>         
          <div style={{ whiteSpace: 'nowrap', }}>
            {user.manager ? user.manager : '( None )'}
          </div>
        </div>
      </div>
    </div>
  );

  return(
    <Fragment>
      <div style={{backgroundColor: '#cccccc'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary" size={50} >
                <BackBlueIcon height="50" name="userlist" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>User List</div>
          </div>
          <div style={{fontSize: '85%', fontWeight: 600, textAlign: 'center', opacity: .75, marginBottom: 25}}>
            Click the refesh icon if your manager name is not up to date
          </div>

          <div style={{margin: '10px 25px', borderBottom: '2px solid rgba(0,0,0,0.15)', display: 'flex', justifyContent: 'space-between',}}>
            <div style={{fontWeight: 600, widthfontSize: '110%', fontWeight: 600, width: '100%',}} >
              Employee
            </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', fontSize: '90%', color: '#000'}}>
                <div style={{opacity: .5, marginRight: 10, cursor: 'pointer'}}>
              </div>
              <div style={{ fontWeight: 600 }}>
                Manager
              </div>
            </div>
          </div>

          <div style={{margin: '20px 25px'}}>
            {listItems}
          </div>
        </animated.div>
      </div>

      <UserProfile  rt={props.rt} wrapper={props.wrapper} screenSize={props.screenSize} user={props.user} selectedUser={selectedUser} />
    </Fragment>
  )
}

