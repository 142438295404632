
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { DeleteMenu } from './DeleteMenu';
import { handleRemove } from './DeleteMenu';
import { DeleteIcon, CustomDelete, CustomEdit } from '../../../../../../../svg/svg_icons';
import firebase from 'firebase';
import extractDomain from 'extract-domain';
import { Stars } from '../../../Stars';
import '../../../../../../../../firebase-init';

export const OneResource = (props) => {
  // Usage: <OneResource title={r.data.title} image={r.data.image} url={r.data.url} key={r.key} />
  let selected = false; // This is a temporary variable that will be deleted when I figure wtf out
  let [data, setData] = useState( [] );
  let [libraryContents, setLibraryContents] = useState([{default: 'default'}]);
  // console.log('OneResource - libraryContents=', libraryContents)
  let db = firebase.firestore();
  useEffect(()=>{
    // console.log('props=', props)
    if(props.user) {

    // Write to Resource database

    let docRef = {};
    // docRef = db.doc(`campbell_compass/resource_Library`); // Create reference to db location where compass data is saved
    docRef = db.doc(`campbell_compass/resource_library_${props.competency}_${props.compassPoint}`); 
    // console.log('props.user 1=', props.user)
    const getRealtimeUpdates = () =>{
      var unsubscribeOnSnapshot = docRef.onSnapshot((doc)=>{
        if(doc && doc.exists) {
          setData({collection: doc.data(), status: 'success' });
          setLibraryContents(doc.data().collection)
          return( null );
        }
        }, (error)=> {
          setData({collection: error, status: 'failed' });
          console.log('error=', error);
          return( null );
        });
      }
      getRealtimeUpdates();  
    }
  },[props.user])

  
    let [menu, setMenu] = useState(false);
    const openMenu = (e, val)=>{
      console.log('val=', val)
      setMenu(val)
    }

    const imageRef = useRef(null);
    const handleSize = (image) => {
      // console.log('inside 1')
      if(image.naturalHeight > image.naturalWidth){
        // console.log('inside 2 - ', image.style.width)
        image.style.width = '90px'
      }
    }

    // Extract domain from url to show the source of the article
    let [domain, setDomain] = useState('');
    useEffect(()=> {
      setDomain(extractDomain(props.url))
    },[])

    const handleEditResource = (i, prps) => {

      let selected = {}
      let docRef = {};
      docRef = db.doc(`campbell_compass/resource_library_${props.competency}_${props.compassPoint}`); // Create reference to db location where compass data is saved
      console.log('props ', props)
      docRef.get() 
      .then((doc) => {
        selected = doc.data()[props.type][i]
        props.setSelectedResource(selected);
        props.setSelectedIndex(i);
        props.setEditModal(true)
      })
      .catch(function(error) {
        console.error(
          "Error writing document to Firestore: ", error
        );
      });

    }
        
  
    return(
      <div style={{display: 'flex', alignItems: 'center', paddingRight: 20, 
        borderBottom: '1px solid rgba(0,0,0,0.30)',
        borderTop: '1px solid #e6e6e6',
        position: 'relative'
      }}>
        <div style={{margin: '22px 0 15px 20px', display: 'flex', overflow: 'hidden'}} >
          <div>
            <div style={{ width: 40, height: 45, margin: props.type==='books' ? '0px -3px 0 0' : '0px -3px 0 0', 
            position: 'absolute', top: 0, left: 0, 
          }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div  onClick={(e)=>setMenu(true)} style={{padding: 10, cursor: 'pointer'}}>
                {/*<DeleteIcon size={25} />*/}
                <CustomDelete size={27} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
              </div>
              <div  onClick={(e)=> handleEditResource(props.index)} style={{padding: '10px 55px', cursor: 'pointer'}}>
                <CustomEdit size={27} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
              </div>
              <DeleteMenu menu={menu} setMenu={setMenu} index={props.index} 
                libraryContents={libraryContents} setLibraryContents={setLibraryContents}
                user={props.user}/>
              </div>
            </div>
            <img src={props.image} style={{ 
              minHeight: 75, width: 'auto', minWidth: 100, maxWidth: 100,
              boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
              backgroundColor: '#c6c6c6',
              marginTop: 5
              // border: 'red' // '1px solid rgba(0,0,0,0.2)'
            }}
            ref={imageRef}
            onLoad={()=> handleSize(imageRef.current)}
            />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer', userSelect: 'none', overflow: 'auto', }} 
            onClick={(e)=> window.open(props.url, "_blank") }>
            <div style={{margin: '0 0px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{textOverflow: 'ellipsis', overflow: 'hidden', width: '100%', marginRight: 3}}>{domain}</div>
                  {/*<Stars rating={props.rating} />*/}
                </div>
            </div>
            <div style={{margin: '0 35px 0 15px', fontWeight: 500, color: '#222'}}>
              {props.title}
            </div>
            <div style={{
              margin: '8px 15px', fontSize: '85%', color: '#222222',
              overflow: 'hidden',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
            }}>{props.description}</div>
          </div>
        </div>
      </div>
    )
  }
  

 