const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

let getOnlyYear = () => {
  let d = new Date();
  return d.getFullYear();
}


let getTimestamp = () => {
  let d = new Date();
  let fullYear = d.getFullYear();
  let am_pm = 'am';
  // let month = months[d.getMonth()];
  let month = addZero(d.getMonth() + 1);
  let day = addZero(d.getDate());
  let hour = addZero(d.getHours());
  let year = d.getFullYear();
  let minutes = addZero(d.getMinutes());
  if(hour > 12) {
    hour = hour -12;
    am_pm = 'pm';
  }
  return  year +'/'+ month +'/'+ day +' '+ am_pm +' '+ hour  +':'+ minutes;
}

export default getTimestamp;