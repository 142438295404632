import React, { useState, useEffect, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../vinciToolbox';
import { AssessNavIcon, PlanNavIcon, ExecuteNavIcon, AssessTitleIcon, PlanTitleIcon, ExecuteTitleIcon, NavBackIcon, GoldBall } from '../svg/svg_icons';
import { compassDefinition } from './AssessPages/compassDefinition';
import { NavButton } from '../components';


export const Phase=(props)=> {
  let segment = usePathSegments([]);
  let width = props.wrapper.width;
  //let width = 50;
  let iconXPosition = 0;
  let iconYPosition = 0;
  let iconsSize = props.size
  let labelXPosition = 0;
  let labelYPosition = 0;
  let labelSize = '150%';
  let fontWeight = 600;
  let color = '#909090';
  if(segment[1]===props.url){
    
    iconXPosition = width-(width-27);
    iconYPosition = 15;
    if(props.label==='Assess'){
      labelXPosition = 123;
      iconsSize = 50;
    }else if(props.label==='Plan'){
      labelXPosition = 117;
      iconsSize = 50;
    }else if(props.label==='Execute'){
      labelXPosition = 125;
      iconsSize = 50;
    }
    labelYPosition = -47;
    color = '#555555';
  }else{
    if(props.label==='Assess'){
      iconXPosition = (width*.27)-60;
    }else if(props.label==='Plan'){
      iconXPosition = (width/2)-(iconsSize/2)-3;
    }else if(props.label==='Execute'){
      iconXPosition = (width)-(width*.27); 
    }
    iconYPosition = 0;
    labelXPosition = 0;
    labelYPosition = 0;
    labelSize = '100%';
    fontWeight = 500;
    color = '#909090';
  }
  let op = segment[1]===props.url ? 1 : 0;
  let pe = 'all';
  let label = props.label
  //console.log(`props.url: ${props.url} segment[1]: ${segment[1]}`);
  if(segment[1]===props.url){
    pe = 'all';
    if(segment[1]==='/assess') label = 'Assessments'
    if(segment[1]==='/plan') label = 'Create Plan'
    if(segment[1]==='/execute') label = 'Execute Plan'
  }else{
    pe = 'none'
    label = props.label
  }
  if(segment[1] === '/'){
    op = 1;
    pe = 'all'
  }
  let navHeading = useSpring({
    transform: `translate(${iconXPosition}px, ${iconYPosition}px )`, width: 60, display: 'flex', flexDirection: 'column', alignItems: 'center',
    opacity: op,
  })
  let navLabel = useSpring({
    marginTop: 5, color: '#909090',
    transform: `translate(${labelXPosition}px, ${labelYPosition}px)`,
    fontSize: labelSize,
    fontWeight: fontWeight,
    color: color,
    whiteSpace: 'nowrap',
  })

  function NavIcon(props) {
    if (label==='Assess') {
      return <AssessNavIcon size={48} backgroundColor="rgba(0,0,0,0.30)" />;
    }else if(label==='Plan') {
      return <PlanNavIcon size={48} backgroundColor="rgba(0,0,0,0.30)" />;
    }else if(label==='Execute'){
      return <ExecuteNavIcon size={48} backgroundColor="rgba(0,0,0,0.30)" />;
    }else if(label==='Admin') {
      return null;
    }else return null;
  }
  let url = props.url;
  function TitleIcon(props) {
    if (segment[1]==='/assess') {
      return <AssessTitleIcon />;
    }else if(segment[1]==='/plan') {
      return <PlanTitleIcon />;
    }else if(segment[1]==='/execute') {
      return <ExecuteTitleIcon />;
    }else if(segment[1]==='/admin') {
      return null;  // <PlanTitleIcon />;
    }else return null;
  }

  let navBackTransition = useSpring({
    config: {tension: 70}  ,
    opacity: segment[1] != '/' ? 1 : 0 ,
    margin: '-5px 0 0 -5px',
    //transform: 'rotate(-90deg)'
  })
  return(
    <Fragment>
      <div style={{ pointerEvents: `${segment[2] ? 'none' : 'all'}`,
        marginTop: 0, position: 'absolute', width: 10, height: 10}}>
        <animated.div style={navHeading}>
          {props.label ? 
            <Fragment>
              <NavButton size={iconsSize} rt={props.rt} url={props.url} label={props.label}>
                <div style={{margin: 6, userSelect: 'none'}} >
                  {segment[1]==='/' ? 
                    <div style={{position: 'relative'}} onClick={(e)=>props.rt.nav(props.url)} >
                      <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
                        <GoldBall size={53} name={`home-${label}`} /> 
                      </div>
                      <div style={{margin: '2px 0 0 3px', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
                        <NavIcon />
                      </div>
                    </div>
                  :
                    <div onClick={(e)=>window.history.go(-1)} >
                      <animated.div style={navBackTransition}>
                        <NavBackIcon height="50" name="phase_123" />
                      </animated.div>
                    </div>
                  }
                </div>
              </NavButton>
              <animated.div style={navLabel}>
                {label}
              </animated.div>
            </Fragment>
          : '' }
        </animated.div>

      </div>
    </Fragment>
  )
}
