import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../vinciToolbox';
import { NavBackIcon, BackGrayIcon, BackBlueIcon, NextBlueIcon, MenuBlueIcon, InfoIcon, ExIcon, ThreeDotsHorizontal } from '../../../svg/svg_icons';
import { NavButton } from '../../../components'; 
import { Modal } from '../Modal';
import firebase from 'firebase';
import '../../../../firebase-init.js'
import compassDefinition from '../compassDefinition.js';
import { HeadingLine, SubHeadingLine, CompassPointLine } from './components';
import { buildTheLines } from './miscOtherAssessmentFuntions.js';
import { CCButton } from '../../../components';
import '../Assessment.css';
import './DoAssessment.css';

let db = firebase.firestore(); // Creates database reference

export const isSelfAssessment = (selected) => {
  if(selected.displayName === firebase.auth().currentUser.displayName){
    return true
  }else{
    return false
  }
}

const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

let getOnlyYear = () => {
  let d = new Date();
  return d.getFullYear();
}

let getTimestamp = () => {
  let d = new Date();
  let fullYear = d.getFullYear();
  let am_pm = 'am';
  // let month = months[d.getMonth()];
  let month = addZero(d.getMonth() + 1);
  let day = addZero(d.getDate());
  let hour = addZero(d.getHours());
  let year = d.getFullYear();
  let minutes = addZero(d.getMinutes());
  if(hour > 12) {
    hour = hour -12;
    am_pm = 'pm';
  }
  return  year +'/'+ month +'/'+ day +' '+ am_pm +' '+ hour  +':'+ minutes;
}


const getCurrentUsersAssessments = ( allAssessments ) => {
  // console.log('before=', allAssessments);
  let filteredList = [];
  for(let i = 0; i < allAssessments.length; ++i){
    if(allAssessments[i].assessorName === firebase.auth().currentUser.displayName){
      filteredList.push(allAssessments[i]);
    }
  }
  // console.log('after=', filteredList)
  return filteredList;
}

let indexed = 0;

export const DoAssessment = (props)=> {

  // let [indexed, setIndexed] = useState(0)
  // console.log('XXXXXX indexed=', indexed);

  // console.log('1st DoAssessment-props=', props)
  let segment = usePathSegments(null);
  let pt = usePageTransitions('/do_assessment', 2);
  let [showButtons, setShowButtons] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [showModal2, setShowModal2] = useState(false);
  let [showModal3, setShowModal3] = useState(false); // For a warning to the user that they're about to deliver and unfished assessment to the recipient.
  let [showModal4, setShowModal4] = useState(false); // For a warning to the user that they're about to delete the assessment.
  let [showModal5, setShowModal5] = useState(false); // For letting the user know that they have completed the assessment and can send it to recipient.
  let [showModal6, setShowModal6] = useState(false); // For showing the user what the scores 1 - 5 are labeled

  useEffect(() => {
    if(pt.render){
      setShowModal6(true);
    } else {
      setShowModal6(false);
    }
  },[pt.render])


  useEffect(()=>{
    if(!props.selectedUser && segment[2]==='/do_assessment'){
      // setShowModal(true); // Shows the warning modal as soon as page loads. The warning states that you need to go back and reselect the recipient of the assessment
      window.history.go(-1);
    }
    
    // if(segment[2] !='/do_assessment'){
    //   // console.log('inside segment[2] test ')
    //   // setShowModal(false);
    //   window.history.go(-1);
    // }

  },[props.selectedUser, segment[2]]);

  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.wrapper.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    display: pt.render ? 'block' : 'none',

  });
  let [ccDef, setCcDef] = useState( compassDefinition );

  const resetBarValues = () => {
    // setCcDef(compassDefinition);
    // console.log('ccDef: ', ccDef);
    // Clear all barVals
    let ccDefTemp = compassDefinition;
    for(let h = 0; h < ccDefTemp.length; ++h){
      // console.log('heading: ', ccDefTemp[h].heading);
      for(let sh = 0; sh < ccDefTemp[h].subHeading.length; ++sh){
        // console.log('subHeading: ', ccDefTemp[h].subHeading[sh]);
        for(let cp = 0; cp < ccDefTemp[h].subHeading[sh].compassPoint.length; ++cp){
          ccDefTemp[h].subHeading[sh].compassPoint[cp].barValue = 0;
          // console.log('subHeading: ', ccDefTemp[h].subHeading[sh].compassPoint[cp] );
        }
      }
    }
    setCcDef(ccDefTemp)
  }
  
  useEffect(()=>{
    // Reset ccDef when page loads.
    resetBarValues()
  },[]);

  var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
  let [bookmark, setBookmark] = useState({hPointer: 0, shPointer: 0, cpPointer: 0});
  let [assessmentNumber, setAssessmentNumber] = useState(0);
  let [finishedButton, setFinishedButton] = useState(false);
  // let [indexed, setIndexed] = useState(0);

  let selectedUserAssessments = [];
  let currentUserAssessments = [];

  let theAssessments = [];
  useEffect(()=>{

    if(props.selectedUser){ // 
      docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
      docRef.get().then(function(doc) {
        if (doc && doc.exists) {         
          // Determine if last assessment of this person (where the assessor was the currenUser) was finished.
            // If no then display the unfinished assessment
            // If yes then create and display new assessment.
          theAssessments = doc.data().assessmentArray;
          let lastAssessment = theAssessments.length;
          if(lastAssessment > 0){ // This if statement insures that lastAssessment.length value is never less than zero
            --lastAssessment; // decrementing results in the indexed value of the assessmentArray.
          }

          // Test if last assessment for this the selected recipient was finished
            // First we must find the currentUsers' last assessment for this person, which we can do 
            // by looping through all assessments while looking for the ones FOR the selectedUser and BY the currentUser. NOTE: the
            // function called getCurrentUsersAssessments() gets only the assessments where currentUser was the the assessor.
            // Then pick the last one in the returned array and check if it was finished.

          let isNewAssessment = true;
          if(theAssessments.length > 0){ // Do this if there is at least one assessment in the array.
            selectedUserAssessments = currentUserAssessments = getCurrentUsersAssessments(theAssessments)
            console.log('currentUserAssessments: ', currentUserAssessments); 
            // The currentUserAssessments array should now hold only assessments that are done FOR currentUser and BY currentUser.
            // If last assessment in the currentUserAssessments array was finished, then indicate that this is a new assessment

            if(currentUserAssessments.length != 0){ // If there are no assessments then skip the following, leaving isNewAssessment = true;
              console.log('INSIDE HERE!!!!!!!!!!')
              if(currentUserAssessments[currentUserAssessments.length-1].assessmentFinished){
                isNewAssessment = true;
                console.log('last assessment was finished ')
              }
              else{
                console.log('last assessment was NOT finished ')
                isNewAssessment = false;
                setCcDef( doc.data().assessmentArray[theAssessments.length - 1].assessment );
              }
            }
          }

          console.log('isNewAssessment=', isNewAssessment)
          if(!isNewAssessment){ 
            // It was not finished, so load the last assessment and go to first unscored page.
            setShowModal2(true);
            // Get the index value for the selectedUserAssessments last assessment.
            // console.log('selectedUserAssessments[selectedUserAssessments.length - 1].index=', selectedUserAssessments[selectedUserAssessments.length - 1].index)
            // THIS indexed VALUE CAN BE USED TO INSURE THAT ONLY THE INDEXED ASSESSMENT WILL BE UPDATED, DELETED, OR SUBMITTED TO RECIPIENT
            // setIndexed( selectedUserAssessments[selectedUserAssessments.length - 1].index );
            indexed = currentUserAssessments[selectedUserAssessments.length - 1].index;
            console.log('1 - THIS IS THE INDEX OF THE SELECTEDUSERS LAST ASSESSMENT', indexed);

            setCcDef( theAssessments[indexed].assessment );
          }else {

            console.log('PUSH NEW ASSESSMENT ONTO THE ARRAY')
            resetBarValues();
            setCcDef(compassDefinition); // Clear bar values
            theAssessments.push({ // It was finished, so push a new assessment onto theAssessments array and continue display blank assessment.
              recipientEmail: props.selectedUser.email,
              assessment: compassDefinition,
              assessorName: props.user.displayName,
              assessorId: props.user.uid,
              assessmentDate: getTimestamp(),
              assessmentYear: getOnlyYear(),
              assessmentFinished: false,
              bookmark: bookmark,
              index: theAssessments.length // New assessments are always added at the end of all the assessments of the selectedUser.
            });
            indexed = theAssessments.length - 1; // ADDING THIS MIGHT HAVE FIXED MY LAST ASSESSMENT BUG.
            console.log('OOOOOO indexed=', indexed);
            // Save updated assessmentArray to database.
            var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);      


            docRef.set({
              assessmentArray: theAssessments
            }, { merge: true })
            .then(()=>{
              console.log('SUCCESS: A new blank assessment doc was created at the end of the assessmentArray');  
              // Retrieve new document for display and fill it with the compassDEfinition template's data.
              docRef.get()
              .then((doc) => {
                if (doc && doc.exists) {
                  setCcDef( compassDefinition );
                }
              });
            }).catch((error)=> {
              console.log('You screwed up: ', error);
            });


          }
        } else {
          let d = new Date();
          docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
          docRef.get().then(function(doc) {
            console.log('doc.data().assessmentArray=', doc.data().assessmentArray)
          })

          let tempIndexed = doc.data() && doc.data().assessmentArray && doc.data().assessmentArray.length;
          console.log('doc does not exist - props.selectedUser.email: ', props.selectedUser.email)
          console.log("No such assessment document, so create a blank assessment");
          let theAssessments = []
          theAssessments.push({
            recipientEmail: props.selectedUser.email,
            assessment: ccDef,
            assessorName: props.user.displayName,
            assessorId: props.user.uid,
            assessmentDate: getTimestamp(),
            assessmentYear: getOnlyYear(),
            assessmentFinished: false,
            bookmark: bookmark,
            index: theAssessments.length // New assessments are always added at the end of all the assessments of the selectedUser.
          });
          // Create the original blank assessment doc.
          var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);


          docRef.set({
            assessmentArray: theAssessments
          }, { merge: true })
          .then(()=>{
            console.log('SUCCESS: Original blank assessment doc was created');  
          }).catch((error)=> {
            console.log('You screwed up: ', error);
          });


        }
      }).catch(function(error) {
        console.log("Error getting document:", error);
      });
    }
  },[props.selectedUser]);

  useEffect(()=>{
    // Setup Realtime database listener so bargraph value updates are automatically displayed without page refresh
    const getRealtimeUpdates = () =>{
      var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
      docRef.onSnapshot((doc)=>{
        // console.log('onSnapshot 1 triggered - indexed=', indexed);
        if(doc && doc.exists) {
          // let theAssessments = doc.data().assessmentArray;
          if(doc.data().assessmentArray[indexed]){
            setCcDef(
              doc.data().assessmentArray[indexed].assessment
            );
          }
        }else{
          // console.log('The assessment doc does not yet exist for current user')
        }
      })
    }
    getRealtimeUpdates();  
  },[props.selectedUser.email]);

  let headingLine = [];
  let subHeadingLine = [];
  let compassPointLine = [];
  /* Converts the ccDef data to arrays that are later used with .map() to build the DOM elements for the Heading, Subheading, and for the CompassPoint descriptions. */
  buildTheLines(ccDef, headingLine, subHeadingLine, compassPointLine);

  /* START of horizontal position pointer processing. The position pointers update when the user clicks the forward or back buttons */
  let [hPointer, setHPointer] = useState(0);
  let [shPointer, setShPointer] = useState(0);
  let [cpPointer, setCpPointer] = useState(0);
  let [submitted, setSubmitted] = useState(false);

  useEffect(()=>{
    setHPointer(0);
    setShPointer(0);
    setCpPointer(0);
  },[])

  let hInd = hPointer;
  const changeHPointer = (val) =>{
    hInd = hPointer;
    if(hInd > 0 && hInd < headingLine.length-1){
      if(val === 1){
        if(hInd <= headingLine.length && (headingLine[hInd+1].levelUp - headingLine[hInd].levelUp === 1) ){
          changeHPointer(1);
        }
      }else if(val === -1){
        if( hInd > 0 && (headingLine[hInd-1].levelUp - headingLine[hInd].levelUp === -1) ){
          changeHPointer(-1);
        }
      }
    }
    hInd += val;
    if( hInd < 0 ){hInd = 0}
    if( hInd > headingLine.length){hInd = headingLine.length}
    setHPointer(hInd);
  }
  let shInd = shPointer;
  const changeShPointer = (val) => {
    shInd = shPointer;
      if(shInd > 0 && shInd < subHeadingLine.length-1){
        if(val === 1){
          if(shInd < subHeadingLine.length && (subHeadingLine[shInd+1].levelUp - subHeadingLine[shInd].levelUp === 1) ){
            changeHPointer(1);
          }
        }else if(val === -1){
          if( shInd > 0 && (subHeadingLine[shInd-1].levelUp - subHeadingLine[shInd].levelUp === -1) ){
            changeHPointer(-1);
          }
        }
      }
    shInd += val;
    if( shInd < 0 ){shInd = 0}
    if( shInd > subHeadingLine.length){shInd = subHeadingLine.length}
    setShPointer(shInd);
  }
  let cpInd = cpPointer;
  const changeCpPointer = (val) => {
    cpInd = cpPointer;
      if(cpInd > 0 && cpInd < compassPointLine.length-1){
        if(val === 1){
          if( (compassPointLine[cpInd+1].levelUp - compassPointLine[cpInd].levelUp === 1) ){
            changeShPointer(1);
          }
        }else if(val === -1){
          if(cpInd > 0 && (compassPointLine[cpInd-1].levelUp - compassPointLine[cpInd].levelUp === -1) ){
            changeShPointer(-1);
          }
        }
      }
    cpInd += val;
    if( cpInd < 0 ){cpInd = 0}
    if( cpInd >= compassPointLine.length){cpInd = compassPointLine.length-1}
    setCpPointer(cpInd);
  }
  /* END of horizontal position pointer processing */

  // Takes the user to the first unscored compsass point. 
  let hIndex = 0;
  let shIndex = 0;
  let cpIndex = 0;
  let getFirstUnscoredPage = () => {
    breakMe: {
      for(let h = 0; h < ccDef.length; ++h){
        // console.log('heading: ', ccDef[h].heading);
        ++hIndex;
        // console.log('hIndex: ', hIndex);
        for(let sh = 0; sh < ccDef[h].subHeading.length; ++sh){
          // console.log('subHeading: ', ccDef[h].subHeading[sh]);
          ++shIndex;
          // console.log('shIndex: ', shIndex);
          for(let cp = 0; cp < ccDef[h].subHeading[sh].compassPoint.length; ++cp){
            // console.log('subHeading: ', ccDef[h].subHeading[sh].compassPoint[cp] );
            ++cpIndex;
            // console.log('cpIndex: ', cpIndex);
            if(ccDef[h].subHeading[sh].compassPoint[cp].barValue === 0){
              // console.log(`THIS IS WHAT YOU'RE LOOKING FOR -> h:${hIndex} shIndex:${shIndex} cpIndex:${cpIndex} `);
              setHPointer(hIndex-1);
              setShPointer(shIndex-1);
              setCpPointer(cpIndex-1);
              break breakMe;
            }
          }
        }
      }
    }
  }
  const gotoFirstUnscoredPage = () => {
    getFirstUnscoredPage();
  }


  // Takes the user to the first unscored compsass point. 

  let getNumberOfUnscoredPages = () => {
    let hIndex = 0;
    let shIndex = 0;
    let cpIndex = 0;
    let unscoredTotal = 0;
      for(let h = 0; h < ccDef.length; ++h){
        // console.log('heading: ', ccDef[h].heading);
        ++hIndex;
        // console.log('hIndex: ', hIndex);
        for(let sh = 0; sh < ccDef[h].subHeading.length; ++sh){
          // console.log('subHeading: ', ccDef[h].subHeading[sh]);
          ++shIndex;
          // console.log('shIndex: ', shIndex);
          for(let cp = 0; cp < ccDef[h].subHeading[sh].compassPoint.length; ++cp){
            // console.log('subHeading: ', ccDef[h].subHeading[sh].compassPoint[cp] );
            ++cpIndex;
            // console.log('cpIndex: ', cpIndex);
            if(ccDef[h].subHeading[sh].compassPoint[cp].barValue === 0){
              ++unscoredTotal;
            }
          }
        }
      }
    return unscoredTotal;
  }

  let [unscoredPages, setUnscoredPages] = useState(0);
  useEffect(() => {
    setUnscoredPages( getNumberOfUnscoredPages() - 1 );
  },[ccDef]);
  // console.log('unscoredPages=', unscoredPages);

  const handleSendAssessment = () => {
    let theAssessmentArray = []
    var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
    console.log('props.selectedUser.email=', props.selectedUser.email)
    // Retrieve the Other Assessment bookmark data from the database
    docRef.get().then(function(doc) {

      if (doc.exists) {
        theAssessmentArray = doc.data().assessmentArray;
        for(let i = 0;  i < theAssessmentArray.length; ++i){
          if( (theAssessmentArray[i].assessorName === firebase.auth().currentUser.displayName 
            && theAssessmentArray[i].assessmentFinished === false)
          ){
            console.log(`before - theAssessmentArray[${i}]=`, theAssessmentArray[i])

            theAssessmentArray[i].assessmentFinished = true;
            theAssessmentArray[i].assessmentDate = getTimestamp();
            theAssessmentArray[i].assessmentYear = getOnlyYear();

            console.log(`after - theAssessmentArray[${i}]=`, theAssessmentArray[i])

          }
          docRef.set({
            assessmentArray: theAssessmentArray
          }).then(() => {
            console.log('SUCCESS: assessment was sent to recipient');
          }).catch((error) => {
            console.log("Error deleting assessment: ", error);
          })
        }
      } 



    }).catch(function(error) {
        console.log("Error getting Other Assessment document:", error);
    });
    // props.setSubmitted(true);

  }

  const handleDeleteAssessment = () => {
    // console.log('Inside handleDeleteAssessment - selectedUser=', props.selectedUser);

    var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
    console.log('props.selectedUser.email=', props.selectedUser.email)
    // Retrieve the Other Assessment bookmark data from the database
    let theAssessmentArray = [];
    let tempArray = [];
    docRef.get().then(function(doc) {
      if (doc.exists) {
        theAssessmentArray = doc.data().assessmentArray;

        for(let i = 0;  i < theAssessmentArray.length; ++i){
          if( !(theAssessmentArray[i].assessorName === firebase.auth().currentUser.displayName 
            && theAssessmentArray[i].assessmentFinished === false)
          ){
            tempArray.push(theAssessmentArray[i]);
          }
        }
        console.log('tempArray', tempArray );
        docRef.set({
          assessmentArray: tempArray
        }).then(() => {
          console.log('SUCCESS: assessment was deleted');
        }).catch((error) => {
          console.log("Error deleting assessment: ", error);
        })
      } 
    }).catch(function(error) {
        console.log("Error getting Assessments document:", error);
    });


  }


  let [showInfoMenu, setShowInfoMenu] = useState(false);

  // console.log('cpPointer=', cpPointer);
  // console.log('compassPointLine=', compassPointLine)
  // console.log('props.selectedUser=', props.selectedUser.displayName)

  // console.log('XXXXXXXXXXXX indexed=', indexed);

  // let unscored = getNumberOfUnscoredPages();
  // console.log('unscored', unscored);
  // useEffect(() => {
  //   if(unscored === 0) setShowModal5(true);
  // },[unscored,props])


  return(
    <Fragment>
      <div style={{}}>
        <animated.div  className="Phases-sidein-page" 
          style={transition}
        >
          <div style={{
            overflowX: 'hidden',
            overflowY: 'auto',
            height: props.wrapper.style.height - 110,
          }}>
            <div className="Phases-subpage-header">
              <div className="Phase-subpage-header-icon" style={{margin: '-12px 0 0 3px'}} onClick={()=>{resetBarValues(); window.history.go(-1) } }>
                <NavButton variant="secondary" name="back" size={50} >
                  <NavBackIcon height="50" name="doAssessment" />
                </NavButton> 
              </div>
              <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>
                {isSelfAssessment(props.selectedUser) ? 'Self Assessment' : 'Assessing ' + props.selectedUser.displayName }
              </div>
              <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', margin: '14px 0px 0 0'}}
                onClick={()=> { setShowInfoMenu(true) }}  
              >
                <div style={{pointer: 'cursor'}}  
                  onClick={()=> { setShowInfoMenu(true) }}  
                >
                  <ThreeDotsHorizontal size={25} color="#787878"  onClick={()=> { setShowInfoMenu(true) }}   />
                </div>
              </div>

            </div>
            <div style={{fontSize: '115%', fontWeight: 500, margin: '-25px 0 15px 0',  width: '100%', display: 'flex', justifyContent: 'center'}}>
              {cpPointer+1} of 60
            </div>
            <div className="Assess-description-block" style={{fontSize: '100%', width: '100%', backgroundColor: '#f4f4f4'}}>
              <HeadingLine headingLine={headingLine} wrapper={props.wrapper} hPointer={hPointer} />
              <SubHeadingLine subHeadingLine={subHeadingLine} wrapper={props.wrapper}  shPointer={shPointer} />
            </div>            

            <div className="Assess-compass-point" style={{
            }} >
              <CompassPointLine compassPointLine={compassPointLine} wrapper={props.wrapper} 
                ccDef={ccDef} setCcDef={setCcDef} cpPointer={cpPointer} user={props.user}
                assessmentNumber={assessmentNumber} // assessmentType={assessmentType}
                getNumberOfUnscoredPages={getNumberOfUnscoredPages}
                setShowModal5={setShowModal5}
                selectedUser={props.selectedUser}
                finishedButton={finishedButton}
                setFinishedButton={setFinishedButton}
                indexed={indexed}
              />

            </div>  
          </div>

          <div style={{}}>

            <BottomButtons pointerCount={cpInd} maxCount={compassPointLine.length} 
              showButtons={showButtons} screenSize={props.screenSize} wrapper={props.wrapper}
              
              ccDef={ccDef} changeCpPointer={changeCpPointer} cpPointer={cpPointer} setCpPointer={setCpPointer} 
              shPointer={shPointer} setShPointer={setShPointer} hPointer={hPointer} setHPointer={setHPointer}
              
              assessmentNumber={assessmentNumber}
              user={props.user}
              selectedUser={props.selectedUser}
              selectedUser={props.selectedUser}
              selectedUserAssessments = {currentUserAssessments}
      
              finishedButton={finishedButton} setFinishedButton={setFinishedButton}

              gotoFirstUnscoredPage={()=> gotoFirstUnscoredPage()}

              indexed={indexed}
            />



          </div>
        
        </animated.div>
      </div>




      <div style={{position: 'fixed', top:0, right:0, bottom:0, left:0, 
        backgroundColor: 'rgba(0,0,0,0)', backdropFilter: 'blur(7px)',
        display: showInfoMenu ? 'block' : 'none' }} onClick={()=> setShowInfoMenu(false)} >
      </div>
      <InfoMenu show={showInfoMenu} setShowInfoMenu={setShowInfoMenu} selectedUser={props.selectedUser} wrapper={props.wrapper} 
        gotoFirstUnscoredPage={()=> gotoFirstUnscoredPage()} unscoredPages={unscoredPages}
        setShowModal3={setShowModal3} setShowModal4={setShowModal4} setShowModal6={setShowModal6}
        handleSendAssessment={()=> handleSendAssessment()}  handleDeleteAssessment={()=> handleDeleteAssessment()}
      />

      <Modal show={showModal} width={'80%'}>
        <div style={{cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', width: props.wrapper.width, marginTop: -8}}
          onClick={()=> {setShowModal(false);  window.history.go(-1) }} >

          <div style={{display: 'flex', alignItems: 'center', }} >
            <NavBackIcon height="50" name="goBackOne"/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', color: '#333', fontSize: '110%', textAlign: 'left', marginLeft: 10}}>
              Go back one page and reselect the person you intend to assess.
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div className="OtherAssessment" 
                style={{display: 'flex', justifyContent: 'center', 
                  position: 'relative', margin: '20px 0 0px 0', width: '100%'}}
              >
              </div>
            </div>
          </div>

      </Modal>

      <Modal show={showModal2 && segment[2]==='/do_assessment'} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
          <div >
            <div style={{color: '#333', fontSize: '110%', textAlign: 'center', marginLeft: 10}}>
              You've already started an assessment for this person. 
              You can't start another until you finish the previous one.
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'center', 
                marginTop: 30,
                width: '100%',
                cursor: 'pointer',
                fontSize: '120%',
                textAlign: 'center',
                color: '#777'
              }} onClick={()=> {setShowModal2(false); window.history.go(-1)}}>
                Cancel
              </div>
              <div style={{display: 'flex', justifyContent: 'center', 
                  marginTop: 30,
                  width: '100%',
                  cursor: 'pointer',
                  fontSize: '120%',
                  textAlign: 'center',
                  color: '#F5B01B'
                }} 
                onClick={()=>{setShowModal2(false); gotoFirstUnscoredPage()}  }
              >
                Resume Previous Assessment
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal show={showModal3 && segment[2]==='/do_assessment'} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
          <div >
            <div style={{color: '#333', fontSize: '110%', textAlign: 'center', marginLeft: 10}}>
              Are you sure want to send {props.selectedUser.displayName} {getNumberOfUnscoredPages() === 0 ? <Fragment>the assessment?</Fragment> : <Fragment>an unfinished assessment?</Fragment> }
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'center', 
                marginTop: 30,
                width: '100%',
                cursor: 'pointer',
                fontSize: '120%',
                textAlign: 'center',
                color: '#777'
              }} onClick={()=> { setShowModal3(false) }}>
                No, cancel this
              </div>
              <div style={{display: 'flex', justifyContent: 'center', 
                  marginTop: 30,
                  width: '100%',
                  cursor: 'pointer',
                  fontSize: '120%',
                  textAlign: 'center',
                  color: '#F5B01B'
                }} 
                onClick={()=>{ handleSendAssessment(); setShowInfoMenu(false); setShowModal3(false);  window.history.go(-1)  } }
              >
                Yes, send it!
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal show={showModal4 && segment[2]==='/do_assessment'} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
          <div >
            <div style={{color: '#333', fontSize: '110%', textAlign: 'center', marginLeft: 10}}>
              Are you sure you want to delete this assessment?
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'center', 
                marginTop: 30,
                width: '100%',
                cursor: 'pointer',
                fontSize: '120%',
                textAlign: 'center',
                color: '#777'
              }} onClick={()=> { setShowModal4(false) }}>
                No, cancel this
              </div>
              <div style={{display: 'flex', justifyContent: 'center', 
                  marginTop: 30,
                  width: '100%',
                  cursor: 'pointer',
                  fontSize: '120%',
                  textAlign: 'center',
                  color: '#F5B01B'
                }}
                onClick={()=>{ handleDeleteAssessment(); setShowInfoMenu(false); setShowModal4(false);  window.history.go(-1)  } }
              >
                Yes, delete it!
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={showModal5 && segment[2]==='/do_assessment'} width={'80%'}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
          <div >
            <div style={{color: '#333', fontSize: '110%', textAlign: 'center', marginLeft: 10}}>
              You have completed the assessment! Send it to {props.selectedUser.displayName} now, or cancel and continue editing.
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{display: 'flex', justifyContent: 'center', 
                marginTop: 30,
                width: '100%',
                cursor: 'pointer',
                fontSize: '120%',
                textAlign: 'center',
                color: '#777'
              }} onClick={()=> { setShowModal5(false) }}>
                Cancel
              </div>
              <div style={{display: 'flex', justifyContent: 'center', 
                  marginTop: 30,
                  width: '100%',
                  cursor: 'pointer',
                  fontSize: '120%',
                  textAlign: 'center',
                  color: '#F5B01B'
                }}
                onClick={()=>{ handleSendAssessment(); setShowInfoMenu(false); setShowModal5(false);  window.history.go(-1)  } }
              >
                Send it
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={showModal6 && segment[2]==='/do_assessment'} width={'80%'}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: props.wrapper.width - 0}}>
        <div >
          <div style={{color: '#333', fontSize: '100%'}}>
            <div style={{fontSize: '130%', fontWeight: 600, marginBottom: 15, textAlign: 'center'}}>Assessment rankings</div>

            <ol style={{fontSize: '110%', display: 'flex', flexDirection: 'column', alignItems: 'left'}}>

              <li className="DoAssessment-definition">
                <div className="name">Starting Point for Development</div>
                <div className="description">Acknowledge need to Acquire the Leadership Attribute</div>
              </li>

              <li className="DoAssessment-definition">
                <div className="name">Needs Additional Development</div>
                <div className="description">Acquiring the Leadership Attribute</div>
              </li>

              <li className="DoAssessment-definition">
                <div className="name">Developing</div>
                <div className="description">Learning to Apply the Leadership Attribute</div>
              </li>

              <li className="DoAssessment-definition">
                <div className="name">Highly Developed</div>
                <div className="description">Applying the Leadership Attribute</div>
              </li>

              <li className="DoAssessment-definition">
                <div className="name">Exceptional</div>
                <div className="description">Modeling the Leadership Attribute / Influences Others</div>
              </li>

            </ol>
          
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{display: 'flex', justifyContent: 'center', 
              marginTop: 15,
              width: '100%',
              cursor: 'pointer',
              fontSize: '130%',
              textAlign: 'center',
              color: '#777'
            }} onClick={()=> { setShowModal6(false) }}>
              Close
            </div>
          </div>
        </div>
      </div>
    </Modal>


    </Fragment>
  )
}



const InfoMenu = (props) => {
  // console.log('InfoMenu - props: ', props)
  let menuTransition = useSpring({
    config: {tension: 350},
    backgroundColor: '#ffffff',
    color: '#000',
    fontWeight: 500,
    maxWidth: props.wrapper.width -20,
    minWidth: 200, 
    minHheight: 150,
    // whiteSpace: 'nowrap',
    borderRadius: 8,
    position: 'absolute', top: 0, right: 0,
    boxShadow: '0 0 10px rgba(0,0,0,0.3)',
    transform: `${props.show ? "scale(1,1)" : "scale(0,0)"}`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    // padding: '10px 25px',
    margin: '8px 10px 0 0',
    overflow: 'none',
    display: props.show ? 'flex' : 'none',
    // fontSize: '120%',
  })

  let variablePadding = ( 25 * (props.wrapper.width / 700 ) ) + 10 ;

  return(
    <animated.div style={menuTransition}>
        <div style={{textAlign: 'left', width: '100%', 
          fontSize: '115%', fontWeight: 600, padding: '12px 25px', 
          backgroundImage: 'linear-gradient(180deg, #F8C115, #F5B01B)', 
          color: 'rgba(0,0,0,0.6)',
          borderRadius: '8px 8px 0px 0px'
        }}>
          Status: {props.unscoredPages + 1} unscored traits
        </div>
        <ul className="Assess-status-menu" style={{textAlign: 'left', fontWeight: 600, color: '#666666', fontSize: '110%', padding: `0px ${variablePadding}px `, width: '100%',}}>
          <li onClick={()=> props.setShowModal3(true) }>
            Send {props.unscoredPages > 0 ? <span style={{color: 'tomato'}}>UNFINISHED</span> : <span>FINISHED</span>} assessment<br />to {props.selectedUser.displayName}?</li>
           <hr />
          <li style={{}} onClick={()=> props.setShowModal4(true) }>Delete this assessment</li>
          <hr />
          <li style={{}} onClick={()=> {props.setShowModal6(true); props.setShowInfoMenu(false) } }>Show assessment rankings</li>
        </ul>
    </animated.div>
  )
}

const BottomButtons = (props) => {
  let segment = usePathSegments();
  const refBottomButtons = useRef(null);
  let h = refBottomButtons.current && refBottomButtons.current.offsetHeight;
  let [showMenu, setShowMenu] = useState(false);

  // if(props.selectedUser){ // 
  //   let docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
  //   docRef.get().then(function(doc) {
  //     console.log('doc.data() :', doc.data());
  //   })
  // }

  let buttonsPosition = useSpring({
    padding: '20px 0 30px 0', 
    margin: '0 auto', 
    width: '100%', 
    display: 'flex',
    justifyContent: 'space-around',  zIndex: 1,
    position: 'static',
    top: props.screenSize.height,
    top: segment[2] ? props.screenSize.height - h : props.screenSize.height,
    backgroundColor: '#e6e6e6',
    borderTop: 'rgba(0,0,0,0.15)',
    boxShadow: '0 0 5px rgba(0,0,0,0.2)'
  })
  
  // Get the bookmark and use it to initialize lastSaved
  let [lastSaved, setLastSaved] = useState({ hPointer: 0, shPointer: 0, cpPointer: 0 });

  let theAssessmentArray = []

  useEffect(()=>{
    if(props.user && props.user.uid){
      var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
      // Retrieve the Other Assessment bookmark data from the database
      docRef.get().then(function(doc) {
        if (doc.exists) {
          theAssessmentArray = doc.data().assessmentArray
            setLastSaved( doc.data().assessmentArray[props.indexed].bookmark )
        } else {
            // console.log("No Other Assessment document!");
        }
      }).catch(function(error) {
          console.log("Error getting Other Assessment document:", error);
      });
    }
    
    // Setup Realtime database listener so updates are automatically displayed without page refresh
    const getRealtimeUpdates2 = () =>{
      docRef && docRef.onSnapshot((doc)=>{
        // console.log('onSnapshot 2 triggered')
        if(doc && doc.exists) {
          theAssessmentArray = doc.data().assessmentArray;
          setLastSaved(
            doc.data().assessmentArray[props.indexed].bookmark
          );
        }else{
          // console.log('The assessments document is not initialized yet')
        }
      })
    }
    getRealtimeUpdates2();  
  },[props.user]);

  return(
    <Fragment>
    {segment[2]==='/do_assessment' ?
      <animated.div ref={refBottomButtons}  style={buttonsPosition}>
       
        <div onClick={()=>  props.changeCpPointer(-1) }>
          <NavButton  name="back" size={60} >
            { props.pointerCount === 0 ?
              <BackGrayIcon height="50" style={{cursor: 'auto', margin: '4px 0 0 6px'}}/>
              : 
              <NavBackIcon name="pageback" height="50" style={{margin: '4px 0 0 6px'}}/>
            }
          </NavButton>
        </div>

        <div onClick={()=> setShowMenu(!showMenu) }>
          <NavButton  name="back" size={60} >
            <MenuBlueIcon height="50" style={{margin: '4px 0 0 6px'}}/>
          </NavButton>
        </div>
        <div onClick={()=> props.changeCpPointer(1) }>            
          <NavButton  name="next" size={60} >
            <div style={{margin: '4px 0 0 6px'}} >
            { props.pointerCount === props.maxCount-1 ?
              <BackGrayIcon height="50" style={{cursor: 'auto', margin: '0px 0 0 0px', transform: 'rotate(180deg)'}}/>
              : 
              <NextBlueIcon height="50" />
            }
            </div>
          </NavButton>
        </div>
        <div style={{position: 'absolute', height: 10,
          display: 'flex', justifyContent: 'center', width: props.wrapper.width, 

          }}>
          <GotoMenu showMenu={showMenu} setShowMenu={setShowMenu} 
            wrapper={props.wrapper} 
            ccDef={props.ccDef}
            hPointer={props.hPointer} setHPointer={props.setHPointer}
            shPointer={props.shPointer} setShPointer={props.setShPointer}
            cpPointer={props.cpPointer} setCpPointer={props.setCpPointer}
            lastSaved={lastSaved} setLastSaved={setLastSaved}
            user={props.user}
            assessmentNumber={props.assessmentNumber}
            selectedUser={props.selectedUser}
            gotoFirstUnscoredPage={()=> props.gotoFirstUnscoredPage()}
            indexed={props.indexed}
          />
        </div>

      </animated.div>
      : ''
    }
  </Fragment>
  )
}

const GotoMenu = (props) => {
  const menuWidth = 260;
  const menuHeight = 400;
  let menuStyle = useSpring({
    zIndex: 5,
    maxWidth: menuWidth,
    width: '100%',
    padding: 20,
    backgroundColor: '#f4f4f4',
    borderRadius: 10,
    position: 'absolute', top: -menuHeight + 30, left: (props.wrapper.width / 2) - (menuWidth/2),
    transform: `scale(${props.showMenu ? 1 : 0.2})`,
    opacity: props.showMenu ? 1 : 0,
    display: props.showMenu ? 'flex' : 'none',
    flexDirection: 'column',
    boxShadow: '0 0 10px rgba(0,0,0,0.3)',
    fontSize: '120%',
    userSelect: 'none'
  });

  const handleStart = () =>{
    props.setHPointer(0); 
    props.setShPointer(0);
    props.setCpPointer(0);
    props.setShowMenu(false);
  }

  const handleEnd = () =>{
    props.setHPointer(2); 
    props.setShPointer(8);
    props.setCpPointer(59);
    props.setShowMenu(false);
  }

  const handleFirstUnscored = () => {
    props.gotoFirstUnscoredPage();
    props.setShowMenu(false);
  }

  const handleGotoBookmark = () =>{
    props.setHPointer(props.lastSaved ? props.lastSaved.hPointer : 0); 
    props.setShPointer(props.lastSaved ? props.lastSaved.shPointer : 0);
    props.setCpPointer(props.lastSaved ? props.lastSaved.cpPointer : 0);
    props.setShowMenu(false);
  }

  const handleBookmarkThis = () =>{
    props.setLastSaved({
      hPointer: props.hPointer,
      shPointer: props.shPointer,
      cpPointer: props.cpPointer
    })

    var docRef = db.collection(`campbell_compass`).doc(`${props.selectedUser.email}_my_assessments`);
    // Save the Other Assessment new bookmark location to the database
    let assessmentArray = [];
    docRef.get()
    .then((doc)=>{
      assessmentArray = doc.data().assessmentArray;
      assessmentArray[props.indexed].bookmark = {
        hPointer: props.hPointer,
        shPointer: props.shPointer,
        cpPointer: props.cpPointer
      }
      docRef.set({
        assessmentArray
      }).catch((error)=> {
        console.log('you screwed up in barValue update: ', error);
      })
    }).catch((error)=> {
      console.log('Something screwed up: ', error);
    });
    props.setShowMenu(false);
  }

  return(
    <Fragment>
      <div style={{display: props.showMenu ? 'block' : 'none', 
        position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, 
        background: 'rgba(0,0,0,0)', backdropFilter: `blur(7px)`}}
        onClick={()=>props.setShowMenu(false)}
      ></div>
      <animated.div style={menuStyle}>
        <div className="Assess-menu" onClick={()=>handleStart() } >Goto start</div>
        <div className="Assess-menu" onClick={()=>handleEnd() } >Goto end</div>
        <div className="Assess-menu" onClick={()=>handleFirstUnscored() } >Goto first unscored</div>
        <div className="Assess-menu" onClick={()=>handleGotoBookmark() } > Goto bookmark</div>
        <div className="Assess-menu" onClick={()=>handleBookmarkThis() } >Save Bookmark</div>
      </animated.div>
    </Fragment>
  )
}

