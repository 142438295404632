import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../vinciToolbox';
import { NavBackIcon, PersonGray } from '../../svg/svg_icons';
import { NavButton } from '../../components'; 
import getTimestamp from './getTimestamp'
import firebase from 'firebase';


export const ChallengingAssignmentNotes = (props)=> {
  let segments = usePathSegments();
  let title = segments[3] && segments[3].split(':');
  title = title && title[1];
  title = title && title.replace(/%20/gm, " ");

  let pt = usePageTransitions('/ca_notes', 2)
  let transition = useSpring({
    config: {tension: 170},
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none'
  });

  let refSubpageHeader = useRef(null);

  let contentWrapperHeight = refSubpageHeader.current ? refSubpageHeader.current.offsetHeight : 0;

  let [caNotes, setCaNotes] = useState('');
  return(
    <Fragment>
        <div style={{backgroundColor: '#cccccc'}}>
          <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary" name="back" size={50} >
                <NavBackIcon height="50" name="ca_notes" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>
              Execution Notes
            </div>
          </div>
          {/*<div className="Assess-description-block" 
            style={{minHeight: 100, fontSize: '100%', width: '100%', backgroundColor: '#f4f4f4', overflow: 'hidden'}}>
  </div>
          <FroalaEditor toolbarInLine={false} description={caNotes} setDescription={setCaNotes} />*/}
          </animated.div>
        </div>
    </Fragment>
  )
}