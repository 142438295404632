// import React, { useState, useEffect, Fragment, useRef } from 'react';

import firebase from 'firebase';

export const PageRoles = (props) => {

}

let db = firebase.firestore();
let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);

export let getPagePermissions = (userName, pageName) => {
  return new Promise(function(resolve, reject) {
    docRef.get()
    .then((doc) => {
      let data = doc.data();
      // console.log('data=', data);
      if(true){
        resolve(false);
      }else(
        resolve(false)
      )
    }).catch((error) => {
      console.log('ERROR - ', error);
      reject('The person is not permitted to access the page')
    })
  });
}



