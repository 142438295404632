import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import SavedResources from './SavedResources';
import LiveLearning from './LiveLearning';
import ChallengingAssignment from './ChallengingAssignment';
import CompletionLog from './CompletionLog';
import { Modal } from '../AssessPages/Modal';
import firebase from 'firebase';

let db = firebase.firestore();

export const Execute = (props)=> {
  // console.log('Execute - props: ', props)

  let headerHeight = props.headerHeight;
  let pt = usePageTransitions('/execute', 1)

  let [tabSlide, setTabSlide] = useState(false);

  let tabBar = useSpring({
    height: '100%', width: '50%',
    backgroundColor: '#F8C115',
    transform: `translateX(${tabSlide ? props.wrapper.style.width / 2 : 0}px)`,
  })

  let transition = useSpring({
    display: pt.render ? 'block' : 'none',
    transform: `translateY(${pt.animate ? headerHeight : props.screenSize.height}px)`,
  });



  let refSubpageHeader = useRef(null);


  
  let subpageWrapper = useSpring({
    position: 'relative',
    display: pt.render ? 'flex' : 'none',
    width: props.wrapper.width * 2, 
    height:  props.wrapper.style.height - 500, // contentWrapperHeight,
    transform: `translateX(${tabSlide ? -props.wrapper.style.width : 0}px)`, 
  });

  let [showModal, setShowModal] = useState(false);
  let [modalContent, setModalContent] = useState('');

  return(
    <div>
    {pt.render ?
      <Fragment>
        <animated.div style={transition}>
          
          <div style={{position: 'absolute', width: props.wrapper.width, height: props.screenSize.height - headerHeight}}>
            <div style={{}}>

              <div style={{display: 'flex', fontWeight: 600, cursor: 'pointer', boxShadow: '0 3px 3px rgba(0,0,0,0.2)'}}>
                <div style={{
                    width: '50%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide ? '#888888' : '#000000'
                  }} 
                  onClick={(e)=> setTabSlide(false)}>
                    Checklist
                  </div>
                  <div style=
                  {{
                    width: '50%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide ? '#000000' : '#888888'
                  }} 
                  onClick={(e)=> setTabSlide(true)}>
                    Completed
                  </div>
                </div>
                <div style={{width: '100%', height: 7, marginTop: -7}}>
                  <animated.div style={tabBar}></animated.div>
                </div>
              </div>


              <animated.div style={subpageWrapper}>
                <div style={{ width: '100%', opacity: tabSlide ? 0 : 1}}>

                  <Fragment>
                    <div style={{height: props.screenSize.height - 135, overflowX: 'hidden', overflowY: 'auto',
                      // boxSizing: 'border-box', border: '3px solid red'
                    }}>
                      <SavedResources rt={props.rt} pt={pt} setShowModal={props.setShowModal} setModalContent={props.setModalContent} />
                      <LiveLearning rt={props.rt} pt={pt} tabSlide={tabSlide} setShowModal={props.setShowModal} setModalContent={props.setModalContent} wrapper={props.wrapper} />
                      <ChallengingAssignment rt={props.rt} pt={pt} tabSlide={tabSlide} setShowModal={props.setShowModal} setModalContent={props.setModalContent} />
                    </div>
                  </Fragment>
                </div>
                <div style={{width: '100%', opacity: tabSlide ? 1 : 0}}>

                  <div>
                    <div style= {{
                      display: 'flex', flexDirection: 'column', 
                      alignContent: 'flex-start', justifyContent: 'flex-start',
                      margin: 0,
                      }}
                    >
                      <Fragment>
                        
                        <CompletionLog rt={props.rt} pt={pt} wrapper={props.wrapper} screenSize={props.screenSize} user={props.user}  
                          setShowModal={setShowModal} setModalContent={setModalContent}
                        />

                      </Fragment>
                    </div>
                  </div>
                </div>
              </animated.div>
            </div>

        </animated.div>

        <Modal show={ showModal ? true : false} width={'80%'}>
          {modalContent}
        </Modal>  

      </Fragment>
    : ''
    }
    </div>
  )
}
