import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions } from '../../vinciToolbox';
import { NavBackIcon } from '../svg/svg_icons';
import { NavButton } from '../components'; 
import QuoteOfTheDay from './QuoteOfTheDay';
import LiveLearning from './LiveLearning';
import UserRoles from './UserRoles';

import { Modal } from '../Phase/AssessPages/Modal';
import firebase from 'firebase';

export const Admin=(props)=> {
  let [showModal, setShowModal] = useState(false);
  let [modalContent, setModalContent] = useState('');

  let pt = usePageTransitions('/admin', 1)
  let transition = useSpring({
    transform: `translateY(${pt.animate ? 0 : props.screenSize.height}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    overflowX: 'hidden', overYflow: 'auto', 
    display: pt.render ? 'block' : 'none'
  });

  let db = firebase.firestore();

  /**  TEST if current user should see this page */
  let [userPermitted, setUserPermitted] = useState(false);
  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    for(let i = 0; i < data.editLibrary.length; ++i){
      if(data.editLibrary[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editLiveLearning.length; ++i){
      if(data.editLiveLearning[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editPermissions.length; ++i){
      if(data.editPermissions[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editQuotes.length; ++i){
      if(data.editQuotes[i] === userName){
        tempResult = true;   
      }
    }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */

  let [tabSlide, setTabSlide] = useState(0);
  let tabBar = useSpring({config: {tension: 300},
    height: '100%', width: '25%',
    backgroundColor: '#ff9933',
    transform: `translateX(${ tabSlide * (props.wrapper.style.width / 4)}px)`
  })

  let contentSlide = useSpring(
    { config: {tension: 400},
      width: props.wrapper.width * 4,
      overflow: 'hidden',
      transform: `translateX(${ -tabSlide * (props.wrapper.style.width)}px)`,
      display: pt.animate ? 'flex' : 'none',
    }
  )
 
  const refWrapper = useRef(null);
  return(
    <Fragment>
        <div style={{backgroundColor: '#cccccc'}}>

          <animated.div  className="Phases-sidein-page" style={transition}>

            <div className="Phases-subpage-header">
              <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
                <NavButton variant="secondary" name="back" size={50} >
                  <div style={{}}>                  
                    <NavBackIcon height="50" name="liveLearning" />
                  </div>
                </NavButton> 
              </div>
              {userPermitted ?
              <div className="Phase-subpage-header-text" style={{width: '100%', display: 'flex', justifyContent: 'center', marginLeft: -35}}>
                Admin Pages
              </div>
              : ''
              }
            </div>
            {userPermitted ?

            <div>
            <div style={{
              display: 'flex', fontWeight: 600, cursor: 'pointer',
            }}>
              <div style= {{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 0 ? '#000000' : '#888888',
                  backgroundColor: '#e6e6e6',
                }} onClick={(e)=> setTabSlide(0)}
              >
                Quote of<br />the Day
              </div>
              <div style={{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 1 ? '#000000' : '#888888',
                  backgroundColor: '#e6e6e6',
                }} onClick={(e)=> setTabSlide(1)}
              >
                Live<br />Learning
              </div>
              <div style={{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 2 ? '#000000' : '#888888',
                  backgroundColor: '#e6e6e6',
                }} onClick={()=> props.rt.nav('/plan/resources/add_resources')} 
              >
                Resource<br />Library
              </div>    
              <div style={{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 3 ? '#000000' : '#888888',
                  backgroundColor: '#e6e6e6',
                }} onClick={(e)=> setTabSlide(3)}
              >
              Edit<br />Admins
              </div>
              
              </div>
              <div style={{width: '100%', height: 7, marginTop: -7}}>
                <animated.div style={tabBar}></animated.div>
              </div>

              <animated.div ref={refWrapper} style={contentSlide} >
                <div style={{width: props.wrapper.width}}>
                  {tabSlide === 0 ?
                    <div>
                      <QuoteOfTheDay rt={props.rt} wrapper={props.wrapper} screenSize={props.screen && props.screen.size} user={props.user}
                        setShowModal={setShowModal} setModalContent={setModalContent}  />
                    </div>
                    : ''
                  }
                </div>
                <div style={{width: props.wrapper.width}}>
                  {tabSlide === 1 ?
                    <div>
                      <LiveLearning rt={props.rt} wrapper={props.wrapper} screenSize={props.screen && props.screen.size} user={props.user}
                        setShowModal={setShowModal} setModalContent={setModalContent}  />
                    </div>
                  : ''
                  }
                </div>
                <div style={{width: props.wrapper.width}}>
                  {tabSlide === 2 ?
                    <div>
                    
                    </div>
                  : ''
                  }
                </div>
                <div style={{width: props.wrapper.width}}>
                  {tabSlide === 3 ?
                    <div>
                      <UserRoles rt={props.rt} wrapper={props.wrapper} screenSize={props.screen && props.screen.size} user={props.user} 
                        setShowModal={setShowModal} setModalContent={setModalContent} 
                      />
                    </div>
                    : ''
                    }
                </div>
              </animated.div>
            </div>          
          : 
            <div style={{textAlign: 'center', marginTop: 50, fontWeight: 500, color: 'red'}}>
              <div>YOU DON'T HAVE ACCESS TO THIS PAGE</div>
            </div>
          }
        </animated.div>
      </div>

      <Modal show={ showModal ? true : false} width={'80%'}>
        {modalContent}
      </Modal>  

    </Fragment>
  )
}


// const UserRoles = (props) => {
//   return(
//     <div style={{textAlign: 'center', padding: 30}}>
//       Not yet implemented
//     </div>
//   )
// }



