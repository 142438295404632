import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../../vinciToolbox';
import { NavBackIcon, InfoIcon } from '../../../svg/svg_icons';
import { AutocompleteUsers, NavButton, CCButton } from '../../../components'; 
import { getPhotoUrlFromEmail } from '../../../helper_functions';
import { Modal } from '../../AssessPages/Modal';
import firebase from 'firebase';
import '../../../../firebase-init.js';
import '../../AssessPages/Assessment.css'; // Uses Assessment styling
import './DevRelationships.css'
// import { Modal } from '../../AssessPages/OtherAssessment/OtherAssessment';

let db = firebase.firestore();

export const DevRelationships=(props)=> {
  let pt = usePageTransitions('/dev_relationships', 2)
  
  let segment = usePathSegments(null);
  let [thisPage, setThisPage] = useState('');
  // let [relationshipType, setRelationshipType] = useState('');
  let relationshipType = segment[2] && segment[2].split(':')[1];
  useEffect(()=>{
    // setRelationshipType(segment[2] && segment[2].split(':')[1]);
    setThisPage(segment[2] && segment[2].split(':')[0])
  },[segment[2]]);

  useEffect(()=>{
    
    //selected
  },[])
  
  let relType = '';
  let relDescription = '';
  switch(relationshipType){
    case 'manager':
      relType = 'Manager';
      relDescription = <p>1. The person who will participate in my assessment to give me data and insight.</p>;
      break;
      case 'mentor':
        relType = 'Mentor/Sounding Board';
        relDescription = 
        <Fragment>
          <p>1. The person to whom I will bring my ideas for reaction and fine-tuning.</p>
          <p>2. The person who will help me make sense of the feedback data from my assessment.</p>
          <p>3. The person who will provide emotional support during the difficulties of the learning process.</p>
        </Fragment>;
        break;
      case 'model':
        relType = 'Role Model/Dialog Partner';
        relDescription = 
        <Fragment>
          <p>1. The person I respectfully observe and try to emulate.</p>
        </Fragment>;
        break;
      case 'partner':
        relType = 'Challenge Partner';
        relDescription = 
        <Fragment>
          <p>1. The person who can sponsor me in gaining access to challenging assignments that become available.</p>
          <p>2. The person who can help me explore an issue more deeply by questioning, tussling, and reflecting on underlying assumptions.</p>
          <p>3. The person who will hold me accountable for the goals I have set.</p>
        </Fragment>;
        break;
      case 'cohort':
        relType = 'Cohort';
        relDescription = 
        <Fragment>
          <p>1. From among those who are my peers in similar circumstances.</p>
        </Fragment>;
        break;
  }
  let transition = useSpring({
    config: {tension: 200},
    transform: `translateX(${pt.animate ? 0 : props.wrapper.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    boxShadow: '-5px 0 5px rgba(0,0,0,0.1)',
    overflow: 'auto',
    display: pt.render ? 'block' : 'none'
  });

  let [value, setValue] = useState([]);
  // console.log('DevRelationships - value: ', value)
  let [showModal, setShowModal] = useState(false);

  let docRef = db.doc(`campbell_compass/${props.user && props.user.email}_dev_relationships`);
  useEffect(() => {    
    setShowModal(false);
    setSelectedUserRecord('');
    docRef.get()
    .then((doc) => {
      if(doc && doc.exists){
        let relationshipsList = doc.data();
        if(relationshipType){
          let results = relationshipsList.hasOwnProperty(relationshipType);
          // console.log('DevRepationships - relationshipType: ', relationshipType)
          if(results === false){ // If relationship property is not found then it needs to be created
            setShowModal(true); // Not found so show the model for entering in the desired relationship
          }else{
            // Found so show it
            setSelectedUserRecord(doc.data()[relationshipType]);
          }
        } 
      }
    })
  },[segment[2], props.user])

  let [selectedUserRecord, setSelectedUserRecord] = useState('');
  let [clear, setClear] = useState(false)

  /**
   * 
   * @param {string} recipient // selected user's email
   * @param {boolean} closeModal 
   */
  const updateRelationship = (recipient, closeModal) => {
    setClear(true); // Clear the Autosugges input field
    if(closeModal) 
      setShowModal(false);
    let usersArray = [];
    // Gets the list of all compass app users
    docRef = db.doc('campbell_compass/users');
    docRef.get().then(function(doc) {
      if (doc && doc.exists) {
        usersArray = Object.values(doc.data().users);
        let theSelected = recipient // Get the email from the recipient string
console.log('usersArray: ', usersArray)
        let theEmail = '';
        for(let i = 0; i < usersArray.length; ++i){
          
          if(usersArray[i].displayName === recipient){
            // console.log('usersArray.email: ', usersArray[i].email)
            theEmail = usersArray[i].email;
          }
        }
        console.log('xxxxxxxxxxxxxxxxxxtheEmail: ', theEmail)
        for(let i = 0; i < usersArray.length; ++i){ // Find the user record that matches the email
          if(theEmail === usersArray[i].email){
            // console.log('user record found: ', usersArray[i]);
            // Write the record to the [relationshipType] object
            docRef = db.doc(`campbell_compass/${props.user && props.user.email}_dev_relationships`);
            docRef.set({ 
              [relationshipType]: usersArray[i]
            }, { merge: true })
            .then(()=>{
              setSelectedUserRecord(usersArray[i])
              console.log('SUCCESS');
            }).catch((error)=> {
              console.log('Something went wrong: ', error);
            })
          }
        }
      } else {
        console.log("No such document!");
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  }

  // const handleContinue = () => {
  //   // updateList(value, true); // Enable the blue Continue link
  // }

  // let [showInfo, setShowInfo] = useState(false);

  // let infoTransition = useSpring({
  //   opacity: showInfo ? 1 : 0
  // })


  let [nameFound, setNameFound] = useState(false);
  
  useEffect(()=>{
    if(!value){
      // console.log('THE FIELD IS EMPTY')
      setNameFound(false)
    }else{
      // console.log('THE FIELD HAS STUFF IN IT')
    }
  })

  const handleContinue = () => {
    // updateList(value, true); // Enable the blue Continue link
    updateRelationship(value, true)
  }

  let [showInfo, setShowInfo] = useState(false);

  let infoTransition = useSpring({
    opacity: showInfo ? 1 : 0
  })

  let [photoUrl , setPhotoUrl] = useState('');
  useEffect(()=>{
    setPhotoUrl('')
  },[])

  // get eighter the user photo or the default user image
  getPhotoUrlFromEmail( selectedUserRecord.email )
  .then((imageUrl)=>{
    // console.log(imageUrl);
    setPhotoUrl(imageUrl)
  })
  .catch((error)=>{
    console.log('ERROR - ', error);
    setPhotoUrl('')
  })



  // /**
  //  * Gets the photo url of the selected user
  //  * @param  {String} email - the email of the person whose photo we're getting
  //  */
  // const getPhotoUrl = (email) => {
  //   var storageRef = firebase.storage().ref();
  //   storageRef.child(`user_photos/${email}.jpg`).getDownloadURL()
  //   .then((url) => {
  //     console.log('url: ', url)
  //     setPhotoUrl(url)
  //   })
  //   .catch((error) => {
  //     // Handle any errors
  //   });
  // }
  useEffect(()=> {
    // getPhotoUrl( selectedUserRecord.email )
  },[selectedUserRecord]);

  return(
    <Fragment>
        <div>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ setPhotoUrl(''); window.history.go(-1)} }>
            <NavButton variant="secondary" name="back" size={50} >
              <NavBackIcon height="50" name="devRelationships" />
            </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>{relType}</div>
          </div>
          <div className="Assess-description-block" style={{backgroundColor: '#f4f4f4', minHeight: 50, paddingBottom: 5}}>
            <div className="Assess-heading" style={{marginBottom: -40, fontSize: '100%'}}>My {relType} is:</div>
            <div className="Assess-subheading" style={{marginLeft: 0, fontSize: '90%'}}>{relDescription}</div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 35, color: '#444'}}>
            
            <NavButton  size={180}>
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
              <div style={{
                  backgroundColor: '#9d9d9d',
                  width: 170,
                  height: 170,
                  borderRadius: '50%', 
                  overflow: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                  <img src={photoUrl} width={173} height={173} />
                </div>
                </div>
              </NavButton>
              <div style={{textAlign: 'center', fontWeight: 600}}>
                <p style={{fontSize: '170%', fontWeight: 500}}>{selectedUserRecord.displayName}</p>
                <p>{selectedUserRecord.jobTitle}</p>
                <p>{selectedUserRecord.email}</p>
                <p>{selectedUserRecord.officeLocation}</p>
                <p>{selectedUserRecord.businessPhones && selectedUserRecord.businessPhones[0]}</p>
              </div>

              <div className="Assess-button-wrapper" style={{marginTop: 30, position: 'static', // top: props.screenSize.height -90
            
              }}> 
                <div onClick={()=> { setShowModal(true);  setValue('') }}>
                  <CCButton><div style={{padding: '0 30px'}}>Change my selection</div></CCButton>
                </div>
              </div>
          </div>
        </animated.div>
      </div>





            <Modal show={showModal ? true : false} width={'95%' } >
            <div style={{display: 'flex', flexDirection: 'column', width: props.wrapper.width - 0, }}>
              <div style={{width: '100%', textAlign: 'center', fontSize: '115%', fontWeight: 600, color: '#606060', margin: '-5px 0 18px 0'}}>
                Who is your {relType}?
              </div>
              <div >
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <div className="OtherAssessment" 
                    style={{display: 'flex', justifyContent: 'center', 
                    position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
                  >
                    <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', justifyContent: 'center'}}>
                      <AutocompleteUsers value={value} setValue={setValue} 
                        clear={clear} setClear={setClear} nameFound={nameFound} setNameFound={setNameFound} 
                        setShowModal={setShowModal} handleContinue={handleContinue}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

    </Fragment>
  )
}



// export const Modal = (props)=>{
//   // console.log('Modal - props:', props)
//   let springStyleForModalOverlay = useSpring({
//     marginTop: '-15%',
//     position: 'fixed',
//     top: 0, right: 0, bottom: 0, left: 0,
//     width: '100%', // props.screenSize.width,
//     backdropFilter: 'blur(2px)',
//     backgroundColor: 'rgba(0,0,0,0.05)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     opacity: props.show ? 1 : 0,
//     display: `${props.show ? 'flex' : 'none'}`
//   })
//   let springStyleForModalPopup = useSpring({config: {tension: 250},
//     backgroundColor: '#ffffff',
//     margin: '1%',
//     // marginBottom: '5%',
//     maxWidth: props.width,
//     maxWidth: 450,
//     minWidth: 200,
//     minHeight: 50,
//     padding: 30,
//     borderRadius: 10, 
//     transform: `scale(${props.show ? 1 : 0.4},${props.show ? 1 : 0.4})`,
//     fontWeight: 600,
//     display: `${props.show ? 'flex' : 'none'}`
//   })
//   return(
//     <div>
//       <animated.div style={springStyleForModalOverlay}>
//         <animated.div style={springStyleForModalPopup}>
//           {props.children}
//         </animated.div>
//       </animated.div>
//     </div>
//   )
// }