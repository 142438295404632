
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { ExecutionNotes } from '../../svg/svg_icons';
import { CustomCheck } from '../../svg/svg_icons';
import getTimestamp from './getTimestamp'
import './Execute.css'

import firebase from 'firebase';
let db = firebase.firestore();



const SavedResources = (props) => {
  // console.log('SavedResources - props: ', props)

  let unsubscribe = null;
  let [resources, setResources] = useState([]);
  useEffect(()=>{
    const getRealtimeUpdates = () =>{
      unsubscribe = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_collection`)
      .onSnapshot((doc)=>{
        if(doc && doc.exists) {
          // console.log('doc.data()', doc.data().collection);
          setResources(doc.data().collection)
        }else{
          console.log('onSnapshot error happened')
        }
      }, (error)=> {
        console.log('error=', error);
        return( null );
      });
    }
    getRealtimeUpdates();  
  },[]);
  if(!props.pt.render, props){
    if(unsubscribe){
      unsubscribe();
    }
  }

  let resourceList = [];
  for(let i = 0; i < resources.length; ++i){
    resourceList.push({
      id: i,
      data: resources[i]
    })
  }

  const imageRef = useRef(null);
  const handleSize = (image) => {
    // if(image.naturalHeight > image.naturalWidth){
    //   image.style.width = '90px'
    // }
  }

  const handleContinue = (index) => {
    console.log('inside handleContinue - index = ', index)
    // This logs the item identified by Index to the Competion Log 
    // Get indexed record from the Saved Resources doc
    let indexedRecord = {};
    let docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_collection`);
    docRef.get().then((doc) => {
      if(doc && doc.exists){
        let collection = doc.data().collection;
        indexedRecord = collection[index];

        // This deletes from Save Resources the item identified by the index number.
        docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`); 
        let logArray = [];
        docRef.get()
        .then((doc) => {
          if(doc && doc.exists){ // DOCUMENT EXISTS, LOG THE INDEXED ITEM
            let completed = doc.data().completed;
            logArray = completed;
            // console.log('completed = ', completed)
            // Add timestamp element to logArray object.
            indexedRecord['timestamp'] = getTimestamp()
            logArray.push(indexedRecord)
            // console.log('logArray = ', logArray);
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: logArray
            }, { merge: true })
            .then(()=>{
              console.log('SUCCESS - item added to completed log');
            }).catch((error)=> {
              console.log('you screwed up!: ', error);
            })
          }else{ // DOCUMENT DOESN'T EXIST, SO CREATE IT AND LOG THE INDEXED ITEM
            docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_completed`);
            docRef.set({
              completed: [indexedRecord] // Initialize the log
            })
            .then(() => {
              console.log('SUCCESS - Completed log created');

            }).catch((error) => {
              console.log('ERROR in set() operation', error)
            });
          }
          // REMOVE INDEXED ITEM FROM SAVED RESOURCES
          docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_collection`); 
          docRef.get()
          .then((doc) => {
            let array = doc.data().collection;
            if (index > -1) {
              array.splice(index, 1);
            }
            docRef.set({
              collection: array
            }).then(() => {
              console.log('SUCCESS - removed item from Save Resources')
            })
          })
          .catch(() => {
            console.log('Couldn\'t retrieve document')
          })
          props.setShowModal(false)

        }).catch((error) => {
          console.log('ERROR in get() operation: ', error);
        })
        console.log('collection = ', collection)
      }
    }).catch((error) => {
      console.log('ERROR in retrieving saved resources', error)
    })




  }

  const handleChecked = (id, data) => {
    // console.log('inside handleChecked - data = ', data)
    props.setModalContent(
      <div>
        <div>
          <div style={{fontSize: '105%', fontWeight: 600, color: '#000',  textAlign: 'center', margin: '10px 0 15px'}}>
            This action will move the <ResourceType type={data.type} /> to Completed 
            and will remove it from your Saved Resources.
          </div>
          {/*
            <div style={{fontSize: '100%', fontWeight: 600, color: 'tomato',  textAlign: 'center', margin: '0 0 20px 0'}}>
            This action is non-reversable
          </div>
          */}
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
          <div style={{ color: '#808080', cursor: 'pointer'}} onClick={()=> props.setShowModal(false) }>
            Cancel
          </div>
          <div style={{
            color: '#F5B01B',
            cursor: 'pointer',
          }} 
            onClick={() => handleContinue(id)}>
            Continue  
          </div>
        </div>
      </div>
    )
    props.setShowModal(true);
  }

  const ResourceType = (props) => {
    switch(props.type) {
      case 'books':
        return 'Book'
        break;
      case 'videos':
        return 'Video'
        break;
      case 'weblinks':
        return 'Website'
        break;
      case 'podcasts':
        return 'Podcast'
        break;
    }
  }



  let theResources = resourceList.map((res) =>   
    <div key={res.id} >
    <div style={{margin: '22px 0 15px 20px', display: 'flex', cursor: 'pointer', overflowX: 'hidden' }} >
    {res.data.type==='books' ?
      <Fragment>

        <div style={{position: 'relative', height: 'auto'}} >
          <img src={res.data.image} style={{ 
            minHeight: 50, height: 'auto', width: 75,
            boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
            backgroundColor: '#c6c6c6',
            marginTop: -5,
            transform: 'scale(0.75)',
            // border: 'red' // '1px solid rgba(0,0,0,0.2)'
          }}
          ref={imageRef}
          onLoad={()=> handleSize(imageRef.current)}
          />
        </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'row', marginLeft: -10 }} >
          <div style={{margin: '0 0px 0 10px', alignContent: 'stretch', overflow: 'auto'}} >
            <div style={{margin: '0 10px 4px 0px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
              <div style={{margin: '0 20px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555',}}>
                <div style={{display: 'flex', justifyContent: 'space-between', }}>
                  <div style={{overflow: 'hidden', overflowWrap: 'no-wrap', textOverflow: 'ellipsis', }} >
                    {/*domain*/}
                  </div>
                  <div style={{display: 'flex', justifyContent: 'flex-end', width: 100,}}>
                    {/*<Stars rating={res.data.rating} />*/}
                  </div>
                </div>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '0 20px 0 15px', fontWeight: 500, color: '#222'}} >
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 600, fontSize: '105%', color: '#333'}}>
              <div style={{width: '100%'}}><span style={{opacity: .6}}><ResourceType type={res.data.type} /></span>: {res.data.title}: {res.data.title}</div>
              <div style={{transform: 'scale(1.1)', cursor: 'pointer'}} onClick={(e) => handleChecked(res.id, res.data)}>
                <CustomCheck size={25} backgroundColor={false ? "#F9C908" : "#cccccc"} color="#555" borderColor={false ? "transparent" : "#c6c6c6"} />
              </div>
            </div>
              {/*<ExecutionNotes size={25} onClick={() => props.rt.nav('/execute/execute_notes') } />*/}
            </div>
            <div style={{
              margin: '5px 35px 8px 15px', 
              fontSize: '90%', fontWeight: 500, color: '#000000', 
              overflow: 'hidden',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
            }}  onClick={(e)=> { window.open(res.data.url, "_blank")}}>
              {res.data.description}
            </div>
          </div>
        </div>
      </Fragment>
    :
      <Fragment>
        <div style={{position: 'relative', height: 'auto'}} >
          <img src={res.data.image} style={{ 
              minHeight: 50, height: 'auto', minWidth: 75, maxWidth: 75,
              boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
              backgroundColor: '#c6c6c6',
              marginTop: 5
            }}
            ref={imageRef}
            onLoad={()=> handleSize(imageRef.current)}
          />
          </div>
        <div style={{display: 'flex', width: '100%', flexDirection: 'row', margin: '-4px 0 0 0', overflowX: 'hidden', }} >
          <div style={{margin: '0 0px 0 5px', alignContent: 'stretch', overflow: 'auto'}} >
            <div style={{margin: '0 20px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
              <div style={{display: 'flex', justifyContent: 'space-between' }}>
                <div style={{overflow: 'hidden', overflowWrap: 'no-wrap', textOverflow: 'ellipsis', }} >
                  {/*domain*/}
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: 100,}}>
                  {/*<Stars rating={res.data.rating} />*/}
                </div>
              </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', margin: '0 20px 0 15px', fontWeight: 500, color: '#222'}} >
              <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 600, fontSize: '105%', color: '#333'}}>
                <div style={{width: '100%'}}>
                  <span style={{opacity: .6}}><ResourceType type={res.data.type} /></span>: {res.data.title}
                </div>
                <div style={{transform: 'scale(1.1)', cursor: 'pointer'}} onClick={(e) => handleChecked(res.id, res.data)}>
                  <CustomCheck size={25} backgroundColor={false ? "#F9C908" : "#cccccc"} color="#555" borderColor={false ? "transparent" : "#c6c6c6"} />
                </div>
              </div>
            </div>
            <div style={{
              margin: '5px 25px 8px 15px', 
              fontSize: '90%', fontWeight: 500, color: '#000000', 
              overflow: 'hidden',
              overflowWrap: 'break-word',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 4,
              WebkitBoxOrient: 'vertical',
            }}  onClick={(e)=> { window.open(res.data.url, "_blank")}} >
              {res.data.description}
            </div>
          </div>
        </div>
      </Fragment>           
    }
  </div>

    </div>
  )

  return(
    <div>
      {theResources.length ?
        <Fragment>
          <div style={{fontSize: '125%', fontWeight: 800, color: '#444', margin: '40px 25px 10px 25px', paddingBottom: 10, borderBottom: '1px solid #333' }}>
            Saved Resources
          </div>
          <div style={{marginBottom: 15}}>
            {theResources}
          </div>
        </Fragment>
      : ''
      }
    </div>
  )
}

export default SavedResources;