import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../../../../../vinciToolbox';
import { BooksColored, VideosColored, WeblinksColored, NavBackIcon, DeleteIcon, RefreshIcon} from '../../../../../../../svg/svg_icons';
import { NavButton, Modal } from '../../../../../../../components'; 
import { compassDefinition } from '../../../../../../AssessPages/compassDefinition';
import { OneResource } from './OneResource';
import { Stars, StarsButtons } from '../../../Stars';
import { InputGroup, Form, FormControl } from 'react-bootstrap';
import firebase from 'firebase';
import '../../../../../../../../firebase-init';
import '../../../../../../Phase.css';
import './EditResourceLibrary.css';

var opengraph = require('opengraph-io')({
  appId: 'dbb6a0b5-2b98-422a-90ff-66992c73ef73', 
  cacheOk: true, // If a cached result is available, use it for quickness
  useProxy: true,  // Proxies help avoid being blocked and can bypass capchas
  maxCacheAge: 432000000, // The maximum cache age to accept
  acceptLang: 'en-US,en;q=0.9', // Language to present to the site. 
  fullRender: false // This will cause JS to execute when rendering to deal with JS dependant sites
});

export const EditResourceLibrary=(prps)=> {
  let props = prps.props;
  // console.log('EditResourceLibrary - prps=', prps);
  const [dummy, reload] = useState(false);
  let segment = usePathSegments();
  let resourceType = segment[5] && segment[5].split(':')[1];
  // console.log('props=', prps)

  let comp = null;
  let [resource, setResource] = useState(null);

  useEffect(()=>{
    if(segment[3]){
      // console.log('segment=',segment);
      setResource({competencies: ['dummy data'] });
      let p = segment[4] && segment[4].split(':');
      // console.log('p=', p)
      if(p){
        let temp = p[1] / 3;
        let cat = Math.floor( temp )
        comp = ((p[1] / 3) % 1);
        comp = Math.floor(comp * 10 / 3);
      }
    }
  },[props])

  // console.log('resource=', resource);

  let pt = usePageTransitions('/edit_resource_library', 5)
  let transition = useSpring({config: {tension: 300},
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: prps.props.wrapper.width, 
    height: prps.props.wrapper.style.height,
    overflowX: 'hidden', overflowY: 'scroll',
    display: pt.render ? 'block' : 'none'
  });

  let dropdownLine = {
    height: 15,
    width: 30,
    margin: '-10px 10px 0 5px',
    borderLeft: '1px solid rgba(0,0,0,0.7)',
    borderBottom: '1px solid rgba(0,0,0,0.7)',
    color: 'rgba(0,0,0,0)'
  }

  let resourceName = '';
  if(resourceType==='books'){
    resourceName = 'Books'
  }
  if(resourceType==='videos'){
    resourceName = 'Videos'
  }
  if(resourceType==='weblinks'){
    resourceName = 'Weblinks'
  }
  if(resourceType==='podcasts'){
    resourceName = 'Podcasts'
  }

  // console.log('1 props=', props)
  let [modalState, setModalState] = useState(false);
  let [editModal, setEditModal] = useState(false)
  let [selectedResource, setSelectedResource] = useState('');
  let [selectedIndex, setSelectedIndex] = useState('');
  
  // if(selectedResource) console.log('selectedResource=', selectedResource)

  let refHeader = useRef(null)

  /** START Edit Resource code */
  let [title, setTitle] = useState('');
  let [description, setDescription] = useState('')
  let [linkUrl, setLinkUrl] = useState('')
  let [imageUrl, setImageUrl] = useState('');
  let [rating, setRating] = useState(0);

  useEffect(()=>{
    setTitle(selectedResource.title)
    setDescription(selectedResource.description)
    setLinkUrl(selectedResource.url)
    setImageUrl(selectedResource.image)
    setRating(selectedResource.rating)

  },[editModal])

  const handleTitle = (e) => {
    setTitle(e.target.value)
  }
  const handleDescription = (e) => {
    setDescription(e.target.value)
  }
  const handleLinkUrl = (e) => {
    setLinkUrl(e.target.value)
  }
  const handleImageUrl = (e) => {
    setImageUrl(e.target.value)
  }
  const handleRating = (e) => {
    setRating(e.target.value)
  }

  // This is a function
  const handleEditSave = (e) => {
    console.log('e: ', e)

    let db = firebase.firestore();

    let comp = ''
    let type = ''
    if(segment[4]){ 
      comp = segment[4].split(':') 
    }
    if(segment[5]){ 
      type = segment[5].split(':') 
    }
    let p = segment[4] && segment[4].split(':');

    let editedFields = {};
    editedFields = {
      title: title,
      image: imageUrl ,
      url: linkUrl,
      description: description,
      rating: !rating ? 0 : rating
    }
    let docRef = db.doc(`campbell_compass/resource_library_${p && p[1]}_${p && p[2]}`);
      docRef.get()
      .then((doc)=>{
        let editedArray = doc.data()[type[1]];
        editedArray[selectedIndex] = editedFields;
      // console.log('editedArray ', editedArray);

        docRef.set({
          [type[1]]: editedArray
        }, { merge: true }) // merge is used to prevent overwriting data
        .then((doc)=> {
          console.log('successfully merged')
          setTimeout(()=>setEditModal(false), 750 ); // setTimeout is used to fake the apperance that the resources is updating.
        })
        .catch((error)=> {
          console.error(
            "Error getting document to Firestore: ", error
          );
        });

      })
      .catch((error)=> {
        console.error(
          "Error getting document to Firestore: ", error
        );
      });
    }
    /** END Edit Resource code */

  return(
    <Fragment>

      <animated.div  className="Phases-sidein-page" style={transition}>
      <div ref={refHeader} style={{}}>
        <div className="Phases-subpage-header" >
          <div className="Phase-subpage-header-icon" onClick={()=>window.history.go(-1)}>
            <NavButton variant="secondary" name="back" size={50} >
              <NavBackIcon height="50" name="editResource"/>
            </NavButton> 
          </div>
          <div className="Phase-subpage-header-text" style={{margin: '0 auto', color: 'red'}}>
            Edit {resourceName}
          </div>
          <div style={{opacity: 0.4, padding: 5, cursor: 'pointer'}} onClick={()=>window.location.reload(true) } ><RefreshIcon scale={1.7} /></div>
        </div>
        <div className="Phases-description-block" style={{backgroundColor: 'white'}}>
          <div>{prps.competency}
            <div style={{display: 'flex', justifyContent: 'flex-start' , marginTop: 15}}>
            <div style={dropdownLine}>xx</div>
            <div style={{marginTop: -5}}>{prps.compassPoint //.match(/.+:/)
            }</div>
          </div>
        </div>
        </div> 
        </div>
        <ResourceList setEditModal={setEditModal}
          pt={prps.pt}
          screenSize={props.screenSize} wrapper={props.wrapper}
          headerHeight={refHeader.current && refHeader.current.offsetHeight} 
          user={props.user} 
          resource={resource} resourceType={resourceType} resourceName={resourceName}
          data={prps.data && prps.data.data}
          dummy={dummy} reload={reload}
          setSelectedResource={setSelectedResource} setSelectedIndex={setSelectedIndex}
        />
      </animated.div>
      
      <Modal show={modalState} screenSize={props.screenSize} wrapper={props.wrapper}>
        <div style={{textAlign: 'center', color: '#666666', fontSize: '120%'}}>
          <div>
            The URL is invalid.
          </div>
          <div style={{paddingTop: 15, width: '100%'}} onClick={()=>setModalState(false)}>Close</div>
        </div>
      </Modal>

      <EditModal show={editModal} screenSize={props.screenSize} wrapper={props.wrapper}>
        <div style={{textAlign: 'left', color: '#666666', fontSize: '100%', width: '100%',}}>
          <div style={{textAlign: 'center', fontSize: '130%', marginBottom: 15, color: '#333'}}>Edit Resource</div>
            <div>

          {/* Rating field */}
          <div style={{width: '100%', display: 'flex', justifyContent: 'center', overflowX: 'hidden', overflowY: 'auto'}}>
            <div style={{ position: 'relative'}}>
              <div style={{pointerEvents: 'none' }}>
                <StarsButtons rating={rating} />
              </div>
              <div style={{display: 'flex', position: 'absolute', top: -8, left: -25}} >
                <input style={{opacity: 0, height: 50, width: 190}} 
                  type="range"  min="0" step="1" max="5" value={0} onChange ={handleRating} />
                <div onClick={(e)=>{setRating(0)}} style={{margin: '10px 0 0 15px', padding: '3px 8px', cursor: 'pointer'}}>
                  <DeleteIcon size={25} />
                </div>
              </div>
            </div>
          </div>

            {/* Title field */}
            <Form.Group >
              <Form.Label>Title</Form.Label>
              <FormControl value={title} onChange={handleTitle} />
            </Form.Group>

            {/* Description field */}
            <Form.Group >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={4} value={description} onChange={handleDescription}/>
            </Form.Group>

            {/* Link URL field */}
              <Form.Group >
              <Form.Label>Link URL</Form.Label>
              <FormControl value={linkUrl} onChange={handleLinkUrl} />
            </Form.Group>

            {/* Imafe URL field */}
              <Form.Group >
              <Form.Label>Image URL</Form.Label>
              <FormControl value={imageUrl} onChange={handleImageUrl} />
            </Form.Group>
          
          <div style={{textAlign: 'center', fontSize: '110%', margin: '13px 0 8px 0', color: '#333'}}>Preview</div>

          <div style={{display: 'flex', alignItems: 'center', margin: '0 -20px 15px -20px', padding: '0 20px 0 20px',
          borderBottom: '1px solid rgba(0,0,0,0.20)',
          borderTop: '1px solid rgba(0,0,0,0.20)',
          backgroundColor: '#e6e6e6',
          //position: 'relative',
          }}>
            <div style={{margin: '0px -20px 0px -20px', display: 'flex', overflow: 'hidden',  }} >
              <div style={{padding: '20px 0 0 20px'}}>
                <img src={imageUrl} style={{ 
                  minHeight: 50, width: 'auto', minWidth: resourceType==='books'? 100 : 130, maxWidth: resourceType==='books'? 100 : 130,
                  boxShadow: '0px 2px 5px rgba(0,0,0,0.3)',
                  backgroundColor: '#c6c6c6', borderBottom: 15
                }} 
                //ref={imageRef}
                // onLoad={()=> handleSize(imageRef.current)}
                />
              </div>
              <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer', userSelect: 'none', }} 
                onClick={(e)=> window.open(linkUrl, "_blank") }
              >
                <div style={{margin: '0 0px 4px 15px', fontSize: '90%', fontWeight: 400, color: '#55555'}}>
                  {/*<div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px 15px 5px 0'}}>
                    <Stars rating={rating} />
                  </div>*/}
                </div>
                <div style={{margin: '0 35px 0 15px', fontWeight: 500, color: '#222'}}>
                  {title}
                </div>
                <div style={{
                  margin: '8px 15px', fontSize: '85%', color: '#222222',
                  // overflow: 'hidden',
                  // overflowWrap: 'break-word',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 4,
                  WebkitBoxOrient: 'vertical',
                  paddingBottom: 10
                }}>
                  {description}
                </div>
              </div>
            </div>
          </div> 


          </div>
          <div style={{display: 'flex', fontSize: '130%', marginTop: 20}}>
            <div style={{paddingTop: 15, width: '100%', textAlign: "center", cursor: 'pointer'}} 
              onClick={()=>setEditModal(false)}
            >
              Cancel
            </div>
            <div style={{paddingTop: 15, width: '100%', textAlign: "center", cursor: 'pointer', color: 'rgb(0, 133, 255)'}}
              onClick={(e)=>handleEditSave(e)}
            >
              Save
            </div>
          </div>
        </div>
      </EditModal>

    </Fragment>
  )
}

const ResourceList = (props) => {  
  // console.log('ResourceList props=', props);

  let [docExists, setDocExists] = useState(false)

  let segment = usePathSegments();
  let comp = ''
  let type = ''
  if(segment[4]){ 
    comp = segment[4].split(':') 
  }
  if(segment[5]){ 
    type = segment[5].split(':') 
  }
  let p = segment[4] && segment[4].split(':');

  /** Begin helper function */
  let docRef = {};
  const handleSaveData = (e, res, props, inputType, rating) => {
    let db = firebase.firestore();
    docRef = db.doc(`campbell_compass/resource_library_${p && p[1]}_${p && p[2]}`); // Create reference to db location where compass data is saved
    // Test if doc exists. If not the setDocExists(false) to be used later to force a window page refresh so the first resourced entered renders on the page.
    docRef.get() // merge is used to prevent overwriting data
    .then(function() {
      console.log("Document exists!");
      setDocExists(true)
    })
    .catch(function(error) {
      console.error(
        "Error writing document to Firestore: ", error
      );
      setDocExists(false)
    });

    // console.log('type=', type)
    // console.log('refInputFieldManual.current=', refInputFieldManual.current.children[3].value)
    if(inputType === 'auto'){
      opengraph.getSiteInfo(res.value , function(error, result){
        if (error !== null) {
          console.log("Caught error: " + String(error));
          console.log('error=', error)
          return;
        }
        console.log('result=== ', result);
        if(result && result.hybridGraph.url){
          console.log('Site opengraph data is', result);
          let theTitle = result.hybridGraph.title;
          if(result.hybridGraph.site_name === 'Amazon'){
            theTitle = result.hybridGraph.products && result.hybridGraph.products[0].name;
          }
          console.log('result.hybridGraph.url=', result.hybridGraph.url)
          let standardFields = {};
            standardFields = {
              type: result.hybridGraph.type ? result.hybridGraph.type : '',
              site_name: result.hybridGraph.site_name ? result.hybridGraph.site_name : '',
              title: theTitle ? theTitle : 'This resource has no title data but you can access the resource by clicking this statement.',
              image: result.hybridGraph.image ? result.hybridGraph.image : '' ,
              imageHeight: result.hybridGraph.imageHeight ? result.hybridGraph.imageHeight : '',
              imageWidth: result.hybridGraph.imageWidth ? result.hybridGraph.imageWidth : '',
              url: result.hybridGraph.url ? result.hybridGraph.url : '',
              description: result.hybridGraph.description ? result.hybridGraph.description : '',
              rating: rating
            }
          console.log('standardFields=', standardFields)
          if(result.hybridGraph.url){
            docRef.set({
              [type[1]]: firebase.firestore.FieldValue.arrayUnion(standardFields) // type[1] // The resource type (books, videos, weblinks or podcasts)
            }, { merge: true }) // merge is used to prevent overwriting data
            .then(function() {
              if(!docExists){
                // window.location.reload(true);
              }
              console.log("Document successfully merged!");
            })
            .catch(function(error) {
              console.error(
                "Error writing document to Firestore: ", error
              );
            });
          }
          props.setModalState(false);
        }
      });
      refInputFieldAuto.current.value = '';
    }else if(inputType === 'manual'){
      let standardFields = {
        type: resourceType,
        title: refInputFieldManual.current.children[0].value,
        image:  refInputFieldManual.current.children[3].value,
        url:  refInputFieldManual.current.children[1].value,
        description:  refInputFieldManual.current.children[2].value,
        rating: rating
      }
      // console.log('standardFields=', standardFields)
      docRef.set({
        [type[1]]: firebase.firestore.FieldValue.arrayUnion(standardFields) // type[1] // The resource type (books, videos, or weblinks)
      }, { merge: true }) // merge is used to prevent overwriting data
      .then(function() {
        if(!docExists){
          // window.location.reload(true);
        }
        console.log("Document successfully merged!");
      })
      .catch(function(error) {
        console.error(
          "Error writing document to Firestore: ", error
        );
      });

    }
    refInputFieldManual.current.children[0].value = '';
    refInputFieldManual.current.children[1].value = '';
    refInputFieldManual.current.children[2].value = '';
    refInputFieldManual.current.children[3].value = '';
  }
  /** End of handleSaveData helper function */



  segment = usePathSegments();
  comp = ''
  type = ''
  if(segment[4]){ 
    comp = segment[4].split(':') 
  }
  if(segment[5]){ 
    type = segment[5].split(':') 
  }
  p = segment[4] && segment[4].split(':');
  
  // Build Resource Library structure here
  let cDef = compassDefinition;
  let competency = comp[1];
  let compassPoint = comp[2];

  // This table converts competency number to heading and subheading values.
  let lookupTable = [
    {heading: 0, sHeading: 0},
    {heading: 0, sHeading: 1},
    {heading: 0, sHeading: 2},
    {heading: 1, sHeading: 0},
    {heading: 1, sHeading: 1},
    {heading: 1, sHeading: 2},
    {heading: 2, sHeading: 0},
    {heading: 2, sHeading: 1},
    {heading: 2, sHeading: 2},
  ]
  let compassPoints = [];
  if(lookupTable[competency]){
    compassPoints = cDef[
      lookupTable[competency].heading].subHeading[
      lookupTable[competency].sHeading
    ];
  }
  let theCompetency = '';
  let theCompassPoint = '';
  if(cDef[lookupTable[competency] && lookupTable[competency].heading]){
    theCompetency = cDef[lookupTable[competency] && lookupTable[competency].heading].subHeading[lookupTable[competency] && lookupTable[competency].sHeading].name;
    if(compassPoints && compassPoints.compassPoint[comp[2]]){
      // theCompassPoint = compassPoints.compassPoint[comp[2]].compassPoint;
      theCompassPoint = compassPoints.compassPoint[comp[2]].compassPoint.match(/\*\*.+\*\*/)[0];
      theCompassPoint = theCompassPoint.replace(/\*\*/g, "");  // Strip away asterisks
    }
  }

  let resourceList = props.resourceList;
  let resourceType = 0;

  let [tabSlide, setTabSlide] = useState(0);

  useEffect(() => {
    if(type[1] === 'books'){
      setTabSlide(1);
    }else{
      setTabSlide(0);
    }
  },[type[1]]);

  let tabBar = useSpring({config: {tension: 300},
    height: '100%', width: '50%',
    backgroundColor: '#F8C115',
    transform: `translateX(${ tabSlide * (props.wrapper && props.wrapper.width / 2)}px)`
  })

  let contentSlide = useSpring(
    { config: {tension: 400},
      width: props.wrapper.width * 2,
      overflow: 'hidden',
      transform: `translateX(${ -tabSlide * (props.wrapper && props.wrapper.width)}px)`,
      display: 'flex',
      // display: props.pt.animate ? 'flex' : 'none'
    }
  )

  switch(tabSlide){
    case 0: resourceType = 'books'; break;
    case 1: resourceType = 'videos'; break;
    case 2: resourceType = 'weblinks'; break;
    case 3: resourceType = 'podcasts'; break;
  }


  switch(type[1]){ 
    case 'books': 
      resourceList =  props.data && props.data.books;
    break;
    case 'videos': 
      resourceList = props.data && props.data.videos;
    break;
    case 'weblinks': 
      resourceList = props.data && props.data.weblinks;
    break;
    case 'podcasts': 
    resourceList = props.data && props.data.podcasts;
  break;
  }

// console.log('resourceLList ', resourceList)

  let resourceArray = [];
  if(resourceList){
    for(let i = 0; i < resourceList.length; ++i){
      resourceArray.push({index: i, data: resourceList[i]})
    }
    
  }


  const theList = resourceArray.map((r)=>
    <div key={r.index}>
      <OneResource type={type[1]} title={r.data.title}
        description={r.data.description} image={r.data.image} url={r.data.url} index={r.index} 
        competency={p[1]} compassPoint={p[2]} rating={r.data.rating}
        setSelectedResource={props.setSelectedResource}
        setSelectedIndex={props.setSelectedIndex}
        setEditModal={props.setEditModal}
        resourceType={resourceType}
      />
    </div>  
  );

 
  let refInputFieldAuto = useRef(null);
  let refInputFieldManual = useRef(null);
  let [rating, setRating] = useState(0);

  let [showList, setShowList] = useState(false);
  useEffect(()=>{
    setTimeout(()=> setShowList(true), 1000 )
  },[])



  let db = firebase.firestore();

  /**  TEST if current user should see this page */
  let [userPermitted, setUserPermitted] = useState(false);
  docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    for(let i = 0; i < data.editLibrary.length; ++i){
      if(data.editLibrary[i] === userName){
        tempResult = true;   
      }
    }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */


  return(
    <Fragment>
      {userPermitted ?
        <div>
          <div style={{
            display: 'flex', fontWeight: 600, cursor: 'pointer',
          }}>
          <div style=
            {{
              width: '50%', textAlign: 'center', padding: '14px 0',
              color: tabSlide === 0 ? '#000000' : '#888888',
              backgroundColor: '#e6e6e6',
            }} 
            onClick={(e)=> type[1]==='books'?  setTabSlide(1) : setTabSlide(0)}
            >
              Auto entry
            </div>
            <div style=
              {{
                width: '50%', textAlign: 'center', padding: '14px 0',
                color: tabSlide === 1 ? '#000000' : '#888888',
                backgroundColor: '#e6e6e6',
              }} 
              onClick={(e)=> setTabSlide(1)}
              >
                Manual entry
              </div>
            </div>
            <div style={{width: '100%', height: 7, marginTop: -7}}>
              <animated.div style={tabBar}></animated.div>
            </div>
            <animated.div style={contentSlide}>
              <div style={{display: 'flex', flexDirection: 'column', padding: '20px 30px', width: props.wrapper.width}}>
              <div style={{display: `${tabSlide === 0 ? 'block' : 'none'}`}}>
                <input ref={refInputFieldAuto} className="Plan-resource-list-field" placeholder={`Enter ${props.resourceName} URL` } />
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex'}}>
                    <StarsButtons rating={rating} setRating={setRating} />
                    <div  onClick={(e)=>{setRating(0)}} style={{padding: '3px 8px', cursor: 'pointer'}}>
                      <DeleteIcon size={25} />
                    </div>
                  </div>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button onClick={(e)=>{handleSaveData(e, refInputFieldAuto.current, props, 'auto', rating); setRating(0)}} style={{alignSelf: 'flex-end' , margin: '0 auto', fontSize: '110%', padding: '8px 8px'}}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px 30px', width: props.wrapper.width}}>
              <div ref={refInputFieldManual} style={{display: `${tabSlide === 1 ? 'block' : 'none'}`}}>
                <input className="Plan-resource-list-field" placeholder={`*Title` } />
                <input className="Plan-resource-list-field" placeholder={`*Resource URL` } />
                <input className="Plan-resource-list-field" placeholder={`Description (optional)` } />
                <input className="Plan-resource-list-field" placeholder={`Image URL (optional)` } />
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex'}}>
                  <StarsButtons rating={rating} setRating={setRating} />
                  <div  onClick={(e)=>{setRating(0)}} style={{padding: '3px 8px', cursor: 'pointer'}}>
                    <DeleteIcon size={25} />
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <button onClick={(e)=>handleSaveData(e, refInputFieldAuto.current, props, 'manual', rating)} style={{alignSelf: 'flex-end' , margin: '0 auto', fontSize: '110%', padding: '8px 8px'}}>
                    Add
                  </button>
                </div>
              </div>
              </div>
            </div>
          </animated.div>
          <div style={{
            boxShadow: 'inset 0 3px 3px rgba(0,0,0,0.15)', 
            height: props.screenSize.height,
          }}>
            { showList ? 
              theList
            : 
              ''
            }
          </div>
        </div>
        :
        <div style={{textAlign: 'center', marginTop: 50, fontWeight: 500, color: 'red'}}>
          <div>YOU DON'T HAVE ACCESS TO THIS PAGE</div>
        </div>
      }
    </Fragment>
  )
}


export const EditModal = (props)=>{
  // console.log('props=', props)
  let modalOverlay = useSpring({
    marginTop: 0,
    position: 'fixed',
    top: 0, right: 0, bottom: 0, left: 0,
    width: '100%', // props.screenSize.width,
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: props.show ? 1 : 0,
  })

  let modalPopup = useSpring({config: {tension: 250},
    backgroundColor: '#ffffff',
    maxWidth: props.wrapper && props.wrapper.width * 0.95,
    minWidth: 200,
    minHeight: 50,
    padding: 20,
    borderRadius: 10,
    transform: `scale(${props.show ? 1 : 0.4},${props.show ? 1 : 0.4})`,
    fontWeight: 500,
    boxShadow: '0 5px 10px rgba(0,0,0,0.2)',
    overflowX: 'hidden', overflowY: 'auto'
  })
  return(
    <Fragment>
      {props.show ?
        <animated.div style={modalOverlay}>
          <animated.div style={modalPopup}>
            {props.children}
          </animated.div>
        </animated.div>
      : '' 
      }
      </Fragment>
  )
}