
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
// import Autosuggest from 'react-autosuggest';
import Autocomplete from 'react-autocomplete';
import { ExIcon, InfoIcon } from './svg/svg_icons';
import firebase from 'firebase';
import '../firebase-init.js'
import './components.css';
import { usePathSegments } from '../vinciToolbox';

var arraySort = require('array-sort');

export const NavButton = (props) => {
  let segment = usePathSegments([]);
  let size = props.size;
  let buttonStyle = {
    display: 'flex', flexDirection: 'column', alignItems: 'center'
  }
  const myBackFunction = (e) => {
    e.preventDefault();
    window.history.back();
  }
  return(
    <div style={buttonStyle} name={props.name}>
      <div className="LCButton" 
        // onClick={(e)=>props.rt.nav(segment[1]==='/' ? props.url : myBackFunction(e))} 
        style=
          {{
            marginTop: 0, width: size, height: size, 
            borderRadius: props.radius ? props.radius : size
          }}
        >
        {props.children}
      </div>
    </div>
  );
}

export const CCButton = (props) => {
  const refButton = useRef(null);

  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',  cursor: 'pointer'}} name={props.name}>
    <button className="CCButton" ref={refButton} ><div style={{textAlign: 'center', width: '100%', padding: '0 10px'}}>{props.children}</div></button>
  </div>

  );
}

// export const CCButton = (props) => {
//   const refButton = useRef(null);
//   return(
//     <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} name={props.name}>
//     <button className="CCButton" ref={refButton}  style={{backgroundImage: 'none'}}></button>
//     <div style={{marginTop: -37, zIndex: 0, cursor: 'pointer'}}>{props.children}</div>
//     <div style={{marginTop: 35, color: '#999999'}}>{props.label}</div>
//   </div>

//   );
// }

export const CCButtonAlt = (props) => {
  const refButton = useRef(null);
  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} name={props.name}>
    <button className="CCButton" ref={refButton} ></button>
    <div style={{marginTop: -37, zIndex: 0, cursor: 'pointer'}}>{props.children}</div>
    <div style={{marginTop: 35, color: '#999999'}}>{props.label}</div>
  </div>

  );
}

export const Modal = (props)=>{
  // console.log('props=', props)
  let modalOverlay = useSpring({
    marginTop: '-15%',
    position: 'fixed',
    top: 0, right: 0, bottom: 0, left: 0,
    width: '100%', // props.screenSize.width,
    backdropFilter: 'blur(30px)',
    backgroundColor: 'rgba(0,0,0,0.05)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: props.show ? 1 : 0,
  })

  let modalPopup = useSpring({config: {tension: 250},
    backgroundColor: '#ffffff',
    maxWidth: props.wrapper && props.wrapper.width * 0.8,
    minWidth: 200,
    minHeight: 50,
    padding: 20,
    borderRadius: 10,
    transform: `scale(${props.show ? 1 : 0.4},${props.show ? 1 : 0.4})`,
    fontWeight: 600
  })
  return(
    <Fragment>
      {props.show ?
        <animated.div style={modalOverlay}>
          <animated.div style={modalPopup}>
            {props.children}
          </animated.div>
        </animated.div>
      : '' 
      }
      </Fragment>
  )
}


export const Spinner = (props) => {
  return(
    <div style={{
      backgroundColor: 'rgba(255,255,255,0.7)', 
      position: 'absolute', top:0, right:0, bottom:0, left:0,
      justifyContent: 'center',
      alignItems: 'center',
      display: props.spinner ? 'flex' : 'none'
    }}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <div className="spinner"></div>
        <div style={{fontSize: '120%', marginTop: 40}}>{props.message}</div>
      </div>
    </div>
  )
}


export const SpinnerSignin = (props) => {
  return(
  <Fragment>
    <div style={{
      backgroundColor: 'rgba(255,255,255,0.7)', 
      position: 'absolute', top:0, right:0, bottom:0, left:0,
      justifyContent: 'center',
      alignItems: 'center',
      display: props.show ? 'flex' : 'none'
    }}></div>
      <div style={{display:  props.show ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center'}}>
        <div className="spinner"></div>
      <div style={{fontSize: '120%', marginTop: 40}}>{props.message}</div>
    </div>
  </Fragment>
  )
}

// Autosuggest starts here


export const AutocompleteUsers = (props) => {
  const [value, setValue] = useState('');
  // console.log('AutocompleteUsers - props=', props)
  
  useEffect(() => {
    if(props.clear){
      setValue('')
      props.setClear(false);
    }
    //console.log('AutocompleteUsers - value =', value)
  },[props]);

  let db = firebase.firestore();

  let users = null;
  let userKeys = [];
  let userValues = [];
  let lowerCasedCompanies = null;
  
  let formattedUsers = [];   
  let [userNames, setUserNames] = useState([]);

  useEffect(() => {
    let docRef = db.doc('campbell_compass/users');
    docRef.get()
    .then((doc) => {
      if(doc && doc.exists){
        users = doc.data().users;


        // users = arraySort(users, 'displayName', {reverse: false});
        // console.log('users - in components.js ', users)
        userKeys = Object.keys(users);
        userValues = Object.values(users);
        for(let i = 0; i < userKeys.length; ++i){
          formattedUsers.push( { 
            id: i + 1, 
            label: userValues[i].displayName, 
            email: userValues[i].email, 
            uid: userValues[i].uid } )
        }
        // Sort users by their displayName
        arraySort(formattedUsers, 'label');
        // console.log('SORTED USER LIST: ', formattedUsers);    
        lowerCasedCompanies = formattedUsers.map(user => {
          return {
            id: user.id,
            label: user.name && user.name.toLowerCase(),
            withCaps: user.name + ' : ' + user.email,
          };
        });
        setUserNames(formattedUsers);
      }
    }).catch((error) => {
      console.log('Error in retrieving users: ', error);
    })
  },[]);

  const handleInputChange = (e) => {
    setValue( e.target.value); 
    // props.setValue( e.target.value); 
    if(userNames){
      let temp = false
      for(let i = 0; i < userNames.length; ++i){
        if(userNames[i].label === e.target.value){ 
          temp = true;
          props.setNameFound(true);
          console.log('NAME FOUND!');
          // return true;
        }else{
          // return false;
        }
      }
      if(temp){
        props.setNameFound(true);
      }else{
        props.setNameFound(false);
      }
    }

  }

  const handleSelect = (val) => {
    setValue(val); 
    props.setValue(val);
    console.log('val ', val)
    if(userNames){
      // let temp = false
      props.setNameFound(false);
      for(let i = 0; i < userNames.length; ++i){
        if(userNames[i].label === val){ 
          props.setNameFound(true);
          console.log('NAME FOUND!');
          // return true;
        }else{
          // return false;
        }
      }
    }
  }

  let [showInfo, setShowInfo] = useState(false);

  let infoTransition = useSpring({
    opacity: showInfo ? 1 : 0,
    position: 'absolute', top: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', 
    height: '100%', width: '100%', maxWidth: 500,
    display: showInfo ? 'flex' : 'none', justifyContent: 'center', backgroundColor: 'rgba(255,255,255, 1)', margin: '7px 0 3px 0' 
  })

  return (
    <div style={{position: 'relative', width: '100%', height: '100%',}}>
      <div style={{postion: 'absolute', top: 0,}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
          <div style={{ display: 'flex'}}>
            <div style={{cursor: 'pointer', padding: '10px 15px 0 0'}} 
              onClick={()=>{props.setValue(''); setShowInfo(true) } }
            >
              <InfoIcon color="#F5B01B" scale={1.2}/>
            </div>
            <Autocomplete
              items={userNames}
              shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
              getItemValue={item => item.label}

              renderItem={(item, highlighted) =>
                <div
                  key={item.id}
                  style={{ backgroundColor: highlighted ? '#fff2e2' : 'transparent', padding: '5px 15px', marginBottom: 0,
                }}
                >
                  {item.label}
                </div>
              }
              menuStyle={{backgroundColor: 'white', fontSize: '100%', fontWeight: 400, 
                overflowY: 'auto', maxHeight: 120, // height: 160,
                padding: 0,
                width: '100%',
                border: '1px solid #e6e6e6',
                boxShadow: '0px 0px 3px rgba(0,0,0,0.3)'
              }}

              inputProps={{ placeholder: "Enter name", 
              style: {
                fontSize: '110%',
                padding: '8px 15px',
                marginRight: -20,
                border: '2px solid #bbb',
                borderRadius: 5,
                width: '100%',
                maxWidth: '100%',
              }}}

              value={value}
              onChange={(e) => handleInputChange(e) }
              onSelect={(val) => handleSelect(val) }
            />
            <div style={{cursor: 'pointer', padding: '6px 10px 0 8px'}} 
              onClick={()=>{setValue(''); props.setValue('') }  } >
              <ExIcon size={28} color="#999" />
            </div>
          </div>
          
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 15, fontSize: '120%', width: '100%'}}>
          
          <div style={{ color: '#999999', cursor: 'pointer'}} 
            onClick={()=> { props.setClear(true); props.setShowModal(false); setShowInfo(false); setValue(''); props.setValue('') } }>
            Cancel
          </div>
          <div style={{
            color: `${props.nameFound ? '#F5B01B' : '#bbb' }`,
            cursor: `${props.nameFound ? 'pointer' : 'default' }`,
          }} 
            onClick={()=> props.nameFound ? props.handleContinue() : null   }>
            Continue  
          </div>
        </div>

        <animated.div style={infoTransition} 
        >
          <div style={{margin: '0 0px 0px -5px', padding: 5, textAlign: 'center', fontSize: `90%`, lineHeight: 1.6, fontWeight: 500, color: '#555', backgroundColor: 'white', 
            //border: '1px solid red'
          }}>
            A name is not accepted if that person has never signed into this app. When they do sign in their name will be added to the user list.
          </div>
          <div style={{padding: '5px 0 0 0', fontSize: '110%', fontWeight: 500, color: "#F5B01B", cursor: 'pointer'}}
            onClick={()=> setShowInfo(false)}
          >
            Close
          </div>
        </animated.div>
      </div>
    </div>
  );
};