/* LATEST HOME PAGE CODE */

import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Phase } from './Phase/Phase.js';
import { Assess } from './Phase/AssessPages/Assess';
import { Plan } from './Phase/PlanPages/Plan';
import { Execute } from './Phase/ExecutePages/Execute';
import ExecutionNotes from './Phase/ExecutePages/ExecutionNotes';
import { ChallengingAssignmentNotes } from './Phase/ExecutePages/ChallengingAssignmentNotes'
import { Admin } from './Admin/Admin';
import { useVinciRouter, useWrapper, useScreenResizer, usePathSegments } from '../vinciToolbox';
import { ResourceCollection } from './Phase/PlanPages/BuildPlan/ResourceCollection/ResourceCollection';
import { AddResources } from './Phase/PlanPages/BuildPlan/ResourceCollection/AddResources/AddResources';
import { LiveLearning } from './Phase/PlanPages/BuildPlan/LiveLearning/LiveLearning';
import { ChallengingAssignment } from './Phase/PlanPages/BuildPlan/ChallengingAssignment';
import { DevRelationships } from './Phase/PlanPages/BuildPlan/DevRelationships';
// import { SelfAssessment } from './Phase/AssessPages/SelfAssessment';
import { DoAssessment } from './Phase/AssessPages/DoAssessment/DoAssessment';
import { ViewAssessments } from './Phase/AssessPages/ViewAssessments';
import { Authorizations } from './Phase/AssessPages/Authorizations';
import { UserList } from './Users/UserList'
import { Modal } from './Phase/AssessPages/Modal';
import firebase from 'firebase';
import './Home.css';
import './Buttons.css';
import { useSpring, animated } from 'react-spring';
import NewHomeCompass from './NewHomeCompass';
import { CompassDisk, CompassPointer } from './svg/svg_compass.js';
import { ExIcon, ThreeDotsHorizontal, GoldDragon } from './svg/svg_icons.js';
import { LogoAndWordmarkInline } from './svg/svg_LogoAndWordmarkInline';

import { UserAgentApplication } from 'msal';

import { getPhotoUrlFromEmail } from './helper_functions';
import { getPagePermissions } from './Admin/PageRoles/PageRoles.js';

// import newCompassImage from 'https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2Fnew-compass.png?alt=media&token=b4c5548b-e9c9-47d8-a9b6-79973f6dad25'

// function signOut() {
//   firebase.auth().signOut()
//     .then(function(){
//       console.log('you are signed out!');
//     }
//   );
// }

const Home=(props)=> {

  let headerHeight = 85;
  let screen = useScreenResizer(); // Sets up screen resize listener and returns width and height of screen.
  let segment = usePathSegments([]); // Breaks up the url into separate segments.
  let wrapper = useWrapper(600, screen.size.height, screen.size); // Maintains main page wrapper and sets its max width and  max height.
  let rt = useVinciRouter(); // Sets up the router and url listener for the entire app.

  let vinci = {
    rt: rt,
    screen: screen,
    wrapper: wrapper,
  }
  let refCompassRing = useRef(null);

  let headingPositionStyle = useSpring(
    {
      transform: `translateY(${segment[1]=== '/' ? screen.size.height-headerHeight-25: 0}px)`
    }
  );
  let w = 0;
  useEffect(()=>{
    w = refCompassRing.current && refCompassRing.current.offsetWidth;
  })
  let theDefaultWidth = 360; // Default Modal width
  let size = vinci.wrapper ? vinci.wrapper.width * 0.90 : theDefaultWidth;
  
  if(size > 425) { 
    size = 425;
  }

  let wrapperBorder = true;

  if(wrapper.width === screen.size.width){
    wrapperBorder = false
  }else{
    wrapperBorder = true
  }
  
  let backgroundTransition = useSpring({
    overflow: 'hidden',
    config: { tension: 270, friction: 25, mass: 1 },
    position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, 
    pointerEvents: segment[1]==='/' ? 'none' : 'all',
    opacity: segment[1]==='/' ? 0 : 1,
    backgroundColor: '#e6e6e6',
    maxWidth: wrapper.width,
    margin: '0 auto',
  })

  let [angle, setAngle] = useState(0); // The angle of the compass pointer  
  let [showHomeMenu, setShowHomeMenu] = useState(false);
  let [selectedUser, setSelectedUser] = useState('');
  let [photoUrl, setPhotoUrl] = useState('')

  // Retrieve photo download URL using email
  const getPhotoUrl = (email) => {
    var storageRef = firebase.storage().ref();
    storageRef.child(`user_photos/${email}.jpg`).getDownloadURL()
    .then((url) => {
      setPhotoUrl(url)
    })
    .catch((error) => {
      // Handle any errors
    });
  }
  let theEmail = firebase.auth().currentUser && firebase.auth().currentUser.email;
  useEffect(()=> {
    getPhotoUrl( theEmail )
  },[theEmail]);

  let [showModal, setShowModal] = useState(false);
  let [modalContent, setModalContent] = useState('blank')
  let adjustedWidth = wrapper.width - (wrapper.width * 0.17)

  // console.log('wrapper=', wrapper);

  let borderStyle = {
    left: (screen.size.width/2)-wrapper.width/2, width: wrapper.width,
    overflow: 'hidden', 
    backgroundColor: '#e6e6e6',
    boxSizing: 'border-box', 
    border: wrapperBorder ? '1px solid rgba(0,0,0,.18)' : 'none',
    boxShadow: '0 0 5px rgba(0,0,0,0.3)'
  }

  return (
    <Fragment>
    {firebase.auth().currentUser ?
      <div style={{
        // boxSizing: 'border-box', border: '4px solid magenta'
      }}>
      
        <div className="Home-background noSelect">
        </div>
        <div className="Home-main-wrapper noSelect" style={borderStyle}>
          <div style={{height: '100vh', width: wrapper.width,
            // boxSizing: 'border-box', border: '4px solid lightgreen'
          }}>
            <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: wrapper.width, height: 'auto', 
              background: '#f8f8f8', 
              // border: '1px solid blue'
            }}>
              <div style={{margin: 20, width: '100%', maxWidth: 165}}>
                <LogoAndWordmarkInline />
              </div>
              {/* Home page dropdown menu*/}
              <div style={{ display: segment[1]=== '/' ? 'block' : 'none', userSelect: 'none',
                height: 40, width: 40, position: 'absolute', top: 20, right: 15,
              }}>
                <ThreeDotsHorizontal size={25} color="#888" onClick={()=> setShowHomeMenu(true)} />
              </div>

              <div style={{backgroundColor: 'transparent',
                display: showHomeMenu ? 'block' : 'none' }} onClick={()=> setShowHomeMenu(false)} >
              </div>
              <div style={{zIndex: 1, position: 'absolute', top: 25, right: 30}}>
                <HomeMenu show={showHomeMenu} setShowHomeMenu={setShowHomeMenu} rt={rt}/>
              </div>
            </div>

            <div style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around'}}>
              <div style={{textAlign: 'center', fontSize: '150%', fontWeight: 600, color: '#444', paddingTop: 0}}>
                <i>Learn, lead, succeed!</i>
              </div>
              <div style={{marginTop: -15}} >
                <NewHomeCompass  photoUrl={photoUrl} wrapper={wrapper}/>
              </div>
              <div style={{padding: '0 40px 230px 40px', textAlign: 'center', textDecoration: 'italic',
                // border: '1px solid blue'
              }}>
                <QuoteOfTheDay segment={segment} />
              </div>
            </div>
        </div>

        <div style={borderStyle, {zIndex: 1}  
        }>
          <Admin rt={rt} wrapper={wrapper} screenSize={screen.size} headerHeight={headerHeight} user={props.user}  />
          <UserList rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <DevRelationships rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <LiveLearning rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <ChallengingAssignment rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <ChallengingAssignmentNotes rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />

          {/*<SelfAssessment t={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />*/}
          <DoAssessment rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} 
            selectedUser={selectedUser} setSelectedUser={setSelectedUser} 
          />
          <Authorizations rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <ViewAssessments rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />

          <ResourceCollection rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
          <AddResources rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
        </div>

          
        </div>

        <div style={borderStyle, {zIndex: 1} }>
         
          <div className="noSelect" style={wrapper.style}> 
            <div className="Home-background noSelect"></div>
              <animated.div style={backgroundTransition }></animated.div>

              <Assess rt={rt} wrapper={wrapper} screenSize={screen.size} headerHeight={headerHeight} user={props.user} 
              selectedUser={selectedUser} setSelectedUser={setSelectedUser}/>
              <Plan rt={rt} wrapper={wrapper} screenSize={screen.size} headerHeight={headerHeight} user={props.user}  />
              <Execute rt={rt} wrapper={wrapper} screenSize={screen.size} headerHeight={headerHeight} user={props.user} 
                showModal={showModal} setShowModal={setShowModal} setModalContent={setModalContent}/>
              
              {/* The following displays the three main nav buttons and performs the button transition from bottom to top */}
              <animated.div style={headingPositionStyle}>
                <Phase size={65} label="Assess" url='/assess' rt={rt} wrapper={wrapper} headerHeight={headerHeight} screenSize={screen.size} user={props.user}  />
                <Phase size={65} label="Plan" url='/plan' rt={rt} wrapper={wrapper} headerHeight={headerHeight} screenSize={screen.size} user={props.user} />
                <Phase size={65} label="Execute" url='/execute' rt={rt} wrapper={wrapper}  headerHeight={headerHeight} screenSize={screen.size} user={props.user} />
                
                <Phase size={65} label="" url='/admin' rt={rt} wrapper={wrapper} headerHeight={headerHeight} screenSize={screen.size} user={props.user} />
              </animated.div>

              <ExecutionNotes rt={rt} wrapper={wrapper} screenSize={screen.size} user={props.user} />
            
            </div>


          </div>
          <Modal show={showModal} setShowModal={setShowModal}>
            {modalContent}
          </Modal>
        </div>
      : ''
}
    </Fragment>
  );
}

// const HomeCompass = (props) => {

//   return(
//     <Fragment>
//       <div style={{display: 'flex', flexDirection: 'column',
//         justifyContent: 'center', alignItems: 'center',
//         position: 'relative', 
//         // zIndex: 1, 
//         // width: props.wrapper.width, height: props.wrapper.width - 100,
        
//         margin: '0 auto',
//         // border: '2px solid red',  
//       }}>
//         <img src='https://firebasestorage.googleapis.com/v0/b/csapp-suite.appspot.com/o/Layout%20assets%2Fnew-compass.png?alt=media&token=a1fa6527-8d4b-43e9-9a67-be426bad9c75' 
//         style={{width: props.adjustedWidth, height: 'auto', position: 'absolute', margin: 'auto auto',}}
//         />
//         <img src={props.photoUrl} 
//           //height={mugShot.size} width={mugShot.size} 
//           style={{opacity: 1, position: 'absolute', width: '52%', height: 'auto', margin: 'auto auto', borderRadius: '50%'}} 
//         />
//       </div>
//     </Fragment>
//   )
// }

const HomeMenu = (props) => {
  let menuTransition = useSpring({
    config: {tension: 350},
    backgroundColor: '#ffffff',
    minWidth: 150, 
    minHheight: 140,
    whiteSpace: 'nowrap',
    borderRadius: 10,
    position: 'absolute', top: 0, right: 0,
    boxShadow: '0 0 10px rgba(0,0,0,0.3)',
    transform: `${props.show ? "scale(1,1)" : "scale(0,0)"}`,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '30px 25px 10px 25px',
    display: props.show ? 'flex' : 'none',
  })

  const mySignOut = () => {
    let TENANT_ID = '75258168-0fa1-459e-94a6-968aabb9186a';
    const config = {
      auth: {
          clientId: TENANT_ID,
          // redirectUri: "localhost:3000/login", //defaults to application start page
          // postLogoutRedirectUri: "localhost:3000/logged_out"
      }
    }
    const myMsal = new UserAgentApplication(config);  
    myMsal.logout();
    firebase.auth().signOut();
  }

  let cUser = firebase.auth().currentUser.displayName;

  const handleCloseMenu = () => {
    props.setShowHomeMenu(false)
  }

  /**  TEST if current user should see the Admin pages item in the Home dropdownmenu */
  let [userPermitted, setUserPermitted] = useState(false);
  let db = firebase.firestore();
  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    let userName = firebase.auth().currentUser.displayName;
    for(let i = 0; i < data.editLibrary.length; ++i){
      if(data.editLibrary[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editLiveLearning.length; ++i){
      if(data.editLiveLearning[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editPermissions.length; ++i){
      if(data.editPermissions[i] === userName){
        tempResult = true;   
      }
    }
    for(let i = 0; i < data.editQuotes.length; ++i){
      if(data.editQuotes[i] === userName){
        tempResult = true;   
      }
    }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
    /** END OF TEST if current user should see the Admin pages item in the Home dropdownmenu */


  return(
    <animated.div style={menuTransition} className="Home-admin-menu">
      {/* cUser === 'Jeff Ricks' || cUser === 'Scott Erickson' ? */}
      <div style={{position: 'absolute', top: 10, right: 20, maxWidth: 350, cursor: 'pointer', opacity: 0.5}} onClick={()=> handleCloseMenu()}  ><ExIcon size={25} /></div>
      { userPermitted ? 
      <div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p onClick={()=> { props.rt.nav('/admin'); props.setShowHomeMenu(false) } }>
              Admin Pages
            </p>
          </div>
        </div>
      : '' 
      }
      <p onClick={()=> { props.rt.nav('/user_list'); props.setShowHomeMenu(false) } }>
        User list
      </p>
      <p onClick={()=> window.history.go(0) }>Refresh app</p>
      <p onClick={()=> mySignOut() }>Sign out</p>
    </animated.div>
  )
}

const QuoteOfTheDay = (props) => {
  let [quoteOfTheDay, setQuoteOfTheDay] = useState('');
  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`admin_tools`);
    docRef.get().then(function(doc) {
      let rand = Math.round( Math.random() * ( doc.data().quoteOfTheDay.length-1) )
      setQuoteOfTheDay(doc.data().quoteOfTheDay[rand])
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
  },[props.segment ])

  return(
    <i style={{fontWeight: 500}}>{quoteOfTheDay}</i>
  )
}

export default Home;

