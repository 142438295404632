export let compassDefinition = [
  {
    heading: 'Personal Leadership',
    subHeading: [
      {
        name: 'Establishing Foundation',
        compassPoint: [
          { compassPoint: "**A. Values:** Exemplifies the Campbell Scientific common cultural values – Accountability, Respect, Client-Centric, Teamwork, Innovation, and Continuous Improvement. At peace in such a way that any conflicts between person values and company values and culture are reconciled.", barValue: 0 },
          { compassPoint: "**B. Integrity:** Exhibits personal integrity and strong character in all situations; truthful and courageous; leads by example; credible; authentic; reliable; builds trust and invites trust", barValue: 0 },
          { compassPoint: "**C.	Life Balance:** Maintains personal balance (family, career, self and community within a spiritual core); lifelong learner.", barValue: 0 },
          { compassPoint: "**D. Self-Awareness:**  Self-aware of the impact your behavior has on others; teachable; open-minded; humble; knows that leadership is earned, not titled (anyone can be a leader); able to be a follower as well as a leader.", barValue: 0 },
          { compassPoint: "**E.	Positivity:**  Positive; views adversity as challenging opportunities; works enthusiastically; sense of humor.", barValue: 0 },
          { compassPoint: "**F.	Commitment:** Recognizes that learning and growth take place on the edge; willing to take risks that promote personal growth.", barValue: 0 },
          { compassPoint: "**G.	Resilience & Fortitude:** Manages internal and external conditions with balance; resilient to stress and setbacks; able to maintain composure and focus; remains optimistic; exhibits patience; keeps people calm; radiates confidence.  Adapts in the face of multiple changes while persevering toward strategic goals.", barValue: 0 },
          { compassPoint: "**H.	Authenticity:** Able to connect with others in meaningful ways; willing to be vulnerable, real and genuine; others feel safe in your presence. .", barValue: 0 },
        ]
      },
      {
        name: 'Creative & Analytical Thinking',
        compassPoint: [
          { compassPoint: "**A.	Problem-solving Ability:** Recognizes problems and priorities; capable of following a logical sequence to problem solving and conflict resolution; breaks complex issues or problems into manageable components; performs root cause analysis; good practical common sense; able to effectively prioritize.", barValue: 0 },
          { compassPoint: "**B.	Intuition:**  Intuitive; can read the business environment to draw conclusions and course correct as needed with business direction, clients, peers, employees and overall strategy..   ", barValue: 0 },
          { compassPoint: "**C.	Curiosity:**  Curious; open to new experiences; appetite for continual learning; seeks understanding both within and outside of their current role.", barValue: 0 },
          { compassPoint: "**D.	Agile Thinking:** Skilled in application of evidence-based thinking, systems thinking, logical analysis, possibility thinking and gut feel judgment to business situations and opportunities; capable of defining the opportunity, considering and analyzing options, gathering data, assessing risk and, performing cost/benefit analysis when recommending solutions. ", barValue: 0 },
          { compassPoint: "**E.	Innovative Spirit:** Encourage innovative thinking by asking of self and others if alternatives have been explored, and by avoiding unnecessary skepticism.  Challenges established thinking, processes or protocol to maximize success; proactively seeks improvements to current workflow; solicits input from others with different perspectives; relentlessly removes barriers; pursues constant improvement in products, processes, and operations to maximize value to clients for a reasonable cost.", barValue: 0 },
        ]
      },
      {
        name: 'Results Orientation',
        compassPoint: [
          { compassPoint: "**A.	Accountability:**  Holds self and others accountable; allows self to be held accountable by others; dependable; makes realistic commitments.", barValue: 0 },
          { compassPoint: "**B.	Initiative:** Exhibits initiative by moving ahead independently without requiring supervision and constant direction; resourcefulness; productively engaged; willing to play full out and give best effort.", barValue: 0 },
          { compassPoint: "**C.	Client-centric:** Dedication to understanding and fulfilling client needs and expectations.", barValue: 0 },
          { compassPoint: "**D.	Ownership:**  Comfortable taking ownership and accepting responsibility; willing to make a decision; capable of planning, organizing and controlling projects within the area of responsibility.", barValue: 0 },
          { compassPoint: "**E.	Execution:** Able to execute and fulfill commitments; action orientation; balanced sense of urgency; sets meaningful and measurable goals; prioritizes and aligns resources to accomplish key objectives; builds practical action plans; maintains forward momentum; maximizes use of available tools and technologies; ensures work processes are effective and consistently followed.", barValue: 0 },
          { compassPoint: "**F.	Results Driven:** Results and outcome focused; assigns clear accountability and ownership for important business outcomes; begins with the end in mind; consistently delivers what is expected (or better) and does so on-time; engages with clients; seeks feedback and continuously improves; high quality of workmanship and service while controlling costs.", barValue: 0 },
        ]
      },
    ]
  },
  {
    heading: 'Team Leadership',
    subHeading: [
      {
        name: 'Inspiring & Motivating Others',
        compassPoint: [
          { compassPoint: "**A. Influence:** Influences others; provides sense of purpose; sells ideas; leads in a manner that people believe in and follow; inspires and energizes people to overcome obstacles to the vision; motivates others to act.", barValue: 0 },
          { compassPoint: "**B. Communication:**  Effective communicator; presents information professionally and in simple terms that get the message across both verbally and in writing; moves and inspires an audience to action through effective presentation; uses technology effectively when appropriate; cares about connecting; focused listener that seeks to understand; graceful and tactful; uses language to build common understanding.", barValue: 0 },
          { compassPoint: "**C. Engagement:**  Engages people at all levels (up, down, sideways) in ways that connect, support and serve; builds effective relationships; maximizes contribution and satisfaction of others by helping them reach full potential, reconciles conflict without harboring ill will.", barValue: 0 },
          { compassPoint: "**D. Appreciation:** Recognizes contributions of others and gives credit where it is due; comfortable expressing appreciation; acknowledges others’ expertise; demonstrates confidence in people’s capabilities.", barValue: 0 },
          { compassPoint: "**E. Support:**  Ensures people have the tools and resources they need to perform their job and accomplish their objectives.", barValue: 0 },
          { compassPoint: "**F. Energy & Enthusiasm:**  Exhibits passion and enthusiasm about their team’s contribution and business impact; leads with ambitious, energetic and persistent work ethic – energy begets energy; willing to be a day maker.", barValue: 0 },
          { compassPoint: "**G.	Compassion:** Emotionally competent; sensitive to personal problems; able to show compassion when needed; genuine, humble and sincere; understands the three steps of an apology:  1) I am sorry; 2) I won’t do that again; 3) What can I do to make it up?", barValue: 0 },
        ]
      },
      {
        name: 'Leading People for Performance',
        compassPoint: [
          { compassPoint: "**A. Communication:**  Positive, open communication that helps each person understand company objectives and how his/her work is helping accomplish those objectives; provides clear direction and expectations; clarifies and communicates cross-functional and regional inter-dependencies.  Leads by example in communicating with global offices. ", barValue: 0 },
          { compassPoint: "**B. Accountability:**  Holds others accountable to commitments and deadlines.", barValue: 0 },
          { compassPoint: "**C. Effective Delegation:**  Capable of delegating responsibility; trusts and empowers others once they have accepted responsibility and demonstrated competence; provides the right balance of direction or support based on skill and motivation levels associated with the task.", barValue: 0 },
          { compassPoint: "**D. Objectivity:** Able to maintain perspective of situations; able to maintain objectivity and not react too quickly to emotion, while having a sense of urgency when required; poised during emotionally challenging situations; resilient; able to assume positive intent; addresses performance problems with clear action steps; handles corrective action situations timely, directly and tactfully, including termination of employment when necessary.", barValue: 0 },
          { compassPoint: "**E. Coaching & Training Aptitude:**  Comfortable in the role of teaching and training; gains personal satisfaction in seeing others develop and grow; provides performance and career coaching and constructive feedback that fuels improvement and growth; offers effective guidance to subordinates in their career development.", barValue: 0 },
          { compassPoint: "**F. Consistency & Fairness:**  Leads and manages with consistency; avoids favoritism; objective and fair.", barValue: 0 },
          { compassPoint: "**G. Client-focus:**  Client focused both internally and externally, measures value of performance from the perspective of the client.", barValue: 0 },
          { compassPoint: "**H. Empathic Listening:**  Listens with real intent, interest and empathy with all levels of the organization; asks the right questions; approachable; accessible.", barValue: 0 },
        ]
      },
      {
        name: 'Building Winning Teams',
        compassPoint: [
          {  compassPoint: "**A.	Team building:** Surrounds themselves with talented, high performing people; leverages a diverse talent mix; models inclusion; encourages cooperation.", barValue: 0 },
          { compassPoint: "**B.	Vision:**  Promotes a shared vision of desired outcomes with a clear and comprehensive plan.", barValue: 0 },
          { compassPoint: "**C.	Respect:** Treats others with dignity and respect; gains respect by giving it; inspires trust within the team; respectful of others’ perspective.", barValue: 0 },
          { compassPoint: "**D.	Facilitation:**  Ability to facilitate in a group/team situation; asks powerful questions; builds unity and cohesiveness while utilizing and appreciating the diverse talent of the team; welcomes opposing views; leaves room for superstars to excel; collaborative within team and across organization, regardless of geography or cultural differences;  skilled in conducting productive meetings; involves the right people; considerate of others time; follows an outline or agenda with forethought and preparation; follows up on action items to ensure accountability. ", barValue: 0 },
          { compassPoint: "**E.	Conflict Resolution Skill:** Facilitates the constructive resolution of conflicts; able to overcome misunderstanding, miscommunication and professional difference of opinion by focusing on goals (what by when), roles (who does what and who decides what), and procedures (how it gets done).  ", barValue: 0 },
          { compassPoint: "**F.	Drive:** Follows up consistently on progress against team goals to sustain momentum.", barValue: 0 },
          { compassPoint: "**G.	Trust:** Allows others to struggle and do difficult things and celebrates successes.", barValue: 0 },
       ]
      },
    ]
  },
  {
    heading: 'Business Leadership',
    subHeading: [
      {
        name: 'Business & Financial Acumen',
        compassPoint: [
          { compassPoint: "**A.	Policy Understanding:** Possesses a functional working knowledge of business policies, procedures, operational processes, workflows and technologies related to the position.", barValue: 0 },
          { compassPoint: "**B.	Financial Savvy:** Understands key financial measures and how their role contributes; grasps and communicates key performance, quality and profit indicators used by the organization and manages to those measures; able to navigate a financial statement (balance sheet, income statement, budgeting, cash flow); able to evaluate the short- and long-term financial impact of decisions and actions; educates others on the impact their role has on key financial measures; budgeting skills.", barValue: 0 },
          { compassPoint: "**C.	Business Environment Awareness:**  Good business acumen; listens to market, investors, clients, employees and suppliers; knows and understands competitive options.", barValue: 0 },
          { compassPoint: "**D.	Analytical Aptitude:** Analyze data to identify trends, interpret results, and make recommendations for addressing them. ", barValue: 0 },
          { compassPoint: "**E.	Sound Financial Decision Making:** Decision making that drives profitability to provide a sound financial basis for growth and a return for shareholders consistent with long-term sustainability and the overall well-being of the organization.", barValue: 0 },
          { compassPoint: "**F.	Financial Stewardship:**  Independence from financial and legal demands of those who are unfamiliar with our cause.", barValue: 0 },
          { compassPoint: "**G.	Technological Knowledge:**  Up-to-date knowledge of appropriate technology that supports leadership in the markets we serve.", barValue: 0 },
        ]
      },
      {
        name: 'Strategic Planning',
        compassPoint: [
          { compassPoint: "**A.	Vision:** Understands the company vision; grasps the big picture; forward thinking; articulates meaning and purpose for employees.", barValue: 0 },
          { compassPoint: "**B.	Strategic Thinking:** Outlines strategy as a statement of intent emphasizing distinctive position that facilitates coordinated execution while making trade-offs and forging fit among activities; able to ground the vision and drive a shared understanding of the company’s strategic objectives and how to support them; aligns people with strategy; balances the tension between daily tasks and strategic actions that impact the long-term viability of the organization; conducts SWOT analysis to assess where we are now, articulate where we are going, and how to get there; able to identify and define a weakness or obstacle to progress and apply power and resources where it will have the greatest effect.", barValue: 0 },
          { compassPoint: "**C.	Global Unity:** Develops global unity and synergy with all Campbell companies; integrating and collaborating, while allowing autonomy at the local level to meet unique client needs better than anyone in the industry; leverages global footprint to consistently exceed the expectations of our clients worldwide. ", barValue: 0 },
          { compassPoint: "**D.	Strategic Relationships:** Skilled in development of strategic relationships and negotiation of agreements. ", barValue: 0 },
          { compassPoint: "**E.	Quality Focus:** Develop exceptional products to deliver a superior client experience.", barValue: 0 },
          { compassPoint: "**F.	Execution:** Translate vision and strategies into practical action plans; enrolls others by connecting the plans to the team’s daily work; determine priorities for short- and long-term objectives; plan how to accomplish initiatives by identifying sequence of steps, deadlines, and involvement of key individuals; develops metrics, controls, checks and balances to monitor progress of plans.", barValue: 0 },
          { compassPoint: "**G.	Wise Risk Taking:**  Willing to take necessary risks for the organization’s sake.", barValue: 0 },
        ]
      },
      {
        name: 'Leading Change',
        compassPoint: [
          { compassPoint: "**A.	Innovation:**  Innovative; applies science and technology in products and services to benefit humankind; continuous improvement mentality; creative response to change.", barValue: 0 },
          { compassPoint: "**B.	Change Agent:** Adaptable and able to effectively lead others through change initiatives; sees and communicates change as opportunity; decisive; provide clear vision, accurate and timely information about the change; communicates the rationale for and benefits of proposed changes; answer questions directly and with candor; explains position with critics vs. avoidance; inspires action, not excuses; address resistance; hold self and others accountable; communicates regularly throughout the change initiative. ", barValue: 0 },
          { compassPoint: "**C.	Collaboration:** Demonstrates cultural and political awareness; networks well across boundaries; generates support and builds collaborative relationships with people who have emotional and rational commitment to the change to help implement and steer it;  identifies influencers; able to lead from the front, middle or back.", barValue: 0 },
          { compassPoint: "**D.	Open-minded Support:** Ensure the necessary resources are available to implement change; evaluate systems and processes to ensure they are aligned with and supportive of change efforts; listen to new ideas; solicit feedback about how the change effort is progressing; align changes with values and ideas employees can embrace; empower employees to act by removing obstacles and providing support.", barValue: 0 },
          { compassPoint: "**E.	Build Momentum:** Define clear targets and milestones for change efforts and gain commitment to them; know how you will measure success; create short term wins by breaking down change initiative goals in small increments; celebrate success; reward engagement.", barValue: 0 },
        ]
      },
    ]
  },

];

export default compassDefinition;