
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';


export const Modal = (props)=>{
  // console.log('Modal - props:', props)
  let springStyleForModalOverlay = useSpring({
    // marginTop: '-5%',
    position: 'fixed',
    top: 0, right: 0, bottom: 0, left: 0,
    width: '100%', // props.screenSize.width,
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: props.show ? 1 : 0,
    display: `${props.show ? 'flex' : 'none'}`,
    zIndex: 2000
  })
  let springStyleForModalPopup = useSpring({config: {tension: 250},
    backgroundColor: '#ffffff',
    marginBottom: '10%',
    maxWidth: props.width,
    maxWidth: 450,
    minWidth: 200,
    minHeight: 50,
    padding: 30,
    margin: 10,
    borderRadius: 10, 
    transform: `scale(${props.show ? 1 : 0.4},${props.show ? 1 : 0.4})`,
    fontWeight: 600,
    display: `${props.show ? 'flex' : 'none'}`,
    zIndex: 2001
  })

  return(
    <div>
      <animated.div style={springStyleForModalOverlay} 
        // onClick={(e)=> { props.setShow(false); }}
      >
        <animated.div style={springStyleForModalPopup}>
          {props.children}
        </animated.div>
      </animated.div>
    </div>
  )
}