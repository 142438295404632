import React, { useState, useEffect, Fragment } from 'react';
import Home from './Home/Home'
import "isomorphic-fetch";
import { SpinnerSignin, Spinner, CCButton } from './Home/components';
import { LogoAndWordmarkCentered } from './Home/svg/svg_LogoAndWordmarkCentered';
import './App.css';
import firebase from 'firebase';
import './firebase-init.js'

var graph = require('@microsoft/microsoft-graph-client');
const App = () => {
  let db = firebase.firestore();
  let docRef = {};

  let provider = null;
  // let TENANT_ID = '75258168-0fa1-459e-94a6-968aabb9186a';
  let TENANT_ID = '6e23e12e-64ec-492e-a762-f5d2f52f3ffe';
  let token = '';
  let user = null; 
  let accessToken = '';  
  let refreshToken = '';  
  let [userProfile, setUserProfile] = useState(null);
  let [showSpinner, setShowSpinner] = useState(false);

  const signIn = () => {
    provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      tenant: TENANT_ID,      
    });
    firebase.auth().signInWithRedirect(provider)
    .then(function(result) {
      accessToken = result.credential.accessToken;
      console.log('result.credential=', result.credential)
    })
    .catch(function(error) {
      console.log('error=', error.message)
    });
  }

  useEffect(() => {
    if(!firebase.auth().currentUser){   
      // signIn()
    }
  },[!firebase.auth().currentUser])

  const signOut = () => {
    firebase.auth().signOut();
  }

  const initApp = () => {
    if(!user){
      firebase.auth().getRedirectResult()
      .then(function(result) {
console.log('firebase.auth().currentUser.email: ', firebase.auth().currentUser.email)
        let tempToken = result.credential && result.credential.accessToken;
        accessToken = tempToken;  // setAccessToken(tempToken);
        // console.log('accessToken: ', accessToken);
        getUserProfile(accessToken);
        if(tempToken){
          docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_access_token`); 
          docRef.set({
            accessToken: tempToken
          })
          .then(()=>{
            console.log('Success - access token saved to secure location in database');
          }).catch((error)=> {
            console.log('you screwed up!: ', error);
          })
        }
      }).catch(function(error) {
        console.log('Error - getRedirectResult failed: ', error)
      });
    } else {
      // getUserProfile();
    }
  }

  // async function getUserProfile() {
  var msUser = null;
  let client = {};
  function getUserProfile(accessToken) {
    // docRef = db.doc(`campbell_compass/${firebase.auth().currentUser.email}_access_token`); 
    // docRef.get()
    // .then((doc)=>{
      // console.log('SUCCESS - token', doc.data());
      client = graph.Client.init({
        authProvider: (done) => {
          // console.log('done: ', done)
          done(null, accessToken);
        }
      });

      console.log('client: ', client)

      client.api('/me').get()
      .then((result)=> {
        msUser = result;
        console.log('Success - your user profile data was retrieved: ', result);

        // Get the manager data
        let managerName = '';
        client.api('/me/manager').get()
        .then((manager)=>{
          // console.log('This is your manager: ', manager.displayName);
          managerName = manager.displayName;
          // console.log('Success - your manager was retrieved: ', manager.displayName);

          let fbUser = firebase.auth().currentUser;
          msUser['email'] = fbUser.email;
          msUser['uid'] = firebase.auth().currentUser.uid;
          msUser['managerName'] = managerName;
          console.log('XXXXXXXXXXX modified msUser:', msUser)
          setUserProfile(msUser);
          
          // Merge modified user record in campbell_compass/users document
          let docRef = db.doc('campbell_compass/users');
          docRef.set({ 
            users: { 
              [msUser.uid]: msUser,
              }
            }, { merge: true })
          .then(()=>{
            console.log('Success - current user record merged with main users object.');
          })
          .catch((error)=> {
            console.log('Error - new user record not added. ', error);
          });
          // Retrieve photo blob from Active Directory and save it as a jpg in Firebase Cloud Storage
          client.api('/me/photo/$value').get()
          .then((photo) => {
            console.log('Success - your photo was retrieved: ', photo);
            var storageRef = firebase.storage().ref();
            storageRef.child('user_photos/' +firebase.auth().currentUser.email + '.jpg').put(photo)
            .then((result) => {
              console.log('Success - The photo blob was saved as jpg in Firebase Storage', result)
            }).catch(function(error) {
              console.log("Error - could not put the photo into Firebase Storage:", error);
            });
          }).catch(function(error) {
            console.error('Error - photo not retrienved:', error);
          });


        })
        .catch((error)=>{
          managerName = '';
          console.log('Error - your manager was not retrieved: error:', error);
        });
      }).catch((error)=> {
        console.log("ERROR - could not retrieve user's profile: ", error)
      })
    // }).catch((error)=> {
    //   console.log('Error - could not retrieve access token: ', error);
    // });
    if(accessToken){

    }
  }
  
  useEffect(() => {
    if(!firebase.auth().currentUser){
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user = user;  // setUser(user)
          console.log('You signed in');

          let docRef = db.doc('campbell_compass/users');
          docRef.get()
          .then((doc) => {
            let userId = firebase.auth().currentUser.uid;
            let usersDoc = doc.data().users;
            console.log('currentUser exsits: ', usersDoc.hasOwnProperty(userId) );

            // if user record exists then get it and set userProfile with the record.
            if(usersDoc.hasOwnProperty(userId)){
              setUserProfile( doc.data().users[userId] )
            }
            console.log('doc.data().users[userId]: ', doc.data().users[userId])
          }).catch(function(error) {
            console.log("Error getting document:", error);
          });
        } else {
          user = null;  // setUser(null);
          setUserProfile(null);
          accessToken = '';
          console.log('You signed out')
        }
      });
    } else {
      // console.log('currentUser: ', firebase.auth().currentUser.xa );
      // console.log('second accessToken: ', JSON.stringify(firebase.auth().currentUser, null, '  ') );
    }

  })



  // let [showButton, setShowButton] = useState(true)
  let showButton = true;
  useEffect(() => {
    // setShowButton(false);
    showButton = false;
    initApp();
    setTimeout(()=> { showButton = true } , 3000)
  },[])

  // let user = true; // Temporary variable for testing
  // if(userProfile) console.log('userProfile: ', userProfile);
  useEffect(()=>{
    // setTimeout(()=>signIn(), 1000);
  },[])

  return(
    <Fragment>
      {!firebase.auth().currentUser ?
        <div style={{padding: 15}}>
          <div style={{margin: '0 0 20% 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', padding: '40px 25px', 
            height: '100vh', width: '100vw',  backgroundColor: 'white', 
            borderRadius: 0, boxShadow: '0px 3px 7px rgba(0,0,0,0.2)', 
            display: 'flex', flexDirection: 'column',
            margin: '0 20px',
          }}>
            <div style={{margin: '0 auto 20% auto', width: '100%', maxHeight: 400, maxWidth: 350, // border: '2px solid red' 
          
            }}>
              <div style={{textAlign: 'center'}}>
                <div style={{marginBottom: 30, display: 'flex', justifyContent: 'center',}}>
                  <LogoAndWordmarkCentered />
                </div>
              </div>
              { !firebase.auth().currentUser ? 
                <div className="Assess-button-wrapper" style={{fontSize: '120%', width: '100%',}}> 
                  <div style={{ width: '100%', margin: '0 auto'}} onClick={()=> { setShowSpinner(false); signIn() }} >
                    <CCButton>SIGN IN</CCButton>
                  </div>
                </div>
                
              : <SpinnerSignin show={!showButton} />
              }
            </div>
          </div>
        </div>
      : 
        <Fragment>
          <Home user={userProfile} />
        </Fragment>
      
      }
    </Fragment>
  )
}
export default App;


