import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { BackIconOrange, NavBackIcon } from '../../svg/svg_icons';
import { NavButton } from '../../components'; 
import firebase from 'firebase';

let db = firebase.firestore();

const ExecutionNotes = (props)=> {
  // console.log('ExecutionNotes - props: ', props)

  let headerHeight = props.headerHeight;
  let pt = usePageTransitions('/execute_notes', 2);

  let transition = useSpring({
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none',
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
  });

  let [caNotes, setCaNotes] = useState('');

  return(
    <div style={{}}>
      <animated.div style={transition}>
         
        <div style={{position: 'absolute', width: props.wrapper.width, height: props.screenSize.height, 
          backgroundColor: '#e6e6e6', // boxSizing: 'border-box', border: '3px solid red'
        }}>
 
        <div style={{backgroundColor: '#e6e6e6'}}>
        <animated.div  className="Phases-sidein-page" style={transition}>
          <div className="Phases-subpage-header">
            <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
              <NavButton variant="secondary" name="back" size={50} >
                <NavBackIcon height="50" name="ca_notes" />
              </NavButton> 
            </div>
            <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>
              Execution Notes
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center', minHeight: 160, padding: 20, color: 'black', fontSize: '100%', width: '100%', backgroundColor: '#e6e6e6'}}>
            <div>(The Resource, Live Training, or Challenging Assignment summary, would be shown here)</div>
          </div>
          {/*<FroalaEditor placeholder={'Enter your notes here...'} toolbarInLine={false} content={caNotes} setContent={setCaNotes} />*/}
          </animated.div>
        </div>  
        
        
        </div>

      </animated.div>
    </div>
  )
}

export default ExecutionNotes;