import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../vinciToolbox';
import { BackIconOrange, NavBackIcon, CustomEdit } from '../../../svg/svg_icons';
import { CCButton } from '../Plan'
import { NavButton } from '../../../components'; 
import { Modal } from '../../AssessPages/Modal';

import firebase from 'firebase'

import { ChallengingAssignmentEdit } from './ChallengingAssignmentEdit';


export const ChallengingAssignment=(props)=> {
  let pt = usePageTransitions('/challenging_assignment', 2)
  let segment = usePathSegments(null);



  let transition = useSpring({
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    display: pt.render ? 'block' : 'none',
    overflow: 'auto'
  });

  let [showModal, setShowModal] = useState(false);

  let [title, setTitle] = useState('');
  let [summary, setSummary] = useState('');
  let [description, setDescription] = useState('');
   
  let unsubscribe = null;
  useEffect(()=>{
    let db = firebase.firestore();
    let docRef = db.collection(`campbell_compass`).doc(`${firebase.auth().currentUser.email}_challenging_assigment`);
    docRef.get().then((doc) => {
      let data = doc.data().challengingAssignment;
      if (doc && doc.exists) {
        // console.log('xxxxxxxxxxx doc.data(): ', data)
        setTitle(data.title);
        setSummary(data.summary);
        setDescription(data.description);
      }else{
        // Initialize the Firestore document because it doesn't exist yet.
        docRef.set({ 
          challengingAssignment: {
            title: '',
            summary: '',
            description: ''
          }
        },{ merge: true })
        .then(()=>{
          console.log('SUCCESS: Doc updated');
        }).catch((error)=> {
          console.log('Something went wrong with the document creation: ', error);
        });
      }
    }).catch(function(error) {
      console.log("Error getting document:", error);
    });
    
  },[segment[3]])  

  if(segment[2] != 'challenging_assignment'){
    if(unsubscribe){
      unsubscribe();
    }
    // console.log('you have unsubscribed the real-time listener')
  }

  let modalContent = 
  <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: 0, fontSize: '120%', width: '100%'}}>
    <div>The editor is currently not available on small screen mobile devices.</div>
    <div style={{ color: '#666', cursor: 'pointer', marginTop: 20,  textAlign: 'center',}} onClick={()=> { setShowModal(false) } }>
      Close
    </div>
  </div>;

  let descriptionRef = useRef(null);
  descriptionRef.current && (descriptionRef.current.dangerouslySetInnerHTML = description );

  function createMarkup() {
    return {__html: description };
  }
  
  function Description() {
    return <div dangerouslySetInnerHTML={createMarkup()} />;
  }

  // console.log('props.screenSize=', props.screenSize)

  let minimumScreenWidth = 500;

  return(
    <Fragment>
        <div style={{backgroundColor: '#cccccc', }}>
          <animated.div  className="Phases-sidein-page" style={transition}>
            <div className="Phases-subpage-header">
              <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
                <NavButton variant="secondary" name="back" size={50} >
                  <NavBackIcon height="50" name="challengingAssignments" />
                </NavButton> 
              </div>
              <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>Challenging Assignment</div>
            </div>
            <div className="Assess-description-block" 
              style={{minHeight: 100, fontSize: '100%', width: '100%', backgroundColor: 'transparent', overflow: 'hidden'
            }}>

              <div style={{color: '#000', display: 'flex', flexDirection: 'column', margin: '25px 0', width: '100%', 
                // backgroundColor: '#f2f2f2'
              }}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15, borderBottom: '2px solid rgba(0,0,0,0.2)'}}>
                  <div style={{color: '#333', fontSize: '145%', width: '100%', fontWeight: 600, margin: '0 0 0 0', 
                    paddingBottom: 3, 
                  }}>
                   {title ? title : <span style={{color: 'tomato'}}>You haven't entered a title</span>}
                  </div>
                  <div style={{width: 50, display: 'flex', justifyContent: 'flex-end', margin: '0 0 0 0', cursor: 'pointer'}}
                    onClick={()=> props.rt.nav('/plan/challenging_assignment/ca_edit') }
                  >
                    <div style={{borderRadius: '50%', width: 30, height: 30, 
                    backgroundColor: '#F8C115', padding: '1px 0 0 0'
                      // display: 'flex', justfyContent: 'center', alignItems: 'center',
                    }}>
                      <CustomEdit size={'auto'} color="rgba(0,0,0,0.4)" />
                    </div>
                  </div>
                </div>
                <div style={{fontSize: '105%', margin: '0 0 20px 0'}}>
                  <div style={{marginBottom: 5 }}>
                  </div>
                   <div style={{fontSize: '80%', opacity: 0.65}}>SUMMARY: </div> {summary ? summary : <span style={{color: 'tomato'}}>You haven't entered a summary</span>}
                  </div>
                  <div style={{fontSize: '100%', display: 'flex', flexDirection: 'column'}}>
                    <div style={{ marginBottom: 5 }}>
                    </div>
                    <div style={{fontSize: '80%', opacity: 0.65}}>FULL DESCRIPTION: </div> 
                    {description ? <Description /> : <span style={{color: 'tomato'}}>You haven't entered a description</span>}
                </div>
              </div>
            </div>

          </animated.div>
          <Modal show={ showModal ? true : false} width={'80%'}>
            {modalContent}
          </Modal> 
        </div>
      <ChallengingAssignmentEdit rt={props.rt} wrapper={props.wrapper} screenSize={props.screenSize} user={props.user} />
    </Fragment>
  )
}


