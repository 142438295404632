import React, { useState, useEffect, Fragment, useRef } from 'react';
// import { useSpring, animated } from 'react-spring';
// import { BigPlus, CustomDelete } from '../svg/svg_icons';
// import { NavButton } from '../components'; 
// import { Form } from 'react-bootstrap'; 
// import { Modal } from '../../Phase/AssessPages/Modal';
import { AutocompleteUsers, NavButton } from '../components';
import { BigPlus, GoldBall } from '../svg/svg_icons';
import { CustomEx } from '../svg/svg_icons';
import firebase from 'firebase';

let db = firebase.firestore();


const UserRoles = (props) => {
  // let [value, setValue] = useState('');
  let [quoteAdmins, setQuoteAdmins] = useState([]);
  let [liveLearningAdmins, setLiveLearningAdmins] = useState([]);
  let [libraryAdmins, setLibraryAdmins] = useState([]);
  let [permissionsAdmins, setPermissionsAdmins] = useState([]);


  
  var unsubscribe = null;
  let tempArray = [];
  const getRealtimeUpdates = () =>{
    unsubscribe = db.collection(`campbell_compass`).doc(`admin_permissions`)
      .onSnapshot((doc)=>{
      if(doc && doc.exists) {
        let data = doc.data();
        // console.log('Inside real time listener - data', data.editQuotes);

        tempArray = [];
        for(let i = 0; i < data.editQuotes.length; ++i ){
          tempArray.push({id: i, pageName: 'quotes', name: data.editQuotes[i]})
        }
        setQuoteAdmins(tempArray);

        tempArray = [];
        for(let i = 0; i < data.editLiveLearning.length; ++i ){
          tempArray.push({id: i, pageName: 'live_learning', name: data.editLiveLearning[i]})
        }
        setLiveLearningAdmins(tempArray);
        
        tempArray = [];
        for(let i = 0; i < data.editLibrary.length; ++i ){
          tempArray.push({id: i, pageName: 'library', name: data.editLibrary[i]})
        }
        setLibraryAdmins(tempArray);
        
        tempArray = [];
        for(let i = 0; i < data.editPermissions.length; ++i ){
          tempArray.push({id: i, pageName: 'permissions', name: data.editPermissions[i]})
        }
        setPermissionsAdmins(tempArray);
        // setQuoteAdmins(data.editQuotes)
      }else{
        console.log('There was an onSnapshot() error')
      }
    })
  }
  useEffect(()=>{
    getRealtimeUpdates(); 
  },[])
  // if(!props.pt.render, props){
  //   if(unsubscribe){
  //     unsubscribe();
  //   }
  // }



  

  let db = firebase.firestore();

  /**  TEST if current user should see this page */
  let [userPermitted, setUserPermitted] = useState(false);
  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    // for(let i = 0; i < data.editLibrary.length; ++i){
    //   if(data.editLibrary[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    // for(let i = 0; i < data.editLiveLearning.length; ++i){
    //   if(data.editLiveLearning[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    for(let i = 0; i < data.editPermissions.length; ++i){
      if(data.editPermissions[i] === userName){
        tempResult = true;   
      }
    }
    // for(let i = 0; i < data.editQuotes.length; ++i){
    //   if(data.editQuotes[i] === userName){
    //     tempResult = true;   
    //   }
    // }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */




  return(
    <Fragment>  
      {userPermitted ?
        <div>
          <div className="Assess-description-block" 
            style={{fontSize: '100%', lineHeight: '1.4em', padding: '15px 25px 17px 25px', width: '100%', backgroundColor: '#f8f8f8', 
          }}>
            <div style={{width: '100%', textAlign: 'center', }}>
              Click the plus button to add new admins.<br />Click the X icons to delete them.
            </div>
          </div>

          <RolesComponent
            pageName={'quotes'} buttonLabel={'New admin'} heading={'Quote of the Day editors'}
            showModal={props.showModal} setShowModal={props.setShowModal} setModalContent={props.setModalContent}
            quoteAdmins={quoteAdmins} setQuoteAdmins={setQuoteAdmins}
          />

          <RolesComponent
          pageName={'live_learning'} buttonLabel={'New admin'} heading={'Live Learning editors'}
          showModal={props.showModal} setShowModal={props.setShowModal} setModalContent={props.setModalContent}
          liveLearningAdmins={liveLearningAdmins} setLiveLearningAdmins={setLiveLearningAdmins}
          />

          <RolesComponent
            pageName={'library'} buttonLabel={'New admin'} heading={'Resource Library editors'}
            showModal={props.showModal} setShowModal={props.setShowModal} setModalContent={props.setModalContent}
            libraryAdmins={libraryAdmins} setLibraryAdmins={setLibraryAdmins}
          />

          <RolesComponent
            pageName={'permissions'} buttonLabel={'New admin'} heading={'Admin access editors'}
            showModal={props.showModal} setShowModal={props.setShowModal} setModalContent={props.setModalContent}
            permissionsAdmins={permissionsAdmins} setPermissionsAdmins={setPermissionsAdmins}
            />
          </div>
        :
          <div style={{textAlign: 'center', marginTop: 50, fontWeight: 500, color: 'red'}}>
            <div>YOU DON'T HAVE ACCESS TO THIS PAGE</div>
          </div>
        }
    </Fragment>

  )
}
export default UserRoles;

const RolesComponent = (props) => {
 
  // if(props) console.log('props.setQuoteAdmins=', props.setQuoteAdmins)  


  let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  useEffect(() => {
    docRef.get()
    .then((doc) => {
      let data = doc.data();

      if(props.pageName==='quotes'){
        let tempArray = [];
        for(let i = 0; i < data.editQuotes.length; ++i ){
          tempArray.push({id: i, pageName: props.pageName, name: data.editQuotes[i]})
        }
        props.setQuoteAdmins(tempArray);
      }

      if(props.pageName==='live_learning'){
        let tempArray = [];
        for(let i = 0; i < data.editLiveLearning.length; ++i ){
          tempArray.push({id: i, pageName: props.pageName, name: data.editLiveLearning[i]})
        }
        props.setLiveLearningAdmins(tempArray);
      }

      if(props.pageName==='library'){
        let tempArray = [];
        for(let i = 0; i < data.editLibrary.length; ++i ){
          tempArray.push({id: i, pageName: props.pageName, name: data.editLibrary[i]})
        }
        props.setLibraryAdmins(tempArray);
      }

      if(props.pageName==='permissions'){
        let tempArray = [];
        for(let i = 0; i < data.editPermissions.length; ++i ){
          tempArray.push({id: i, pageName: props.pageName, name: data.editPermissions[i]})
        }
        props.setPermissionsAdmins(tempArray);
      }

    }).catch((error) => {
      console.log('ERROR - ', error)
    })  
  },[]);

  let adminList = [];

  if(props.pageName==='quotes'){
    adminList = props.quoteAdmins && props.quoteAdmins.map((adm) => 
      <div key={adm.id} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{fontWeight: 500, textAlign: 'center', marginRight: 10}}>
          {adm.name}
        </div>
        {adm.name != 'Heather Tortel' ?
          <div style={{marginTop: -12, padding: 10, cursor: 'pointer'}}
            onClick={()=>handleDeleteAdmin(adm.id, adm.pageName, adm.name)}
          >
            <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
          </div>
        : 
          ''
        }
      </div>
    );
  }else if(props.pageName==='live_learning'){
    adminList = props.liveLearningAdmins && props.liveLearningAdmins.map((adm) => 
      <div key={adm.id} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{fontWeight: 500, textAlign: 'center', marginRight: 10}}>
          {adm.name}
        </div>
        {adm.name != 'Heather Tortel' ?
          <div style={{marginTop: -12, padding: 10, cursor: 'pointer'}}
            onClick={()=>handleDeleteAdmin(adm.id, adm.pageName, adm.name)}
          >
            <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
          </div>
        : 
          ''
        }
      </div>
    );
  }else if(props.pageName==='library'){
    adminList = props.libraryAdmins && props.libraryAdmins.map((adm) => 
      <div key={adm.id} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{fontWeight: 500, textAlign: 'center', marginRight: 10}}>
          {adm.name}
        </div>
        {adm.name != 'Heather Tortel' ?
          <div style={{marginTop: -12, padding: 10, cursor: 'pointer'}}
            onClick={()=>handleDeleteAdmin(adm.id, adm.pageName, adm.name)}
          >
            <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
          </div>
        : 
          ''
        }
      </div>
    );
  }else if(props.pageName==='permissions'){
    adminList = props.permissionsAdmins && props.permissionsAdmins.map((adm) => 
      <div key={adm.id} style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{fontWeight: 500, textAlign: 'center', marginRight: 10}}>
          {adm.name}
        </div>
        {adm.name != 'Heather Tortel' ?
          <div style={{marginTop: -12, padding: 10, cursor: 'pointer'}}
            onClick={()=>handleDeleteAdmin(adm.id, adm.pageName, adm.name)}
          >
            <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
          </div>
        : 
          ''
        }
      </div>
    );
  }

  const handleAddAdmin = () =>{
    props.setModalContent (
      <div>
        <EditRolesModalContent showModal={props.showModal} setShowModal={props.setShowModal} 
          pageName={props.pageName} 
        />
      </div>
    );
    props.setShowModal(true)
  }

  const handleDeleteAdmin = (id, pageName, personName) =>{
    props.setModalContent (
      <div>
        <DeleteAdminModalContent showModal={props.showModal} setShowModal={props.setShowModal} 
          personName={personName} index={id} pageName={pageName}
        />
      </div>
    );
    props.setShowModal(true)
  }

  const handleDeleteAdminNow = (index, pageName) =>{
  
  }

  return(
    <div style={{margin: '30px 0 20px 0', padding: '0 0 40px 0',
      borderBottom: '1px solid rgba(0,0,0,0.15)'}} >  {/* // setShowModal(true)*/}
      <div style={{fontWeight: 600, fontSize: '110%', textAlign: 'center', marginBottom: 20}}>
        {props.heading}
      </div>
      <div style={{marginBottom: 20}}>
        {adminList}
      </div>
      <NavButton size={60} >
        <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', 
        }}  onClick={()=> handleAddAdmin() }>
          <div style={{position: 'relative', top: -25, left: -25}}>
            <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0}}>
              <GoldBall size={50} name="authorize1" />
            </div>
            <div style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, margin: '7px 0 0 8px'}}>
                <BigPlus size={40} name={'userRoles'} color='rgba(0,0,0,0.3)' /> 
            </div>
          </div>
        </div>
      </NavButton>
      <div style={{fontSize: '80%', color: '#444', marginTop: 5, textAlign: 'center'}}>
        {props.buttonLabel}
      </div>

    </div>
  )
}


const EditRolesModalContent = (props) => {
  let [value, setValue] = useState('');
  let [clear, setClear] = useState('');
  let [nameFound, setNameFound] = useState('');

  const handleContinue = () => {
    // console.log('Inside handleContinue() - value: ', value);
    // console.log('Inside handleContinue() - props: ', props);
    
    let permissionsObject = {}

    let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
    docRef.get()
    .then((doc) => {
      let data = doc.data();
      permissionsObject = {
        editQuotes: data.editQuotes,
        editLiveLearning: data.editLiveLearning,
        editLibrary: data.editLibrary,
        editPermissions: data.editPermissions
      }
      if(props.pageName==='quotes'){
        let tempArray = data.editQuotes;
        if(value != 'Heather Tortel') tempArray.push(value)
        permissionsObject['editQuotes'] = tempArray;

      }else if(props.pageName==='live_learning'){
        let tempArray = data.editLiveLearning;
        if(value != 'Heather Tortel') tempArray.push(value)
        permissionsObject['editLiveLearning'] = tempArray;

      }else if(props.pageName==='library'){
        let tempArray = data.editLibrary;
        if(value != 'Heather Tortel') tempArray.push(value)
        permissionsObject['editLibrary'] = tempArray;

      }else if(props.pageName==='permissions'){
        let tempArray = data.editPermissions;
        if(value != 'Heather Tortel') tempArray.push(value)
        permissionsObject['editPermissions'] = tempArray;
      }
      // console.log('the permissionsObject: ', permissionsObject)
      docRef.set(
        permissionsObject, { merge: true }
      )
    }).catch((error) => {
      console.log('ERROR - ', error)
    });    
    props.setShowModal(false);

  }

  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
        <div >
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className="OtherAssessment" 
              style={{display: 'flex', justifyContent: 'center', 
              position: 'relative', margin: '0px 0 0px 0', width: '100%'}}
            >
              <div style={{position: 'static', width: '100%', maxWidth: 500, display: 'flex', justifyContent: 'center'}}>
              <AutocompleteUsers value={value} setValue={setValue} 
                clear={clear} setClear={setClear} nameFound={nameFound} setNameFound={setNameFound} 
                setShowModal={props.setShowModal} handleContinue={handleContinue}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


const DeleteAdminModalContent = (props) => {

  const handleDeleteFromDB = () => {
    // console.log('Inside handleContinue() - ', props);

    let permissionsObject = {}

    let docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
    docRef.get()
    .then((doc) => {
      let data = doc.data();
      permissionsObject = {
        editQuotes: data.editQuotes,
        editLiveLearning: data.editLiveLearning,
        editLibrary: data.editLibrary,
        editPermissions: data.editPermissions
      }
      if(props.pageName==='quotes'){
        let tempArray = [];
        for(let i = 0; i < data.editQuotes.length; ++i ){
          if(props.index != i){
            tempArray.push(data.editQuotes[i])
          }
        }
        permissionsObject['editQuotes'] = tempArray;
      }else if(props.pageName==='live_learning'){
        let tempArray = [];
        for(let i = 0; i < data.editLiveLearning.length; ++i ){
          if(props.index != i){
            tempArray.push(data.editLiveLearning[i])
          }
        }
        permissionsObject['editLiveLearning'] = tempArray;
      }else if(props.pageName==='library'){
        let tempArray = [];
        for(let i = 0; i < data.editLibrary.length; ++i ){
          if(props.index != i){
            tempArray.push(data.editLibrary[i])
          }
        }
        permissionsObject['editLibrary'] = tempArray;
      }else if(props.pageName==='permissions'){
        let tempArray = [];
        for(let i = 0; i < data.editPermissions.length; ++i ){
          if(props.index != i){
            tempArray.push(data.editPermissions[i])
          }
        }
        permissionsObject['editPermissions'] = tempArray;
      }
      console.log('the permissionsObject: ', permissionsObject)
      docRef.set(
        permissionsObject, { merge: true }
      )

    }).catch((error) => {
      console.log('ERROR - ', error)
    })      
    props.setShowModal(false);
  }

  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column',}}>
        <div style={{display: 'flex', justifyContent: 'center', fontSize: '130%', color: '#333333'}}>
          Remove {props.personName} ?
        </div>
        <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 25, fontSize: '130%', width: '100%'}}>
          <div style={{ color: '#999999'}} onClick={()=> props.setShowModal(false) }>
            Cancel
          </div>
          <div style={{
            color: `#ff0000`,
            cursor: 'pointer',
          }} 
            onClick={()=> handleDeleteFromDB()  }>
            Remove  
          </div>
        </div>
      </div>
    </div>
  )
}

