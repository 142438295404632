import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { PlusGray, CustomCheck } from '../../../../../svg/svg_icons';
import {  usePathSegments} from '../../../../../../vinciToolbox';
import firebase from 'firebase';
import extractDomain from 'extract-domain';
import { Stars } from '../Stars'
import '../../../../../../firebase-init';

export const ListOfTraits = React.memo(props => {
  let refTraitsList = useRef(null);
  const traitListRef = useRef(null);
  useEffect(()=>{
    props.setListHeight(traitListRef.current.offsetHeight);
  },[])
  const theProps = props.props.props;

  let mappedTraits = '';
  if(props.selected){
    let traits = [];
    for(let index = 0; index < props.selected.traits.length; ++index){
      traits.push({index: index, selected: props.selected.traits[index]})
    }
    for(let n = 0; n < traits.length; ++n){
      mappedTraits = traits.map((trait)=>
        <div ref={refTraitsList} key={trait.index} style={{display: 'flex', justifyContent: 'space-between', marginBottom: 14}}>
          <div style={{width: '90%', display: 'flex'}}>
            <div style={{
              borderLeft: '1px solid rgba(0,0,0,0.5)', 
              borderBottom: '1px solid rgba(0,0,0,0.5)',
              width: 30, height: 50, marginTop: -39,
              marginRight: 0,
              color: 'rgba(0,0,0,0)' }}
            >
              
            </div>
            <div style={{marginLeft: 10, cursor: 'pointer', width: theProps.wrapper.width - 270 }}
              onClick={(e)=> theProps.rt.nav(`/plan/resources/add_resources/resources:${props.selectedIndex}:${trait.index}`)}
            >
              <Trait competency={props.competency} trait={trait.selected}/>
            </div>
          </div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className="Resources-spacer">
              <div style={{display: 'flex'}}>
                <div className='Resources-value' style={{margin: '-6px 10px 0 0'}}>
                  {trait.selected.barValue}
                </div>
                <div className="Resources-outer-bar" style={{width: 100}}>
                  <Bar value={trait.selected.barValue} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
  const traitList = 
  <div style={{display: 'flex', marginLeft: 15}}>
    <div style={{width: '100%'}} >
      {mappedTraits}
    </div>
  </div>;
  return (
    <Fragment>
      <div ref={traitListRef} style={{paddingTop: 20}} >
        {traitList}
      </div>
    </Fragment>
  )
});


const Trait = React.memo(props=> {
  // console.log('props=', props)
  let s = props.trait.compassPoint;
  let regex = /\*\*/gi; 
  s.replace(regex, '') 
  return(
    <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
      {s.replace(regex, '')}
    </div>
  )
});


const Bar = (props) => {
  let barlength = props.value / 5 * 100;
  return(
    <div className="Resources-inner-bar" style={{width: barlength}}></div>
  )
}



// const AddResourceMenu = (props) => {

//   let menuStyle = useSpring({
//     config: {tension: 400},
//     position: 'relative', 
//     width: 320, 
//     // height: 100,
//     top: -15, left: 20,
//     padding: '15px 30px',
//     backgroundColor: '#f4f4f4',
//     transform: `scale(${props.menu ? 1 : 0.5},${props.menu ? 1 : 0.5})`,
//     display: props.menu ? 'block' : 'none',
//     borderRadius: 10,
//     boxShadow: '0 0 20px rgba(0,0,0,0.2)',
//     color: '#444444',
//     fontSize: '110%',
//     userSelect: 'none',
//   })

//   return(
//     <animated.div style={menuStyle} >
//       <div style={{fontWeight: 500}}>
//         Add this resource to your Saved Resources?
//       </div>
//       <div style={{display: 'flex', justifyContent: 'space-between',
//         margin: '15px 0 0 0',
//         fontWeight: 500,
//         fontSize: '110%',
//       }}>
//         <div style={{cursor: 'pointer'}} 
//           onClick={(e)=>props.setMenu(false)}
//         >
//           Cancel
//         </div>
//         <div style={{ cursor: 'pointer'}} 
//           onClick={(e)=>props.handleAdd(e, props.index)}
//         >
//           Add resource
//         </div>
//       </div>

//     </animated.div>
//   )
// }