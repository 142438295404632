import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments } from '../../../../../../../vinciToolbox';
import { NavBackIcon } from '../../../../../../svg/svg_icons';
import { EditResourceLibrary } from './EditResourceLibrary/EditResourceLibrary';
import { Resource } from './Resources';
import { NavButton, Spinner } from '../../../../../../components'; 
import {  compassDefinition } from '../../../../../AssessPages/compassDefinition';
import firebase from 'firebase';
import '../../../../../../../firebase-init';
import ReactHtmlParser from 'react-html-parser';
var markdown = require('markdown-it')();

export const ResourceLibrary = React.memo(prps=> {
  let props = prps.props;
  let pt = usePageTransitions('/resources', 4);
  let segment = usePathSegments();
  let [competency, setCompetency] = useState('(content missing)');
  let [compassPoint, setCompassPoint] = useState('(content missing)');
  let [spinner, setSpinner] = useState(false);
  
  useEffect(()=>{
    if(segment[4]) setSpinner(true);
  },[segment[4]]);


  let p = [];
  useEffect(()=>{
    if(segment[4]){
      p = segment[4].split(':');
      let temp = p[1] / 3;
      let cat = Math.floor( temp )
      let subh = ((p[1] / 3) % 1);
      subh = Math.floor(subh * 10 / 3);

      if(props && prps.compassData && prps.compassData.ccDef && prps.compassData.ccDef[cat] && prps.compassData.ccDef[cat]){
        setCompetency(prps.compassData.ccDef[cat].subHeading[subh].name );
        let s = prps.compassData.ccDef[cat].subHeading[subh].compassPoint[p[2]].compassPoint;
        let regex = /\*\*/gi; 
        setCompassPoint( s.replace(regex, '') );
        setCompassPoint( ReactHtmlParser(markdown.render( s )) )
      }
    }
  },[prps])

  // console.log('props.screenSize.height=', props.screenSize.height);

  let dropdownLine = {
    height: 15,
    width: 30,
    margin: '-10px 10px 0 5px',
    borderLeft: '1px solid rgba(0,0,0,0.7)',
    borderBottom: '1px solid rgba(0,0,0,0.7)',
    color: 'rgba(0,0,0,0)'
  }

  // let [resourceLibrary, setResourceLibrary] = useState(null); // WHERE THE ENTIRE RESOURCE LIBRARY STATE IS STORED.
  let [data, setData] = useState( null );
  let db = firebase.firestore();
  let docRef = {};
  let theUrl = '';
  p = segment[4] && segment[4].split(':');

  useEffect(()=>{
    theUrl = `campbell_compass/resource_library_${p && p[1]}_${p && p[2]}`;
    // console.log('docRef=', `campbell_compass/resource_library_${p && p[1]}_${p && p[2]}`);
    docRef = db.doc(theUrl);
    docRef.get().then(function(doc) {
      if (doc.exists) {
        setData({data: doc.data(), status: 'success' });
       //  console.log('doc.data()=', doc.data())
        setSpinner(false);
      } else {
        // doc.data() will be undefined in this case, so clear the data hook
        setData({data: [], status: 'error' });
          // console.log("No such document!");
        setSpinner(false);
      }
    }).catch(function(error) {
      setData({data: error, status: 'error' });
      console.log('error=', error);
    });
  },[segment[4]]);

if(data){
  // console.log('data= ', data)
}


  let [rest, setRest] = useState(false)
  let transition = useSpring({
    onRest: ()=> {
     if(segment && segment[4]){
        //console.log('at rest', segment); 
        //setTimeout(()=>setRest(true), 3000)
     }
    },
    config: {tension: 250},
    transform: `translateX(${pt.animate ? 0 : props.screenSize.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    boxShadow: '-5px 0 15px rgba(0,0,0,0.15)',
    overflowX: 'hidden', overflowY: 'auto',
    display: pt.render ? 'block' : 'none',
  });

  const getRealtimeUpdates = () =>{
    theUrl = `campbell_compass/resource_library_${p && p[1]}_${p && p[2]}`;
    docRef.onSnapshot((doc)=>{
      // console.log('xxxxxxxxxxxxxxxxxxxxxxxxx INSIDE ResourceLibrary - onSnapshot()')
      if(doc && doc.exists) {
        setData({data: doc.data(), status: 'success' });
        return( null );
      }
    }, (error)=> {
      setData({data: error, status: 'error' });
      console.log('error=', error);
      return( null );
    });
  }

  useEffect(()=>{        
    getRealtimeUpdates(); 
  },[])

  const refWrapper = useRef(null);

  let [wrapperHeight, setWrapperHeight] = useState(200);
  useEffect(()=>{
    if(refWrapper.current){
      setWrapperHeight(wrapperHeight = props.screenSize.height - refWrapper.current.getBoundingClientRect().top);
    }
  },[refWrapper.current]);

  let [tabSlide, setTabSlide] = useState(0);

  let tabBar = useSpring({config: {tension: 300},
    height: '100%', width: '25%',
    backgroundColor: '#F8C115',
    transform: `translateX(${ tabSlide * (props.wrapper.style.width / 4)}px)`
  })

  let contentSlide = useSpring(
    { config: {tension: 400},
      width: props.wrapper.width * 4,
      overflow: 'hidden',
      transform: `translateX(${ -tabSlide * (props.wrapper.style.width)}px)`,
      display: pt.animate ? 'flex' : 'none',
    }
  )

  p = segment && segment[4] && segment[4].split(':');

  let resourceType = 0;

  switch(tabSlide){
    case 0: resourceType = 'books'; break;
    case 1: resourceType = 'videos'; break;
    case 2: resourceType = 'weblinks'; break;
    case 3: resourceType = 'podcasts'; break;
  }
  // console.log('data=', data && data.data.books)
  let refHeaderBlock = useRef(null);
  // console.log('props=', props)



  /**  TEST if current user should see the EDIT link */
  let [userPermitted, setUserPermitted] = useState(false);
  docRef = db.collection(`campbell_compass`).doc(`admin_permissions`);
  let tempResult = false;
  docRef.get()
  .then((doc) => {
    let data = doc.data();
    // console.log('data=', data);
    let userName = firebase.auth().currentUser.displayName;
    for(let i = 0; i < data.editLibrary.length; ++i){
      if(data.editLibrary[i] === userName){
        tempResult = true;   
      }
    }
    setUserPermitted(tempResult);
  }).catch((error) => {
    console.log('ERROR - ', error);
  });
  /** END OF TEST */




  return(
    <Fragment>
      <Fragment>
        <animated.div className="Phases-sidein-page" style={transition}>
          <div style={{
            height: props.wrapper.style.height,
            // overflowX: 'hidden', overflowY: 'scroll'  // Controls the overflow of the main window
          }}>
            <div ref={refHeaderBlock} className="Phases-subpage-headerBlock">
              <div className="Phases-subpage-header">
                <div className="Phase-subpage-header-icon" onClick={()=>{window.history.go(-1);setRest(false)} }>
                  <NavButton variant="secondary" name="back" size={50} >
                    <NavBackIcon height="50" name="resourceLibrary" />
                  </NavButton> 
                </div>
                <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>Resource Library</div>
              </div>
              <div style={{
                display: 'flex', fontWeight: 600, cursor: 'pointer',
              }}>
              <div style=
                {{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 0 ? '#000000' : '#888888',
                  backgroundColor: '#e6e6e6',
                }} 
                onClick={(e)=> setTabSlide(0)}>Books</div>
                <div style=
                  {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 1 ? '#000000' : '#888888',
                    backgroundColor: '#e6e6e6',
                  }} 
                  onClick={(e)=> setTabSlide(1)}>Videos</div>
                  <div style=
                  {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 2 ? '#000000' : '#888888',
                    backgroundColor: '#e6e6e6',
                  }} 
                  onClick={(e)=> setTabSlide(2)}>Weblinks</div>
                  
                  <div style=
                  {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 2 ? '#000000' : '#888888',
                    backgroundColor: '#e6e6e6',
                  }} 
                  onClick={(e)=> setTabSlide(3)}>Podcasts</div>
              </div>
              <div style={{width: '100%', height: 7, marginTop: -7}}>
                <animated.div style={tabBar}></animated.div>
              </div>

              <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
                <div style={{fontWeight: 600, fontSize: '100%'}}>{competency}
                  <div style={{display: 'flex', justifyContent: 'space-between' , marginTop: 15}}>
                  <div style={dropdownLine}>xx</div>
                  <div style={{marginTop: -5, fontWeight: 400, fontSize: '90%'}}>{compassPoint}</div>
                  </div>
                </div>

                {userPermitted ?
                  <div style={{color: 'red', fontWeight: 700, fontSize: '90%', 
                    textDecoration: 'underline', textAlign: 'center', width: '100%',
                  }} 
                    onClick={(e)=> props.rt.nav(`/plan/resources/add_resources/resources:${p && p[1]}:${p && p[2]}/edit_resource_library:${resourceType}`)}
                  >
                    Edit {resourceType}
                  </div>
                : 
                  ''
                }
                </div>



            </div>
            <animated.div ref={refWrapper} style={contentSlide} >
              <div style={{width: props.wrapper.width}}>

                <Resource rt={props.rt} show={tabSlide === 0 ? true : false} segment={segment} resourceType="books" 
                 data={data} user={props.user}
                  width={props.wrapper.width}
                  wrapper={props.wrapper}
                  headerHeight={refHeaderBlock.current && refHeaderBlock.current.offsetHeight}
                  // collection={collection} setCollection={setCollection}
                />

              </div>
              <div style={{width: props.wrapper.width}}>

                <Resource rt={props.rt} show={tabSlide === 1 ? true : false} segment={segment}resourceType="videos" 
                  data={data} user={props.user}
                  width={props.wrapper.width}
                  wrapper={props.wrapper}
                  headerHeight={refHeaderBlock.current && refHeaderBlock.current.offsetHeight}
                />

              </div>
              <div style={{width: props.wrapper.width}}>

                <Resource rt={props.rt} show={tabSlide === 2 ? true : false} segment={segment} resourceType="weblinks" 
                  data={data} user={props.user}
                  width={props.wrapper.width}
                  wrapper={props.wrapper}
                  headerHeight={refHeaderBlock.current && refHeaderBlock.current.offsetHeight}
                />

              </div>
              <div style={{width: props.wrapper.width}}>

                <Resource rt={props.rt} show={tabSlide === 3 ? true : false} segment={segment} resourceType="podcasts" 
                  data={data} user={props.user}
                  width={props.wrapper.width}
                  wrapper={props.wrapper}
                  headerHeight={refHeaderBlock.current && refHeaderBlock.current.offsetHeight}
                />

              </div>
            </animated.div>
            <Spinner spinner={spinner} />
          </div>
        </animated.div>
      </Fragment>
      {/* THIS IS the instance in the DOM of the EditResourceLibrary page. 
        Notice the "rt={props.rt}" prop that is used for determining when to render the EditResourceLibrary compoent.
      */}
      <EditResourceLibrary props={props} rt={props.rt} 
        competency={competency} compassPoint={compassPoint} 
        data={data} setData={setData}
      />

    </Fragment>
  )
});

