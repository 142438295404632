import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../../../vinciToolbox';
import { NavBackIcon } from '../../../../svg/svg_icons';
import { CCButton } from '../../../../components'
import { NavButton } from '../../../../components'; 
import { OneCollectionResource } from './OneCollectionResource';
import firebase from 'firebase';
import '../../../../../firebase-init';

export const ResourceCollection=(props)=> {
  // console.log('ResourceCollection - props', props)
  let pt = usePageTransitions('/resources', 2);
  let segment = usePathSegments();
  let db = firebase.firestore();
  let docRef = {};
  let [data, setData] = useState( [] );
  docRef = db.doc(`campbell_compass/${props.user && props.user.email}_collection`); 

  useEffect(()=>{
    if(props.user) {
      // console.log('ResourceCollection - props.user=', props.user)
      const getRealtimeUpdates = () =>{
        docRef.onSnapshot((doc)=>{
          if(doc && doc.exists) {
            setData({content: doc.data(), status: 'success' });
            // console.log('doc.data()=', doc.data() );
            return( null );
          }
        }, (error)=> {
          setData({content: error, status: 'error' });
          console.log('error=', error);
          return( null );
        });
      }
      getRealtimeUpdates();  
    }
  },[props.user])

  let collectionBooks = [];
  let collectionVideos = [];
  let collectionWeblinks = [];
  let collectionPodcasts = [];

  let collectionContentsBooks = '';
  let collectionContentsVideos = '';
  let collectionContentsWeblinks = '';
  let collectionContentsPodcasts = '';
  
  if(data.status==='success' && data.content.collection){
    for(let i=0; i < data.content.collection.length; ++i){
      if(data.content.collection[i].type === 'books'){
        collectionBooks.push( {index: i, data: data.content.collection[i]} );
      }
    }
    for(let i=0; i < data.content.collection.length; ++i){
      if(data.content.collection[i].type === 'videos'){
        collectionVideos.push( {index: i, data: data.content.collection[i]} );
      }
    }
    for(let i=0; i < data.content.collection.length; ++i){
      if(data.content.collection[i].type === 'weblinks'){
        collectionWeblinks.push( {index: i, data: data.content.collection[i]} );
      }
    }
    for(let i=0; i < data.content.collection.length; ++i){
      if(data.content.collection[i].type === 'podcasts'){
        collectionPodcasts.push( {index: i, data: data.content.collection[i]} );
      }
    }

    // console.log('data.content.collection[i]', data.content.collection)

    collectionContentsBooks = collectionBooks.map((r)=>
    <div key={r.index}>
      <OneCollectionResource type={r.data.type} 
        title={r.data.title} 
        description={r.data.description} 
        image={r.data.image} url={r.data.url} index={r.index} 
        user={props.user}
        rating={r.data.rating}
      />
    </div> );
    collectionContentsVideos = collectionVideos.map((r)=>
    <div key={r.index}>
      <OneCollectionResource type={r.data.type} 
        title={r.data.title} 
        description={r.data.description}
        image={r.data.image} url={r.data.url} index={r.index} 
        user={props.user}
        rating={r.data.rating}
      />
    </div> );
    collectionContentsWeblinks = collectionWeblinks.map((r)=>
    <div key={r.index}>
      <OneCollectionResource type={r.data.type} 
        title={r.data.title} 
        description={r.data.description}
        image={r.data.image} url={r.data.url} index={r.index} 
        user={props.user}
        rating={r.data.rating}
      />
    </div> );
    collectionContentsPodcasts = collectionPodcasts.map((r)=>
    <div key={r.index} >
      <OneCollectionResource type={r.data.type} 
        title={r.data.title} 
        description={r.data.description}
        image={r.data.image} url={r.data.url} index={r.index} 
        user={props.user}
        rating={r.data.rating}
      />
    </div> );
  }

  let transition = useSpring({
    position: 'absolute', top: 0, left: 0,
    transform: `translateX(${pt.animate ? 0 : props.wrapper.width}px)`,
    backgroundColor: '#e6e6e6',  
    width: props.wrapper.width, 
    height: props.screenSize.height,
    backgroundColor: '#e6e6e6',
    overflowX: 'hidden', overflowY: 'auto',
    display: pt.render ? 'block' : 'none'
  });

  let [tabSlide, setTabSlide] = useState(false);
  
  let tabBar = useSpring({
    height: '100%', 
    width: '25%',
    backgroundColor: '#F8C115',
    transform: `translateX(${ tabSlide * (props.wrapper.style.width / 4)}px)`
  })

  let collectionStyle = useSpring({
    height: 'auto', 
    width: props.wrapper.width * 4, 
    backgroundColor: '#e6e6e6',
    // overflow: 'hidden', 
    display: pt.render ? 'flex' : 'none',
    transform: `translateX(${ -props.wrapper.style.width * tabSlide}px)`,
  })

  let refHeader = useRef(null);
  return(
    <Fragment>
        <Fragment>
        <div  style={{overflowX: 'hidden'}}>
          <animated.div  className="Phases-slidein-page" style={transition}>
            <div ref={refHeader}   style={{}} >
              <div className="Phases-subpage-header">
                <div className="Phase-subpage-header-icon" onClick={()=>window.history.go(-1)}>
                  <NavButton variant="secondary" name="back" size={50} >
                    <NavBackIcon height="50" name="resourceCollection" />
                  </NavButton> 
                </div>
                <div className="Phase-subpage-header-text"  style={{margin: '0 auto'}}>Saved Resources</div>
              </div>
              <div style={{display: 'flex', fontWeight: 600, cursor: 'pointer'}}>
                <div style= {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 0 ? '#000000' : '#888888'
                  }} 
                  onClick={(e)=> setTabSlide(0)}>
                    Books
                </div>
                <div style= {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 1 ? '#000000' : '#888888'
                  }} 
                  onClick={(e)=> setTabSlide(1)}>
                    Videos
                </div>
                <div style= {{
                    width: '25%', textAlign: 'center', padding: '14px 0',
                    color: tabSlide === 2 ? '#000000' : '#888888'
                  }} 
                  onClick={(e)=> setTabSlide(2)}>
                    Weblinks
                </div>
                <div style= {{
                  width: '25%', textAlign: 'center', padding: '14px 0',
                  color: tabSlide === 3 ? '#000000' : '#888888'
                }} 
                onClick={(e)=> setTabSlide(3)}>
                  Podcasts
              </div>
              </div>
              <div style={{width: '100%', height: 7, marginTop: -7}}>
                <animated.div style={tabBar}></animated.div>
              </div>
              <div className="Phases-description-block" style={{backgroundColor: '#f4f4f4'}}>
                <span>This page contains the resources you have saved. 
                {/*Click the <span style={{fontWeight: 600}}>Add Resources</span> button to add resources, 
                or the <span style={{fontWeight: 600}}>X</span> button to delete one.*/}</span>
              </div>
            </div>
            <div style={{
              height: props.screenSize.height - (refHeader.current && refHeader.current.offsetHeight),
              backgroundColor: '#e6e6e6',
            }}>
              <animated.div style={collectionStyle}>
                <div style={{width: props.wrapper.width}}>
                  <ResourceCategory content={collectionContentsBooks} wrapper={props.wrapper}
                    headerHeight={refHeader.current && refHeader.current.offsetHeight} 
                  />
                </div>
                <div style={{width: props.wrapper.width}}>
                  <ResourceCategory content={collectionContentsVideos} wrapper={props.wrapper} 
                    headerHeight={refHeader.current && refHeader.current.offsetHeight} 
                  />
                </div>
                <div style={{width: props.wrapper.width}}>
                  <ResourceCategory content={collectionContentsWeblinks} wrapper={props.wrapper} 
                    headerHeight={refHeader.current && refHeader.current.offsetHeight} 
                  />
                </div>
                <div style={{width: props.wrapper.width}}>
                <ResourceCategory content={collectionContentsPodcasts} wrapper={props.wrapper} 
                  headerHeight={refHeader.current && refHeader.current.offsetHeight} 
                />
              </div>
              </animated.div>
            </div>
          </animated.div>
          <div style= {{
              zIndex: 10,
              display: !segment[3] && segment[2]==='/resources' ? 'block' : 'none',
              backgroundColor: '#e6e6e6'
            }}
          > 
            {/*<div className="xxAssess-button-wrapper" style={{width: '100%'}}> 
              <div  style={{
                position: 'absolute', top: 0, left: 0,
                top: props.screenSize.height - 75,
                left: props.wrapper.width*0.5 - (250/2), 
                width: 250,
                fontSize: '110%',
                fontWeight: 600,
                color: '#333333',
                // backgroundColor: '#cccccc',
              }} onClick={()=> props.rt.nav('/plan/resources/add_resources')}>
                <CCButton>+ Add Resources</CCButton>
              </div>
            </div>*/}
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
}

const ResourceCategory = (props) => {
  // console.log('ResourceCategory - props=', props)
  return(
    <Fragment>
      <div style={{
          width: props.wrapper && props.wrapper.width,
          height: props.wrapper.style.height - props.headerHeight,
          paddingBottom: 80
        }}
      >
        {props.content}
      </div>
    </Fragment>
  )
}
