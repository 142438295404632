import React, { useState, useEffect, Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { ExpandedIcon, CollapsedIcon } from '../../../../../svg/svg_icons';
import { ListOfTraits} from './AddResourcesComponents';



export const CompetencyLineItem = React.memo(props => {
  // console.log('props=', props)
  
  let [open, setOpen] = useState(false);
  let [selectedCompassData, setSelectedCompassData] = useState(null)

  const handleClick = (e) => {
    let target = e.target;
    setSelectedCompassData(props.competency);
    setOpen(!open);
  }

  const collapsedIconStyle= useSpring({
    transform: `rotate(${open ? 90 : 0}deg)`,
    opacity: open ? 0 : 1,
    position: 'absolute'
  });
  const expandedIconStyle= useSpring({
    transform: `rotate(${open ? 90 : 0}deg)`,
    position: 'absolute'
  });

  let [listHeight, setListHeight] = useState(0);
  const traitListStyle = useSpring({
    overflow: 'hidden',
    height: open ? props.competency.traits.length * 43 : 0,
  })
  return(
    <div>
      <div style={{
          userSelect: 'none',
          display: 'flex', justifyContent: 'space-between', 
          margin: '8px 0', cursor: 'pointer'
        }} onClick={(e)=> handleClick(e)}>

        <div style={{display: 'flex', justifyContent: 'flex-start'}} >
            <div style={{position: 'relative'}}>
              <animated.div style={ expandedIconStyle }>
                <ExpandedIcon style={{transform: 'rotate(-90deg)'}}size={28} />
              </animated.div>
              <animated.div style={ collapsedIconStyle }>
                <CollapsedIcon size={28} />
              </animated.div>
              </div>
          <div style={{margin: '2px 0 0 50px', fontSize: '100%'}}>
            {props.competency.name}
          </div>
        </div>
        
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className="Resources-spacer" style={{display: 'flex'}} >
            <div className='Resources-value'>
              <div style={{margin: '-6px 10px 0 0', fontWeight: 600}}>
                {props.competency.value}
              </div>
            </div>
            <div className="Resources-outer-bar" style={{width: 100}}>
              <Bar value={props.competency.value} />
            </div>
          </div>

        </div>
      </div>
      <animated.div className="Phases-trait-list" style={traitListStyle} >
        <ListOfTraits props={props} competency={props.competency} listHeight={listHeight} setListHeight={setListHeight} 
          selected={selectedCompassData} selectedIndex={props.competency.index}
        />
      </animated.div>
    </div>
  )
});

const Bar = (props) => {
  let barlength = props.value / 5 * 100;
  return(
    <div className="Resources-inner-bar" style={{width: barlength}}></div>
  )
}