/**
 * Converts the ccDef data to arrays that are later used with .map() to build the DOM elements for the Heading, Subheading, and for the CompassPoint descriptions.
 * @param {object} ccDef 
 * @param {array} hl 
 * @param {array} shl 
 * @param {array} cpl 
 * @returns nothing because it takes advantage of the fact that hl, shl, cp1 are pointers to the arrays that exist outside of this function. This means that when
 * the function returns, those arrays will have the data that was produced in this function, so there's no need to return anything.
 */
export const buildTheLines = ( ccDef, hl, shl, cpl , tst) => {

  let hLevelUp = 0;
  let shLevelUp = 0;
  let keyCount = 0;
  if(ccDef != []){
    for(let h=0; h < ccDef.length; ++h){
      hl.push({
        key: h,
        count: h+1,
        total: ccDef.length,
        heading: h+1 +'. '+ ccDef[h].heading
      });
      for(let sh=0; sh < ccDef[h].subHeading.length; ++sh){
        shl.push({
          key: (h * 3) + sh,
          count: sh+1,
          total: ccDef[h].subHeading.length,
          levelUp: hLevelUp,
          subHeading: ccDef[h].subHeading[sh].name
        });
        for(let cp=0; cp < ccDef[h].subHeading[sh].compassPoint.length; ++cp){
          cpl.push({
            // key: (((h * 3) + sh) * 9) + cp ,
            key: keyCount,
            hCount: h+1,
            shCount: sh+1,  
            cpCount: cp+1,
            total: ccDef[h].subHeading[sh].compassPoint.length,
            levelUp: shLevelUp,
            compassPoint: ccDef[h].subHeading[sh].compassPoint[cp].compassPoint,
            barValue: ccDef[h].subHeading[sh].compassPoint[cp].barValue
          });
          ++keyCount;
        }
        ++shLevelUp;
      }
      ++hLevelUp;
    }
    return [ hl, shl, cpl ];
  }
}
