import firebase from 'firebase';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCG0lnF6Ign7AowH0zeu-eUfYNEPz2APaU",
  authDomain: "juniper-leadership-compass.firebaseapp.com",
  projectId: "juniper-leadership-compass",
  storageBucket: "juniper-leadership-compass.appspot.com",
  messagingSenderId: "718346090139",
  appId: "1:718346090139:web:dde071389fa0748ad3f83e",
  measurementId: "G-GWDVTWW897"
};

firebase.initializeApp(firebaseConfig);
