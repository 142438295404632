import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { usePageTransitions, usePathSegments, goHome } from '../../../vinciToolbox';
import { BackIconOrange, NavBackIcon,  NextBlueIcon, MenuBlueIcon, 
  AssessNavIcon, SelfAssessIcon, ManagerAssessIcon, PeerAssessIcon, CustomEx } from '../../svg/svg_icons';
import { NavButton } from '../../components'; 
import firebase from 'firebase';
import '../../../firebase-init.js'
import compassDefinition from './compassDefinition.js';
import ReactHtmlParser from 'react-html-parser';
import './ViewAssessments.css';

// var markdown = require('markdown-it')();
var arraySort = require('array-sort');

let db = firebase.firestore();

// var graph = require('@microsoft/microsoft-graph-client');
// const client = graph.Client.init({
//   authProvider: (done) => {
//     done(null, accessToken);
//   }
// });

// // Get the manager data
// let managerName = '';
// client.api('/me/manager').get()
// .then((manager)=>{
//   console.log('This is the manager!!: ', manager.displayName);
//   managerName = manager.displayName;
// });


export const ViewAssessments = (props)=> {
  let [path, setPath] = useState('self')
  let pt = usePageTransitions('/view', 2);
  let currentUser = firebase.auth().currentUser;
  let [assessments, setAssessments] = useState([]);
  let [disabledAssessments, setDisabledAssessments] = useState([]);
  let assessmentArray = [];

  // console.log('disabledAssessments: ', disabledAssessments)

  // Get current year
  var d = new Date();
  var year = d.getFullYear();
  // console.log('current Year', year);
  let [selectedYear, setSelectedYear] = useState(year);

  useEffect(()=>{
    let docRef = db.collection(`campbell_compass`).doc(`${currentUser && currentUser.email}_my_assessments`);
    docRef && docRef.get()
    .then ((doc)=>{
      if(doc && doc.exists) {
        let assessments = doc.data().assessmentArray;
        let tempArray = [];
        // console.log(`assessments.length: `, assessments.length)
        for(let n = 0; n < assessments.length; ++n){
          // console.log(`assessments[${n}].assessmentFinished: `, assessments[n].assessmentFinished)
          if(assessments[n].assessmentFinished){
            tempArray.push(assessments[n]);
          }
        }
        assessments = tempArray
        // console.log('assessments 2: ', assessments)
        setAssessments(assessments);
        // Initialize the array that tracks which arrays are disabled.
        let disabled = [];
        for(let i = 0; i < 3; ++i){
          disabled.push(false);
        }
        setDisabledAssessments(disabled);
      }
    }).catch((err)=>{
      console.log('error: ', err);
    });
  },[props])

// console.log('assessments=', assessments)

  const getRealtimeUpdates = () =>{
    let docRef = db.collection(`campbell_compass`).doc(`${currentUser && currentUser.email}_my_assessments`);
    docRef && docRef.onSnapshot((doc)=>{
      if(doc && doc.exists) {
        let assessments = doc.data().assessmentArray;
        let tempArray = [];
        // console.log(`assessments.length: `, assessments.length)
        for(let n = 0; n < assessments.length; ++n){
          // console.log(`assessments[${n}].assessmentFinished: `, assessments[n].assessmentFinished)
          if(assessments[n].assessmentFinished){
            tempArray.push(assessments[n]);
          }
        }
        assessments = tempArray
        setAssessments(assessments);
      }else{
        console.log('There was an onSnapshot() error')
      }
    })
  }
  useEffect(()=>{
    getRealtimeUpdates(); 
  },[])

  const getAverageOfCompassPoints = (subHeading) => {
    let compassPointArray = subHeading.compassPoint;
    let sum = 0;
    let avg = 0;
    for(let cp = 0; cp < compassPointArray.length; ++cp){
      let barValue = compassPointArray[cp].barValue;
      sum = sum + barValue;
      avg = sum / compassPointArray.length;
    }
    return(avg)
  }

  const getCompetencyScores = (assessment, i) => {
    let competencyScores = [];
    for(let h = 0; h < assessment.length; ++h){
      for(let sh = 0; sh < assessment[h].subHeading.length; ++sh){
        let avg = getAverageOfCompassPoints(assessment[h].subHeading[sh]);
        competencyScores.push(avg);
      }
    }
    return competencyScores;
  }

  let assessmentList = null; 
  let barGraphList = null;
  let selfScores = [], selfSum = [], selfCount = 0;
  let managerScores = [], managerSum = [], managerCount = 0;
  let peerScores = [], peerSum = [], peerCount = 0;
  let theLabels = [];
  
  //console.log('disabledAssessments: ', disabledAssessments)

  if(assessments){
    // assessmentArray = arraySort(assessments, 'assessmentDate', {reverse: true});
    assessmentArray = assessments
    let lastSavedYear = '';
    if(props.user){
      for(let i = 0; i < assessmentArray.length; ++i){
        // In assessmentArray set year to '' if year is the same as the last saved year.
        if(assessmentArray[i].assessmentYear != lastSavedYear){
          lastSavedYear = assessmentArray[i].assessmentYear;
          assessmentArray[i]['showYear'] = true;
        }else{
          assessmentArray[i]['showYear'] = false;
        }
        // In assessmentArray add an index property for the subsequent map() function.
        assessmentArray[i]['index'] = i.toString();

        // In assessmentArray set the assessment type
        if(assessmentArray[i].assessorName === props.user.displayName){
          assessmentArray[i]['type'] = 'Self';
        }else if(assessmentArray[i].assessorName === props.user.managerName){
          assessmentArray[i]['type'] = 'Manager';
        }else{
          assessmentArray[i]['type'] = 'Peer';
        }
        assessmentArray[i]['disable'] = disabledAssessments[i];
      }
      // console.log('assessmentArray: ', assessmentArray);

      // Use the first assessment to grab from it the competency labels and push them into theLabels array.
      let competencies = assessmentArray[0] && assessmentArray[0].assessment; 
      if(competencies){
        for(let h = 0; h < competencies.length; ++h){
          for(let sh = 0; sh < competencies[h].subHeading.length; ++sh){
            theLabels.push(competencies[h].subHeading[sh].name)
          }
        }
        // console.log(`theLabels: `, theLabels)
      }

      // Clear selfSum values
      for(let s = 0; s < 9; ++s){
        selfSum[s] = 0;
      }
      // Clear managerSum values
      for(let s = 0; s < 9; ++s){
        managerSum[s] = 0;
      }
      // Clear peerSum values
      for(let s = 0; s < 9; ++s){
        peerSum[s] = 0;
      }
      // Sum the values for each type
      for(let i = 0; i < assessmentArray.length; ++i){
        // Find the assessments for the selectedYear.
        if(assessmentArray[i].assessmentYear === selectedYear){
          // save the nine averages in separate array for each Self type.
          if(assessmentArray[i].type === 'Self' && !assessmentArray[i].disable){
            selfScores = getCompetencyScores(assessmentArray[i].assessment, i);
            for(let s = 0; s < 9; ++s){
              selfSum[s] = selfScores[s] + selfSum[s];
            }
            ++selfCount;
            for(let i = 0; i < selfSum.length; ++i){
              selfScores[i] = Math.round( selfSum[i] / selfCount );
            }
          // save the nine averages in separate array for each Manager type.
          }else if(assessmentArray[i].type === 'Manager' && !assessmentArray[i].disable){
            managerScores = getCompetencyScores(assessmentArray[i].assessment, i);
            for(let s = 0; s < 9; ++s){
              managerSum[s] = managerScores[s] + managerSum[s];
            }
            ++managerCount;
            for(let i = 0; i < managerSum.length; ++i){
              managerScores[i] = Math.round( managerSum[i] / managerCount );
            }
          // save the nine averages in separate array for each Peer type.
          }else if(assessmentArray[i].type === 'Peer' && !assessmentArray[i].disable){
            peerScores = getCompetencyScores(assessmentArray[i].assessment, i);
            for(let s = 0; s < 9; ++s){
              peerSum[s] = peerScores[s] + peerSum[s];
            }
            ++peerCount;
            for(let i = 0; i < peerSum.length; ++i){
              peerScores[i] = Math.round( peerSum[i] );
            }
          }
        }
      }
      // Compute the average values for each type
      for(let i = 0; i < selfScores.length; ++i){
        selfScores[i] = Math.round( selfScores[i] / selfCount );
      }
      for(let i = 0; i < managerScores.length; ++i){
        managerScores[i] = Math.round( managerScores[i] / managerCount );
      }
      for(let i = 0; i < peerScores.length; ++i){
        peerScores[i] = Math.round( peerScores[i] / peerCount );
      }
    }
    let competenciesArray = [];
    for(let i = 0; i < 9; ++i){
      competenciesArray.push({index: i, 
        selfScores: !selfScores[i] ? 0 : selfScores[i], 
        mangerScores: !managerScores[i] ? 0 : managerScores[i], 
        peerScores: !peerScores[i] ? 0 : peerScores[i],  
        wrapper: props.wrapper, 
        label: theLabels[i]
      })
    }
    barGraphList = competenciesArray.map((assmnt)=>
      <HorizontalBar key={assmnt.index} index={assmnt.index}
        selfValue={selfScores[assmnt.index]} managerValue={managerScores[assmnt.index]} peerValue={peerScores[assmnt.index]}
        wrapper={props.wrapper} label={assmnt.label}
      />
    );

    const handleDisable = (ind) => {
      console.log('inside handleDisable - disabled=', assessmentArray[0].disable)
      let index = parseInt(ind);
      assessmentArray[index]['disable'] = !assessmentArray[index].disable;

      // let disabled = [];
      let temp = [];
      for(let i = 0; i < assessmentArray.length; ++i){
        temp.push(assessmentArray[i].disable)
      }
      // console.log('temp= ', temp)
      setDisabledAssessments(temp);
      // console.log('disabledAssessments= ', disabledAssessments);
    }



    assessmentArray = arraySort(assessmentArray, 'assessmentDate', {reverse: true})
    // Move am/pm to end of date string
//     let tempDate = '';
//     let am_pm = '';
//     let index = '';
//     // for(let i = 0; i < assessmentArray.length; ++i){
//     //   tempDate = assessmentArray[i].assessmentDate;
//     //   // console.log('tempDate[3]: ', tempDate[12]);
//     //   if(tempDate[12]==='m'){
//     //     // index = tempDate.search(/(\sam|\spm)/g);
//     //     am_pm = tempDate.substring(10, 13);
//     //     console.log('am_pm: ', am_pm)
//     //     tempDate = tempDate.replace(/(\sam|\spm)/g,"") + am_pm;
//     //     console.log(`tempDate: ${tempDate}` );
//     //   }
//     //   assessmentArray[i].assessmentDate = tempDate;
//     // }
// // console.log('XXXXXXXXXXXXXXXXassessmentArray.map: ', assessmentArray)
    assessmentList = assessmentArray.map((assmnt)=>
      <div key={assmnt.index} style={{display: 'flex', position: 'relative'}}>
        <div style={{width: '100%', paddingRight: 0}} > 
          <OneAssessment key={assmnt.index} index={assmnt.index} 
            type={assmnt.type } selectedYear={selectedYear} setSelectedYear={setSelectedYear}
            showYear={assmnt.showYear} assessmentDate={assmnt.assessmentDate} assessmentYear={assmnt.assessmentYear} 
            assessorName={assmnt.assessorName} assessmentData={assmnt.assessment}
            disable={assmnt.disable} handleDisable={handleDisable}
          />
        </div>
      </div>
    );
  }

  useEffect(()=>{
    let docRef = db.collection(`campbell_compass`).doc(`${currentUser && currentUser.email}_my_assessments`);
    docRef.get()
    .then(function(doc) {
      if(doc && doc.exists){
        assessmentArray = doc.data().assessmentArray;
        // Sorts the array by date
        // assessmentArray = arraySort(assessmentArray, 'assessmentDate', {reverse: true})

        // Adds an index to the array for use in map() operations
        for(let i = 0; i < assessmentArray.length; ++i){
          assessmentArray[i]['index'] = i;
        }
      }
    }).catch((error)=>{
      console.log('You screwed something up', error)
    })
  },[props])

  let transition = useSpring({
    // config: {tension: 30},
    transform: `translateX(${pt.animate ? 0 : props.wrapper.width}px)`,
    backgroundColor: '#e6e6e6', 
    position: 'absolute', top: 0, left: 0, 
    width: props.wrapper.width, 
    height: props.screenSize.height,
    overflowX: 'hidden',
    overflowY: 'auto',
    display: pt.render ? 'block' : 'none',
    // border: '2px solid blue'
  });

  let [tabSlide, setTabSlide] = useState(false);

  let tabBar = useSpring({
    height: '100%', width: '50%',
    backgroundColor: '#F8C115',
    transform: `translateX(${tabSlide ? props.wrapper.style.width / 2 : 0}px)`,
  })

  let subpageWrapper = useSpring({
    position: 'relative',
    display: pt.render ? 'flex' : 'none',
    width: props.wrapper.width * 2, 
    height:  props.wrapper.style.height - 500, // contentWrapperHeight,
    transform: `translateX(${tabSlide ? -props.wrapper.style.width : 0}px)`, 
  });


  return(
    <div>
      <animated.div  className="Phases-sidein-page" style={transition}>
        <div className="Phases-subpage-header">
          <div className="Phase-subpage-header-icon" onClick={()=>{ window.history.go(-1)} }>
            <NavButton variant="secondary" name="back" size={50} >
              <NavBackIcon height="50" name="view_assessment" />
            </NavButton> 
          </div>
          <div className="Phase-subpage-header-text" style={{margin: '0 auto'}}>View Assessments</div>
        </div>

        <div style={{marginTop: -20, display: 'flex', fontWeight: 600, cursor: 'pointer', boxShadow: '0 3px 3px rgba(0,0,0,0.2)'}}>
          <div style={{
              width: '50%', textAlign: 'center', padding: '14px 0',
              color: tabSlide ? '#888888' : '#000000'
            }} 
            onClick={(e)=> setTabSlide(false)}
          >
            Graph
          </div>
          <div style={{width: '50%', textAlign: 'center', padding: '14px 0',
              color: tabSlide ? '#000000' : '#888888'
            }} 
            onClick={(e)=> setTabSlide(true)}
          >
            History
          </div>
          </div>
          <div style={{width: '100%', height: 7, marginTop: -7}}>
            <animated.div style={tabBar}></animated.div>
          </div>



        <animated.div style={subpageWrapper}>

          {/* Graph content */}
          <div style={{ width: '100%', opacity: tabSlide ? 0 : 1}}>
            {assessments.length > 0 ? 
              <Fragment>
                <div style={{width: '100%', textAlign: 'center', fontFamily: 'Big Shoulders Display', marginTop: 25,
                  fontSize: '190%', fontWeight: 600}}>{selectedYear}</div>
                <div className="ViewAssessments-graph" 
                  style={{marginTop: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', 
                  backgroundColor: 'transparent', width: props.wrapper.width,
                  padding: '0 25px 0px 25px', 
                }} >
                { barGraphList }
                </div>

                <div style={{display: 'flex', justifyContent: 'space-around', minHeight: 20, width: '100%', marginTop: 15, 
                  // border: '1px solid blue'
                }}>
                  <LegendDot label="Self"/>
                  <LegendDot label="Manager"/>
                  <LegendDot label="Peer"/>
                </div>
              </Fragment>
            : 
              <div style={{marginTop: 25, fontWeight: 500, display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                <div style={{textAlign: 'center'}}>You have no assessments to view.</div>
                <div style={{textAlign: 'center'}}>Either do a self assessment or authorize</div>
                <div style={{textAlign: 'center'}}>someone and ask them to assess you.</div>
              </div>
            }
          </div>
          
          {/* History content */}
          <div style={{width: '100%', opacity: tabSlide ? 1 : 0, boxSizing: 'border-box',}}>
            {assessments.length > 0 ?
              <div style={{width: '100%',
                }}>
                <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: 0}}>
                  { assessmentList }
                </div>
              </div>
            :
            <div style={{marginTop: 25, fontWeight: 500, display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
              <div style={{textAlign: 'center'}}>You have no assessment history to view.</div>
            </div>
              }
          </div>
        
          </animated.div>
      </animated.div>
    </div>
  )
}

const getColor = (type) => {
  if(type === 'Self')return '#F8C115';
  if(type === 'Manager')return '#0bd0ec';
  if(type === 'Peer')return '#b3b3b3';
}

const LegendDot = (props) => {
  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '85%', fontWeight: 500, color: 'rgba(0,0,0,0.8)',
      
    }}>
      <div style={{height: 22, width: 22, borderRadius: '50%',
      borderTop: '3px solid #d0d0d0', borderRight: '3px solid #f4f4f4', borderBottom: '3px solid #f4f4f4', borderLeft: '3px solid #d0d0d0',
      backgroundColor: getColor(props.label) 
    }}>
    </div>
      {props.label}
    </div>
  )
}

const OneAssessment = (props) => {  
  let [menu, setMenu] = useState(false);
  // console.log('props.disableAssessments: ', props.disabledAssessments);
  
  const handleSelectedYear = (year, setSelectedYear) => {
    setSelectedYear(year);
  }

  let formattedDate = props.assessmentDate
  formattedDate = formattedDate.split('/');
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
  let formattedMonth = formattedDate[1];
  formattedMonth = months[parseInt(formattedDate[1] - 1)]
  let formattedDay = formattedDate[1];
  let formattedTime = formattedDate[2]

  formattedDay = formattedTime.split(' ');
  formattedDay = formattedDay[0]
  formattedTime = formattedTime.split(' ');
  formattedTime = formattedTime[1] +' '+ formattedTime[2];
  // Move am or pm to end of date string
  formattedTime = formattedTime.split(' ');
  formattedDate = formattedDay +" "+ formattedMonth +' '+  formattedTime[1] +' '+ formattedTime[0]

  const handleDelete = (index) => {
    setMenu(true);
    console.log('inside handleDelete - index', index)
  }

  return(
    <div style={{
        margin: 0,
        backgroundColor: props.selectedYear === props.assessmentYear ? 'transparent' : 'transparent',
        opacity: props.selectedYear === props.assessmentYear ? 1 : .3, cursor: 'pointer',
        // border: '2px solid blue'
      }} onClick={()=> handleSelectedYear(props.assessmentYear, props.setSelectedYear)}
    >
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', margin: '0 0 0 25px',}}>
        <div className="ViewAssessments big-shoulders-display" style={{cursor: 'pointer', fontSize: '150%', fontWeight: 700, marginTop: props.showYear? 30 : 0, 
          // textDecoration: props.showYear ? 'underline' : 'none'
        }}  >
          {props.showYear ? props.assessmentYear : ''}
        </div>
      </div>
      <div key={props.index} 
        style={{display: 'flex', justifyContent: 'space-between', 
          position: 'relative',
          width: '100%', marginBottom: -4, 
          opacity: props.disable ? .4 : 1,
          //border: '2px solid red',

        }}
      >
        <div style={{
            display: 'flex', justifyContent: 'flex-begin',
          }}
        >
          <div style={{
              position: 'relative', display: 'flex',
              userSelect: props.selectedYear === props.assessmentYear ? 'auto' : 'none',
            }} onClick={(e)=> props.selectedYear === props.assessmentYear ? props.handleDisable(props.index) : ''}
          >
            <div style={{height: 40, width: 30, margin: '3px 15px 0 15px'}}  
              // onClick={(e)=> props.selectedYear === props.assessmentYear ? props.handleDisable(props.index) : ''}
              
              >
              {props.type === 'Self' ? <SelfAssessIcon /> : ''}
              {props.type === 'Manager' ? <ManagerAssessIcon /> : ''}
              {props.type === 'Peer' ? <PeerAssessIcon /> : ''}
            </div>
            <div style={{fontSize: '90%', fontWeight: 600, margin: 'auto 0', color: '#333'}}  
              // onClick={(e)=> props.selectedYear === props.assessmentYear ? props.handleDisable(props.index) : ''}
            >
              {props.assessorName}
            </div>
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', position: 'relative'}} >
          <div style={{paddingRight: 25, fontSize: '90%', fontWeight: 500, margin: 'auto 0', color: '#000'}}>
            {formattedDate}
          </div>
        </div>
        <div style={{
            width: 10, position: 'absolute', right: 145, bottom: 13, 
          }} 
          onClick={(e)=> 
            props.selectedYear === props.assessmentYear && !props.disable ?   
            handleDelete(props.index)
            : ''
          }
        >
          {props.assessorName === firebase.auth().currentUser.displayName ? 
            <CustomEx size={20} backgroundColor="#e6e6e6" color="#666" borderColor="#999" />
             : ' '}
        </div>
        <div style={{position: 'absolute', top: 12, right: 40, zIndex: 50}}>
          <DeleteMenu menu={menu} setMenu={setMenu} index={props.index} />
        </div>
      </div> 
    </div>
  )
}

const DeleteMenu = (props) => {
  let menuStyle = useSpring({
    config: {tension: 400},
    position: 'relative', 
    width: 'auto', 
    // height: 100,
    top: -15, left: 20,
    padding: '15px 20px 15px 10px',
    backgroundColor: '#f4f4f4',
    transform: `scale(${props.menu ? 1 : 0.5},${props.menu ? 1 : 0.5})`,
    // opacity: props.menu ? .5 : 0,
    borderRadius: 10,
    boxShadow: '0 0 20px rgba(0,0,0,0.3)',
    color: '#444444',
    fontSize: '110%',
    userSelect: 'none',
    display: props.menu ? 'block' : 'none'
  })   
  const handleRemove = (index) => {
    let edited = props.collectionContents;
    props.setMenu(false);
    let db = firebase.firestore();
    let currentUser = firebase.auth().currentUser;
    let docRef = db.collection(`campbell_compass`).doc(`${currentUser && currentUser.email}_my_assessments`);
    docRef.get()
    .then((doc)=>{
      let assessments = doc.data().assessmentArray

      // console.log('assessments before: ', index +' '+ assessments)
      assessments = arraySort(assessments, 'assessmentDate', {reverse: true})
      // console.log('assessments after: ', index +' '+ assessments)

      let editedAssessments = [];
      for(let i = 0; i < assessments.length; ++i){
        if(i != index){
          editedAssessments.push(assessments[i]);
        }
      }
      // console.log('index : ', index)
      console.log('editedAssessments: ', editedAssessments)
      docRef.set({
        assessmentArray: editedAssessments
      }).then(()=>{
        console.log('REMOVE SUCCESS');
      }).catch((error)=> {
        console.log('you screwed up!: ', error);
      })
    })
  }
  return(
    <animated.div style={menuStyle}>
      <div style={{display: 'flex'}}>
        <div style={{marginLeft: 0,
          paddingRight: 15,
          color: '#bbb'
          // border: '1px solid blue'
        }} >
          {'<'} 
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{fontWeight: 500}}>
          Delete this Assessment?
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between',
          margin: '15px 0 0 0',
          fontWeight: 500,
          fontSize: '110%',
        }}>
          <div style={{cursor: 'pointer'}} 
            onClick={(e)=>props.setMenu(false)}
          >
            Cancel
          </div>
          <div style={{ color: 'red', cursor: 'pointer'}} 
            onClick={(e)=>handleRemove(props.index)}
          >
            Delete
          </div>
        </div>
        </div>
      </div>
    </animated.div>
  )
}

const HorizontalBar = (props) => {
  // console.log('HorizontalBar - props: ', props)
  // console.log('==========================')
  let barWidth = 6/100;
  let barRadius = barWidth * 100 / 2;

  let selfStyles = useSpring(
    { width: `${!props.selfValue ? 0 : props.selfValue * 20}%`}
  )
  let managerStyles = useSpring(
    { width: `${!props.managerValue ? 0 : props.managerValue * 20}%`}
  )
  let peerStyles = useSpring(
    { width: `${!props.peerValue ? 0 : props.peerValue * 20}%`}
  )
  return(
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', width: '100%',
      marginBottom: 15
    }}>
      <div style={{ lineHeight: 1.2, margin: '-2px 20px 0 0', width: '100%', whiteSpace: 'no-wrap', color: '#333', fontSize: '95%', fontWeight: 600, textAlign: 'right', alignItems: 'flex-start',
        
      }}>
        {props.label}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
        <div className="ViewAssessments-outer-bar" style={{}}>
          <animated.div className="ViewAssessments-inner-bar ViewAssessments-inner-bar-gold" style={selfStyles}></animated.div>
        </div>
        <div className="ViewAssessments-outer-bar" style={{}}>
          <animated.div className="ViewAssessments-inner-bar ViewAssessments-inner-bar-dark" style={managerStyles}></animated.div>
        </div>
        <div className="ViewAssessments-outer-bar" style={{}}>
          <animated.div className="ViewAssessments-inner-bar ViewAssessments-inner-bar-gray" style={peerStyles}></animated.div>
        </div>  
      </div>
    </div>
  )
}
